export const TIF_OPTIONS_FILTER = [
  {
    label: "Município",
    value: "municipioFiscalizacao",
  },
  {
    label: "Número do TIF",
    value: "numero",
  },
  {
    label: "Nome Propriedade/Estabelecimento",
    value: "nomePropriedade",
  },
  {
    label: "Nome do Fiscalizado",
    value: "nomeFiscalizado",
  },
  {
    label: "CPF/CNPJ do Fiscalizado",
    value: "cpfCnpjFiscalizado",
  },
  {
    label: "Nome do Servidor",
    value: "nomeServidor",
  },
];

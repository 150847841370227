import { createAction } from "@reduxjs/toolkit";
import {
  IDadosAssinaturaFiscalizadoDepositario,
  ITermoApreensaoLiberacao,
} from "../../interfaces/ITermoApreensaoLiberacao";

const addTermo = createAction(
  "TERMO_APREENSAO_LIBERACAO/addTermo",
  (termoJson: ITermoApreensaoLiberacao) => ({
    payload: { termoJson },
  })
);

const updateAssinatura = createAction(
  "TERMO_APREENSAO_LIBERACAO/updateAssinatura",
  (
    dadosEdit: IDadosAssinaturaFiscalizadoDepositario,
    termoId: number,
    termo: ITermoApreensaoLiberacao
  ) => ({
    payload: { dadosEdit, termoId },
    meta: {
      offline: {
        effect: {
          //CHAMADA DA API
          url: `/vegetal/termos-apreensao-liberacao/${termoId}`,
          method: "PUT",
          data: dadosEdit,
        },

        // action to dispatch if network action fails permanently:
        rollback: {
          type: "TERMO_APREENSAO_LIBERACAO/updateAssinaturaRollback",
          meta: { termo },
        },
      },
    },
  })
);

const addTermoByFilter = createAction(
  "TERMO_APREENSAO_LIBERACAO/addTermoByFilter",
  (termo: ITermoApreensaoLiberacao) => ({
    payload: { termo },
  })
);

const updateTermo = createAction(
  "TERMO_APREENSAO_LIBERACAO/updateTermo",
  (termo: ITermoApreensaoLiberacao) => ({
    payload: { termo },
  })
);

const removeTermo = createAction(
  "TERMO_APREENSAO_LIBERACAO/removeTermo",
  (numTermo: string) => ({
    payload: { numTermo },
  })
);

const removeIdTermo = createAction(
  "TERMO_APREENSAO_LIBERACAO/removeIdTermo",
  (id: number) => ({
    payload: { id },
  })
);

const addTimePersistedAtItems = createAction(
  "TERMO_APREENSAO_LIBERACAO/addTimePersistedAtItems",
  (createdAt: number) => ({
    payload: { createdAt },
  })
);

const removeTimePersistedAtItems = createAction(
  "TERMO_APREENSAO_LIBERACAO/removeTimePersistedAtItems",
  () => ({
    payload: {},
  })
);

export const termoApreensaoLiberacaoAction = {
  addTermo,
  removeTermo,
  updateAssinatura,
  updateTermo,
  removeIdTermo,
  addTermoByFilter,
  addTimePersistedAtItems,
  removeTimePersistedAtItems,
};

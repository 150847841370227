import { createAction } from "@reduxjs/toolkit";
import { IAtividadeInterdicao } from "../../interfaces/IAtividadeInterdicao";

const addAtividades = createAction(
  "ATIVIDADE_INTERDITADA/addAtividades",
  (atividades: IAtividadeInterdicao[]) => ({
    payload: { atividades },
  })
);

const removeAtividades = createAction(
  "ATIVIDADE_INTERDITADA/removeAtividades",
  () => ({ payload: {} })
);

export const atividadeInterditadaAction = { addAtividades, removeAtividades };

import { createAction } from "@reduxjs/toolkit";

const ADD_GRID_SERVIDOR = "GRID_SERVIDORES/addGridServidor";

const addGridServidor = createAction(ADD_GRID_SERVIDOR, (servidor) => ({
  payload: servidor,
}));

const REMOVE_SERVIDOR_IN_GRID = "GRID_SERVIDORES/removeServidorInGrid";

const removeServidorInGrid = createAction(
  REMOVE_SERVIDOR_IN_GRID,
  (matriculaServidor: string) => ({
    payload: matriculaServidor,
  })
);

const REMOVE_ALL_SERVIDORES_IN_GRID =
  "GRID_SERVIDORES/removeAllServidoresInGrid";

const removeAllServidoresInGrid = createAction(
  REMOVE_ALL_SERVIDORES_IN_GRID,
  () => ({
    payload: {},
  })
);

export const gridServidorAction = {
  addGridServidor,
  removeServidorInGrid,
  removeAllServidoresInGrid,
};

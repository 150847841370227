import moment from "moment";
import { ITabelaTesteLasgal } from "../../../../interfaces/ITabelaTesteLasgal";
import { ITabelaTesteLasgalGerminacao } from "../../../../interfaces/ITabelaTesteLasgalGerminacao";

//valor que se refere a quando o teste nao foi realizado
export const VALOR_PADRAO = "-N-";

export const INITIAL_VALUE_TESTES: ITabelaTesteLasgal[] = [
  {
    key: "0",
    pureza: "1",
    materialInerte: "2",
    outraSementes: "3",
    outraCultivares: "4",
    normais: "5",
    anormais: "6",
    duras: "7",
    dormentes: "8",
    mortas: "9",
    outraCultivadas: "10",
    silvestres: "11",
    toleradas: "12",
    proibidas: "13",
    tetrazolio: "14",
    pms: "15",
    vigna: "16",
    vazio: "17",
  },
  {
    key: "1",
    pureza: "",
    materialInerte: "",
    outraSementes: "",
    outraCultivares: "",
    normais: "",
    anormais: "",
    duras: "",
    dormentes: "",
    mortas: "",
    outraCultivadas: "",
    silvestres: "",
    toleradas: "",
    proibidas: "",
    tetrazolio: "",
    pms: "",
    vigna: "",
    vazio: "",
  },
];

export const INITIAL_VALUE_GERMINACAO: ITabelaTesteLasgalGerminacao = {
  key: "0",
  data: moment().format("DD/MM/YYYY"),
  validade: "0K",
  normais: "",
  anormais: "",
  duras: "",
  dormentes: "",
  mortas: "",
  operador: "INDEFINIDO",
};

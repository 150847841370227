import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IListagemFormularioPerguntas } from "../../interfaces/IListagemFormularioPerguntas";

interface IFormularioPerguntasVistoriaSilce {
  items: IListagemFormularioPerguntas[];
  persistedAt: number;
}

const initialState: IFormularioPerguntasVistoriaSilce = {
  items: [],
  persistedAt: 0,
};

const formularioPerguntasVistoriaSlice = createSlice({
  name: "FORMULARIO_PERGUNTAS_VISTORIA",
  initialState,
  reducers: {
    addFormsPerguntaVistoria: (state, action) => {
      const { forms, dateTimeExpire } = action.payload;
      state.items = forms;
      state.persistedAt = dateTimeExpire;
    },
    removeFormsPerguntaVistoria: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default formularioPerguntasVistoriaSlice.reducer;

export const getFormularioPerguntasVistoria = (state: RootState) =>
  state.formularioPerguntasVistoria.items;

import { createAction } from "@reduxjs/toolkit";
import {
  IDadosAssinaturaTermoVistoria,
  ITermoVistoria,
} from "../../interfaces/ITermoVistoria";

const addTermo = createAction(
  "TERMO_VISTORIA/addTermo",
  (termo: ITermoVistoria) => ({
    payload: { termo },
  })
);

const updateAssinatura = createAction(
  "TERMO_VISTORIA/updateAssinatura",
  (
    dadosEdit: IDadosAssinaturaTermoVistoria,
    termoId: number,
    termo: ITermoVistoria
  ) => ({
    payload: { dadosEdit, termoId },
    meta: {
      offline: {
        effect: {
          //CHAMADA DA API
          url: `/vegetal/termos-vistoria/${termoId}`,
          method: "PUT",
          data: dadosEdit,
        },

        // action to dispatch if network action fails permanently:
        rollback: {
          type: "TERMO_VISTORIA/updateAssinaturaRollback",
          meta: { termo },
        },
      },
    },
  })
);

const updateTermo = createAction(
  "TERMO_VISTORIA/updateTermo",
  (termo: ITermoVistoria) => ({
    payload: { termo },
  })
);

const removeTermo = createAction(
  "TERMO_VISTORIA/removeTermo",
  (numTermo: string) => ({
    payload: { numTermo },
  })
);

const addByFilter = createAction(
  "TERMO_VISTORIA/addByFilter",
  (termo: ITermoVistoria) => ({
    payload: { termo },
  })
);

const addTimePersistedAtItems = createAction(
  "TERMO_VISTORIA/addTimePersistedAtItems",
  (createdAt: number) => ({
    payload: { createdAt },
  })
);

const removeTimePersistedAtItems = createAction(
  "TERMO_VISTORIA/removeTimePersistedAtItems",
  () => ({
    payload: {},
  })
);

export const termoVistoriaAction = {
  addTermo,
  updateTermo,
  removeTermo,
  addByFilter,
  addTimePersistedAtItems,
  removeTimePersistedAtItems,
  updateAssinatura,
};

import { Button, Divider, Form, Modal, Radio, message } from "antd";
import { Fragment, useState, useRef } from "react";
import { useQuery } from "react-query";
import { apiService } from "../../services/api";
import { FiscalizadoVistoria } from "./components/FiscalizadoVistoria";
import { AssinaturaVistoria } from "./components/AssinaturaVistoria";
import { IMunicipio } from "../../interfaces/IMunicipio";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ServidorVistoria } from "./components/ServidorVistoria";
import { openNotification } from "../../components/Notifications";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ITermoVistoria } from "../../interfaces/ITermoVistoria";
import moment from "moment";
import fs from "file-saver";
import { getStatusAssinatura } from "../../functions/statusAssinatura";
import { PerguntasVistoria } from "./components/PerguntasVistoria";
import { TitleTermo } from "../../styles/TitleTermo";
import { ButtonFooterContainer } from "../../styles/ButtonFooterContainer";
import { IPerguntaTermoVistoria } from "../../interfaces/IPerguntaTermoVistoria";
import { useForms } from "../../hooks/useForms";
import { useMunicipio } from "../../hooks/useMunicipio";
import { useServidores } from "../../hooks/useServidores";
import { useActionsTermoVistoria } from "./hooks/useActionsTermoVistoria";
import { useDadosTermoVistoria } from "../../hooks/useDadosTermoVistoria";
import { getRespostasVistoria } from "./functions/getRespostasVistoria";
import { PreviaImpressaoVistoria } from "./components/PreviaImpressaoVistoria";
import { ModalInformarNumeroTermo } from "../../components/ModalInformarNumeroTermo";
import { transformStringToNumber } from "../../utils/transformStringToNumber";
import { INITIAL_VALUES_ASSINATURA } from "../../constants/INITIAL_VALUES_ASSINATURA";

export function TermoVistoria() {
  const [formVistoria] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const { getMunicipioPorId } = useMunicipio();
  const { getServidorPorMatricula } = useServidores();
  const { messageSubmitFailed, gerarNumeroTermo } = useForms();
  const { submitTermo, validateTermo, adicionarTermoByFiltro } =
    useActionsTermoVistoria();
  const { listaFormsPerguntaTermoVistoria } = useDadosTermoVistoria();

  const refServidor = useRef<any>(null);

  const [isOpenModalInformarNumTermo, setIsOpenModalInformarNumTermo] =
    useState(false);
  const [numTermo, setNumTermo] = useState("");
  const [perguntas, setPerguntas] = useState<IPerguntaTermoVistoria[]>([]);
  const [municipiosOptions, setMuncipiosOptions] = useState<IMunicipio[]>([]);
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [fiscalizado, setFiscalizado] = useState(INITIAL_VALUES_ASSINATURA);
  const [isServidorSelected, setIsServidorSelected] = useState(false);
  const [statusAssinatura, setStatusAssinatura] = useState<CheckboxValueType[]>(
    []
  );
  const [assinaturaServidor, setAssinaturaServidor] = useState("");
  const [isSavingTermo, setIsSaving] = useState(false);
  const { data: listaPerguntas } = useQuery({
    initialData: listaFormsPerguntaTermoVistoria,
    queryFn: () => apiService.listagemFormularios(),
    queryKey: "FORMULARIO_PERGUNTAS_TERMO_VISTORIA",
  });

  const getFormSelected = (formId: number) => {
    const form = listaPerguntas?.filter(
      (pergunta) => pergunta.id === formId
    )[0];

    if (!form) return;

    return form;
  };

  const handleSubmit = async (values: any) => {
    const keyMessage = "termo-vistoria";
    const numTermoVistoria =
      numTermo || gerarNumeroTermo(values.municipioFiscalizado);

    //FAZER VALIDACOES
    const dadosValidacao = {
      assinaturaServidor,
      dadosFiscalizado: fiscalizado,
      statusAssinatura,
    };

    if (!validateTermo(dadosValidacao)) return;

    let formValues: ITermoVistoria = {
      localArmazenamentoProdutoAgrotoxico: values.localArmazenamento,
      cpfCnpjFiscalizado: values.cnpjFiscalizado.replace(/[^\d]+/g, ""),
      nomeFiscalizado: values.nomeFiscalizado,
      emailFiscalizado: values.emailFiscalizado,
      telefoneFiscalizado: values.telefoneFiscalizado.replace(/[^\d]+/g, ""),
      cepFiscalizado: values.cepFiscalizado,
      municipioFiscalizado: getMunicipioPorId(values.municipioFiscalizado),
      formulario: getFormSelected(values.tipoEstabelecimento) || null,
      assinaturaFiscalizado:
        statusAssinatura.length === 0
          ? fiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() || ""
          : "",
      nomeAssinanteFiscalizado: fiscalizado.nomeAssinante,
      cpfAssinanteFiscalizado: fiscalizado.cpfAssinante.replace(/[^\d]+/g, ""),
      funcaoAssinanteFiscalizado: fiscalizado.funcaoAssinante,
      assinaturaServidor: assinaturaServidor,
      servidor: getServidorPorMatricula(values.matriculaServidor) || null,
      numero: numTermoVistoria,
      statusAssinaturaFiscalizado: getStatusAssinatura(statusAssinatura),
      statusAssinaturaServidor: getStatusAssinatura(statusAssinatura),
      enderecoFiscalizado: values.enderecoFiscalizado,
      latGrauFiscalizado:
        transformStringToNumber(values.latGrauFiscalizado || "") || 0,
      latMinFiscalizado:
        transformStringToNumber(values.latMinFiscalizado || "") || 0,
      latSegFiscalizado:
        transformStringToNumber(values.latSegFiscalizado || "") || 0,
      longGrauFiscalizado:
        transformStringToNumber(values.longGrauFiscalizado || "") || 0,
      longMinFiscalizado:
        transformStringToNumber(values.longMinFiscalizado || "") || 0,
      longSegFiscalizado:
        transformStringToNumber(values.longSegFiscalizado || "") || 0,
      dataCadastro: `${moment().format("YYYY-MM-DD[T]HH:mm:ss")}Z`,
      inscricaoEstadualFiscalizado: "",
      respostas: getRespostasVistoria(perguntas, values),
    };

    if (navigator.onLine) {
      setIsSaving(true);
      message.loading({
        key: keyMessage,
        duration: 0,
        content: "Salvando Termo...",
      });

      await apiService.termoVistoria
        .enviar(formValues)
        .then((res) => {
          adicionarTermoByFiltro({ ...formValues, id: res.data.id });

          setIsSaving(false);
          message.destroy(keyMessage);
          openNotification({
            type: "success",
            message: "Termo de Vistoria salvo com sucesso!",
          });
        })
        .catch((erro) => {
          console.log(erro);
          setIsSaving(false);
          message.destroy(keyMessage);
          openNotification({
            type: "error",
            message: "Erro ao salvar Termo de Vistoria",
            description: erro.response.data.detail,
          });
        });
    } else {
      submitTermo(formValues);

      modal.info({
        title: `Gostaria de baixar um backup do Termo de Vistoria?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Sim",
        onOk: () => {
          let blob = new Blob([JSON.stringify(formValues)], {
            type: "text/plain;charset=utf-8",
          });
          fs.saveAs(blob, `Termo de Vistoria ${formValues.numero}.txt`, {
            autoBom: true,
          });
          openNotification({
            type: "success",
            message: "Termo de Vistoria criado com sucesso!",
            description:
              "Salvamento do Termo de  Vistoria pendente, para quando aplicativo houver conexão com a internet.",
          });
        },
      });
    }

    handleReset();
  };

  const modalConfirmReset = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        handleReset();
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const handleReset = () => {
    if (refServidor.current) {
      refServidor.current.clear();
    }

    formVistoria.resetFields();
    setPerguntas([]);
    setAssinaturaServidor("");
    setIsValidDocument(false);
    setIsServidorSelected(false);
    setStatusAssinatura([]);
    setStatusAssinatura([]);
    setFiscalizado(INITIAL_VALUES_ASSINATURA);
    setAssinaturaServidor("");
  };

  return (
    <Fragment>
      {contextHolder}
      <TitleTermo>Termo de Vistoria</TitleTermo>
      <Divider />

      <Form
        id="form-vistoria"
        labelWrap
        labelAlign="left"
        wrapperCol={{ flex: 1, span: 14 }}
        form={formVistoria}
        onFinish={handleSubmit}
        onFinishFailed={messageSubmitFailed}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <FiscalizadoVistoria
          formVistoria={formVistoria}
          municipiosOptions={municipiosOptions}
          setMunicipiosOptions={setMuncipiosOptions}
          isValidDocument={isValidDocument}
          setIsValidDocument={setIsValidDocument}
          setFiscalizado={setFiscalizado}
        />

        <Form.Item
          label="Local de armazenamento dos produtos agrotóxicos"
          name="localArmazenamento"
          labelCol={{ span: 6 }}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value="PREDIO_PROPRIO"> Prédio próprio</Radio>
            <Radio value="OUTRO_LOCAL">Outro local</Radio>
          </Radio.Group>
        </Form.Item>

        <Divider />

        <PerguntasVistoria
          perguntas={perguntas}
          setPerguntas={setPerguntas}
          formVistoria={formVistoria}
          listaPerguntas={listaPerguntas || []}
        />

        <Divider />
        <ServidorVistoria
          form={formVistoria}
          setIsServidorSelected={setIsServidorSelected}
        />
      </Form>

      <Divider />

      <AssinaturaVistoria
        ativarModalInformarNumTermo={() => setIsOpenModalInformarNumTermo(true)}
        fiscalizado={fiscalizado}
        setFiscalizado={setFiscalizado}
        form={formVistoria}
        assinaturaServidor={assinaturaServidor}
        setAssinaturaServidor={setAssinaturaServidor}
        setStatusAssinatura={setStatusAssinatura}
        statusAssinatura={statusAssinatura}
        isServidorSelected={isServidorSelected}
        refServidor={refServidor}
      />

      <ButtonFooterContainer>
        <Button
          form="form-vistoria"
          onClick={modalConfirmReset}
          disabled={isSavingTermo}
        >
          Cancelar
        </Button>

        <Button
          type="primary"
          form="form-vistoria"
          htmlType="submit"
          disabled={isSavingTermo}
        >
          Salvar
        </Button>

        <PreviaImpressaoVistoria
          form={formVistoria}
          fiscalizado={fiscalizado}
          perguntas={perguntas}
          setPerguntas={setPerguntas}
        />
      </ButtonFooterContainer>

      <ModalInformarNumeroTermo
        isOpen={isOpenModalInformarNumTermo}
        onClose={() => setIsOpenModalInformarNumTermo(false)}
        numTermo={numTermo}
        setNumTermo={setNumTermo}
      />
    </Fragment>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { embalagemAction } from "../store/actions/embalagemAction";
import { IEmbalagem } from "../interfaces/IEmbalagem";
import { getEmbalagens } from "../store/feature/embalagemSlice";

export const useEmbalagem = () => {
  const { addEmbalagens, removeEmbalagens } = embalagemAction;
  const listaEmbalagens = useSelector(getEmbalagens);
  const appDispatch = useDispatch();

  const adicionarEmbalagens = (
    embalagens: IEmbalagem[],
    persistedAt: number
  ) => {
    appDispatch(addEmbalagens(embalagens, persistedAt));
  };

  const removerEmbalagens = () => {
    appDispatch(removeEmbalagens());
  };

  return { adicionarEmbalagens, removerEmbalagens, listaEmbalagens };
};

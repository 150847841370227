import { useDispatch, useSelector } from "react-redux";
import { getProdutosAgrotoxicos } from "../store/feature/produtoAgrotoxicoSlice";
import { produtoAgrotoxicoAction } from "../store/actions/produtoAgrotoxicoAction";
import { IProdutoAgrotoxico } from "../interfaces/IProdutoAgrotoxico";

export const useProdutosAgrotoxicos = () => {
  const listaProdutosAgrotoxicos = useSelector(getProdutosAgrotoxicos);
  const { addProdutosAgrotoxico, removeProdutosAgrotoxico } =
    produtoAgrotoxicoAction;
  const appDispatch = useDispatch();

  const adicionarProdutos = (
    produtos: IProdutoAgrotoxico[],
    persistedAt: number
  ) => {
    appDispatch(addProdutosAgrotoxico(produtos, persistedAt));
  };

  const removerProdutos = () => {
    appDispatch(removeProdutosAgrotoxico());
  };

  return {
    listaProdutosAgrotoxicos,
    adicionarProdutos,
    removerProdutos,
  };
};

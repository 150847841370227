import { Button, Tooltip, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { getAnexosFormatadosBase64 } from "../../../../../functions/getAnexosFormatadosBase64";
import { Buffer } from "buffer";
import { openNotification } from "../../../../../components/Notifications";
import { apiService } from "../../../../../services/api";
import { useActionsTif } from "../../../hooks/useActionsTif";
import { converterTIFtoFormData } from "../../../functions/converterTIFtoFormData";
import { IAnexos } from "../../../../../interfaces/IAnexos";
import { converterBase64ToFile } from "../../../../../functions/converterBase64ToFile";
import { useTif } from "../../../hooks/useTif";

export function ButtonEnviarBackupTIF() {
  const { adicionarTifByFiltro, atualizarTif, listaTif } = useActionsTif();
  const { getAnexo } = useTif();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const keyMessage = "termo";

    try {
      const anexo = await getAnexosFormatadosBase64(info.fileList);

      const json = Buffer.from(anexo[0].arquivo, "base64").toString();

      //SLICE NA POSICAO 14 PARA REMOVER CARACTERES ESPECIAIS
      const jsonObject = JSON.parse(json.slice(14));

      const anexosFile = jsonObject.anexosTIF.map((anexo: IAnexos) => {
        return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
      });

      message.loading({
        duration: 0,
        content: "Enviando Termo...",
        key: keyMessage,
      });

      await apiService.tif
        .enviar(converterTIFtoFormData(jsonObject, anexosFile))
        .then(async (res) => {
          const tifInApp = listaTif.find(
            (tif) => tif.numero === res.data.numero
          );

          if (tifInApp) {
            atualizarTif({ ...tifInApp, id: res.data.id });
            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: "TIF enviado com sucesso",
            });

            return;
          }

          const responseTif = await apiService.tif.byId(res.data.id);

          const dadosAnexos = responseTif.anexosTIF.map((anexo) => {
            return {
              nomeUpload: anexo.nomeUpload,
              nomeArquivo: anexo.nomeArquivo,
            };
          });

          if (dadosAnexos.length <= 0) {
            adicionarTifByFiltro({
              ...responseTif,
              anexosTIF: [],
            });
          } else {
            const anexos = await getAnexo(dadosAnexos);
            // console.log(anexos);

            adicionarTifByFiltro({
              ...responseTif,
              anexosTIF: anexos,
            });
          }

          message.destroy(keyMessage);
          openNotification({
            type: "success",
            message: "TIF enviado com sucesso",
          });
        })
        .catch((erro) => {
          message.destroy(keyMessage);
          openNotification({
            type: "error",
            message: "Não foi possivel enviar backup do TIF",
            description: erro.response.data.detail,
          });
        });
    } catch (error) {
      message.destroy(keyMessage);
      openNotification({
        type: "error",
        message: "Não foi possivel enviar backup do TIF",
        description:
          "Por favor verifique se o backup foi selecionado corretamente, caso o erro persista entre em contato com o suporte.",
      });
    }
  };

  return (
    <>
      <Tooltip title="Enviar backup do TIF" placement="left">
        <Upload
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          fileList={[]}
        >
          <Button disabled={!navigator.onLine}>
            <UploadOutlined />
          </Button>
        </Upload>
      </Tooltip>
    </>
  );
}

import { createAction } from "@reduxjs/toolkit";
import { IEmpresaAgrotoxico } from "../../interfaces/IEmpresaAgrotoxico";

const addEmpresas = createAction(
  "EMPRESA_AGROTOXICO/addEmpresas",
  (empresas: IEmpresaAgrotoxico[], createdAt: number) => ({
    payload: { empresas, createdAt },
  })
);

const removeEmpresas = createAction(
  "EMPRESA_AGROTOXICO/removeEmpresas",
  () => ({
    payload: {},
  })
);

export const empresaAgrotoxicoAction = { addEmpresas, removeEmpresas };

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IPerfil } from "../../interfaces/IPerfil";

interface IPerfilSlice {
  items: IPerfil[];
}

const initialState: IPerfilSlice = {
  items: [],
};

const perfilSlice = createSlice({
  name: "PERFIL",
  initialState,
  reducers: {
    addPerfis: (state, action) => {
      const { perfis }: { perfis: IPerfil[] } = action.payload;

      // const orderPerfis = perfis.sort((a, b) => {
      //   if (a.ativo && b.ativo) return 1;

      //   if (a.ativo && !b.ativo) return -1;

      //   return 0;
      // });
      state.items = perfis;
    },

    addItem: (state, action) => {
      const { perfil } = action.payload;

      state.items.push(perfil);

      const orderPerfis = state.items.sort((a, b) => {
        if (a.ativo && b.ativo) return 1;

        if (a.ativo && !b.ativo) return -1;

        return 0;
      });

      state.items = orderPerfis;
    },

    removeItem: (state, action) => {
      const { idPerfil } = action.payload;
      state.items = state.items.filter((item) => item.id !== idPerfil);
    },

    updateNome: (state, action) => {
      const { idPerfil, nomeNovo } = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === idPerfil) {
          return {
            ...item,
            nome: nomeNovo,
          };
        }
        return item;
      });
    },

    updatePermissoes: (state, action) => {
      const { idPerfil, permissoes } = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === idPerfil) {
          return {
            ...item,
            permissoes: permissoes,
          };
        }
        return item;
      });
    },
  },
});

export default perfilSlice.reducer;

export const getPerfis = (state: RootState) => state.perfis.items;

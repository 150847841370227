import { Navigate, useLocation } from "react-router-dom";
import { CustomRoute } from "../CustomRoute";
import { usePermission } from "../../hooks/usePermission";

export function PrivateRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { isRouteEnabled } = usePermission();
  const rota = window.location.pathname.slice(1);

  if (!isRouteEnabled(rota)) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return <CustomRoute>{children}</CustomRoute>;
}

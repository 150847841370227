import { Form, FormInstance, Input, Select } from "antd";
import { Fragment, useState, useEffect } from "react";
import { masks } from "../../../../functions/mascaras";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { openNotification } from "../../../../components/Notifications";
import { IPropriedade } from "../../../../interfaces/IPropriedade";
import { apiService } from "../../../../services/api";
import { IMunicipio } from "../../../../interfaces/IMunicipio";
import { IAssinante } from "../../../../interfaces/IAssinante";
import { IFormValuesTermoApreensaoLiberacao } from "../../../../interfaces/IFormValuesTermoApreensaoLiberacao";
import { useMunicipio } from "../../../../hooks/useMunicipio";
import { usePropriedade } from "../../../../hooks/usePropriedade";
import { resetarDepositarioFields } from "../../functions/resetarDepositarioFields";

interface IRegistroDepositarioTermoApreensaoLiberacaoProps {
  isTifExisist: boolean;
  isProprio: boolean;
  formApreensao: FormInstance<IFormValuesTermoApreensaoLiberacao>;
  depositarioSignature: IAssinante;
  setDepositarioSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  isValidDocument: boolean;
  setIsValidDocument: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
  municipioDepositarioOptions: IMunicipio[];
  setMunicipioDepositarioOptions: React.Dispatch<
    React.SetStateAction<IMunicipio[]>
  >;
}

export function RegistroDepositarioTermoApreensaoLiberacao({
  isProprio,
  formApreensao,
  depositarioSignature,
  setDepositarioSignature,
  isValidDocument,
  setIsValidDocument,
  isEdit,
  municipioDepositarioOptions,
  setMunicipioDepositarioOptions,
}: IRegistroDepositarioTermoApreensaoLiberacaoProps) {
  const { listaPropriedadeSincronizadas } = usePropriedade();
  const { municipiosBrasil, listaUfs } = useMunicipio();
  const { cepMask, cpfCnpjMask, renasemMask, telefoneMask } = masks;

  const [isLoading, setIsLoading] = useState(false);
  const [statusError, setStatusError] = useState(false);

  const handleSearchDepositario = (value: string) => {
    const cpfCnpj = value ? value.replace(/[^\d]+/g, "") : "";

    if (!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
      openNotification({
        type: "error",
        message: "Erro ao buscar Depositario",
        description: "Digite um CPF/CNPJ válido para prosseguir.",
      });
      setStatusError(true);
      setIsValidDocument(false);
      return;
    }

    setIsValidDocument(true);
    setStatusError(false);

    buscaPropriedade(cpfCnpj);
  };

  const buscaPropriedade = async (cpfCnpj: string) => {
    try {
      if (navigator.onLine) {
        setIsLoading(true);
        const params = new URLSearchParams();
        params.append("cpfCnpj", cpfCnpj);
        const depositario = await apiService.pessoas(params.toString());

        if (depositario.length > 0) {
          setMunicipioDepositarioOptions(
            municipiosBrasil.filter(
              (municipio) =>
                municipio.uf.id === depositario[0].endereco.municipio.uf.id
            )
          );

          //SETANDO CAMPOS NO FORMULARIO
          formApreensao.setFieldsValue({
            nomeDepositario: depositario[0].nome,
            // renasemDepositario: depositario[0].renasem,
            enderecoDepositario: `${depositario[0].endereco.logradouro},${depositario[0].endereco.numero},${depositario[0].endereco.bairro}`,
            ufDepositarioId: depositario[0].endereco.municipio.uf.id,
            municipioDepositarioId: depositario[0].endereco.municipio.id,
            emailDepositario: depositario[0].email,
            telefoneDepositario: telefoneMask(depositario[0].endereco.telefone),
            cepDepositario: cepMask(depositario[0].endereco.cep),
            rgDepositario: depositario[0].inscricaoEstadual || "",
          });

          setDepositarioSignature({
            ...depositarioSignature,
            nomeAssinante: depositario[0].nome,
            cpfAssinante: cpfCnpjMask(depositario[0].cpfCnpj),
            funcaoAssinante: "Produtor",
          });
        } else {
          openNotification({
            type: "info",
            message: "Depositario não encontrado",
          });
        }

        setIsLoading(false);
      } else {
        const propriedadesOffline: IPropriedade[] = [];
        listaPropriedadeSincronizadas.forEach((item) => {
          item.propriedades.forEach((prop) => {
            prop.produtores.forEach((produtor) => {
              if (produtor.cpf === cpfCnpj) {
                propriedadesOffline.push(prop);
                //SETANDO CAMPOS NO FORMULARIO
                formApreensao.setFieldsValue({
                  nomeDepositario: produtor.nome,
                  enderecoDepositario: `${produtor.endereco.logradouro},${produtor.endereco.numero},${produtor.endereco.bairro}`,
                  renasemDepositario: produtor.renasem || "",
                  emailDepositario: produtor.email,
                  telefoneDepositario: telefoneMask(produtor.endereco.telefone),
                  cepDepositario: cepMask(produtor.endereco.cep),
                  ufDepositarioId: produtor.endereco.municipio.uf.id,
                  municipioDepositarioId: produtor.endereco.municipio.id,
                  rgDepositario: produtor.rg,
                });

                setMunicipioDepositarioOptions(
                  municipiosBrasil.filter(
                    (municipio) =>
                      municipio.uf.id === produtor.endereco.municipio.uf.id
                  )
                );

                setDepositarioSignature({
                  ...depositarioSignature,
                  nomeAssinante: produtor.nome,
                  cpfAssinante: cpfCnpjMask(produtor.cpf),
                  funcaoAssinante: "Produtor",
                });
              }
            });
          });
        });

        if (propriedadesOffline.length === 0) {
          openNotification({
            type: "info",
            message: "Estabelecimento Não encontrado",
          });
          return;
        }
      }
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel encontrar depositário",
      });
    }

    // console.log(propriedades);
  };

  const handleChangeCpfCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !cpf.isValid(e.target.value.replace(/[^\d]+/g, "")) ||
      !cnpj.isValid(e.target.value.replace(/[^\d]+/g, ""))
    ) {
      resetarDepositarioFields(formApreensao);
      setIsValidDocument(false);
    }

    formApreensao.setFieldValue(
      "cpfCnpjDepositario",
      cpfCnpjMask(e.target.value)
    );
  };

  const handleChangeUF = (value: number | undefined) => {
    formApreensao.setFieldValue("municipioDepositarioId", "");
    if (!value) {
      setMunicipioDepositarioOptions([]);
      return;
    }

    const municipios = municipiosBrasil.filter(
      (municipio) => municipio.uf.id === value
    );
    setMunicipioDepositarioOptions(municipios);
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (e.key === "Enter") {
      document.getElementById(id)?.focus();
    }
  };

  useEffect(() => {
    if (isEdit) {
      const municipios = municipiosBrasil.filter(
        (municipio) =>
          municipio.uf.id === formApreensao.getFieldValue("ufDepositarioId")
      );
      setMunicipioDepositarioOptions(municipios);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Form.Item
        label="CPF/CNPJ do Depositário"
        name="cpfCnpjDepositario"
        rules={[
          { required: !isProprio, message: "Digite o CPF/CNPJ do fiscalizado" },
        ]}
      >
        <Input.Search
          onSearch={handleSearchDepositario}
          onChange={handleChangeCpfCnpj}
          status={statusError ? "error" : ""}
          maxLength={18}
          allowClear
          enterButton="Buscar"
          placeholder="Digite o CPF/CNPJ"
          loading={isLoading}
          onKeyDown={(e) => handleKeyPress(e, "nomeDepositario")}
        />
      </Form.Item>
      <Form.Item
        label="Nome do Depositário"
        name="nomeDepositario"
        rules={[{ required: !isProprio }]}
      >
        <Input
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "ufDepositario")}
        />
      </Form.Item>

      <Form.Item label="UF do Depositário" name="ufDepositarioId">
        <Select
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          disabled={!isValidDocument}
          onChange={handleChangeUF}
          allowClear
        >
          {listaUfs?.map((uf) => (
            <Select.Option key={uf.id} value={uf.id}>
              {uf.sigla}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Municipio do Depositário"
        name="municipioDepositarioId"
        rules={[{ required: !isProprio }]}
      >
        <Select
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          disabled={!isValidDocument}
          allowClear
        >
          {municipioDepositarioOptions.map((municipio) => (
            <Select.Option key={municipio.id} value={municipio.id}>
              {`${municipio.nome}-${municipio.uf.sigla}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="RG do Depositário" name="rgDepositario">
        <Input
          id="rgDepositario"
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "renasemDepositario")}
        />
      </Form.Item>
      <Form.Item label="RENASEM do Depositário" name="renasemDepositario">
        <Input
          id="renasemDepositario"
          disabled={!isValidDocument}
          maxLength={13}
          onKeyDown={(e) => handleKeyPress(e, "enderecoDepositario")}
          onChange={(e) =>
            formApreensao.setFieldValue(
              "renasemDepositario",
              renasemMask(e.target.value).toUpperCase()
            )
          }
        />
      </Form.Item>

      <Form.Item label="Endereço do Depositário" name="enderecoDepositario">
        <Input
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "cepDepositario")}
        />
      </Form.Item>
      <Form.Item label="CEP do Depositário" name="cepDepositario">
        <Input
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "telefoneDepositario")}
        />
      </Form.Item>

      <Form.Item label="Telefone do Depositário" name="telefoneDepositario">
        <Input
          disabled={!isValidDocument}
          onKeyDown={(e) => handleKeyPress(e, "emailDepositario")}
        />
      </Form.Item>
      <Form.Item label="Email do Depositário" name="emailDepositario">
        <Input disabled={!isValidDocument} />
      </Form.Item>
    </Fragment>
  );
}

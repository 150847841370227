import { ITermoApreensaoLiberacao } from "../../../interfaces/ITermoApreensaoLiberacao";

export const converterJsonToFormDataTermoApreensaoLiberacao = (
  formValues: ITermoApreensaoLiberacao,
  anexos?: (File | undefined)[]
) => {
  const formData = new FormData();

  Object.entries(formValues).forEach((formValue) => {
    if (
      [
        "tif",
        "termoApreensao",
        "itens",
        "municipioDepositario",
        "municipioFiscalizado",
        "municipioFiscalizacao",
        "servidores",
        "anexos",
      ].includes(formValue[0])
    )
      return;

    formData.append(formValue[0], formValue[1]);
  });

  //ITENS
  formValues.itens.forEach((item, ind) => {
    Object.entries(item).forEach((formItem) => {
      if (
        [
          "especie",
          "cultivar",
          "fabricante",
          "embalagem",
          "produtoAgrotoxico",
          "unidadeMedida",
          "key",
          "id",
        ].includes(formItem[0])
      )
        return;

      formData.append(
        `itens[${ind}].${formItem[0]}`,
        formItem[1].toString() || ""
      );
    });

    if (item.cultivar) {
      Object.entries(item.cultivar).forEach((cultivarItem) => {
        formData.append(
          `itens[${ind}].cultivar.${cultivarItem[0]}`,
          cultivarItem[1]?.toString() || ""
        );
      });
    }

    if (item.especie) {
      Object.entries(item.especie).forEach((especieItem) => {
        if (
          ["pragasLsv", "cultivares", "grupoEspecieLaboratorio"].includes(
            especieItem[0]
          )
        )
          return;

        formData.append(
          `itens[${ind}].especie.${especieItem[0]}`,
          especieItem[1]?.toString() || ""
        );
      });

      item.especie.pragasLsv.forEach((pragaLsv, indicePraga) => {
        Object.entries(pragaLsv).forEach((pragaItem) => {
          if (["parte", "praga"].includes(pragaItem[0])) return;

          formData.append(
            `itens[${ind}].especie.pragaLsv[${indicePraga}].${pragaItem[0]}`,
            pragaItem[1]?.toString() || ""
          );
        });

        Object.entries(pragaLsv.parte).forEach((pragaItem) => {
          formData.append(
            `itens[${ind}].especie.pragaLsv[${ind}].parte.${pragaItem[0]}`,
            pragaItem[1]?.toString() || ""
          );
        });

        Object.entries(pragaLsv.praga).forEach((pragaItem) => {
          formData.append(
            `itens[${ind}].especie.pragaLsv[${ind}].praga.${pragaItem[0]}`,
            pragaItem[1]?.toString() || ""
          );
        });
      });
    }

    if (item.fabricante) {
      Object.entries(item.fabricante).forEach((fabricanteItem) => {
        if (["municipio"].includes(fabricanteItem[0])) return;
        formData.append(
          `itens[${ind}].fabricante.${fabricanteItem[0]}`,
          fabricanteItem[1]?.toString() || ""
        );
      });

      if (item.fabricante.municipio) {
        Object.entries(item.fabricante.municipio).forEach((fabricanteItem) => {
          if (["uf"].includes(fabricanteItem[0])) return;

          formData.append(
            `itens[${ind}].fabricante.municipio.${fabricanteItem[0]}`,
            fabricanteItem[1]?.toString() || ""
          );
        });

        Object.entries(item.fabricante.municipio.uf).forEach(
          (fabricanteItem) => {
            formData.append(
              `itens[${ind}].fabricante.municipio.uf.${fabricanteItem[0]}`,
              fabricanteItem[1]?.toString() || ""
            );
          }
        );
      }
    }

    if (item.embalagem) {
      Object.entries(item.embalagem).forEach((embalagemItem) => {
        formData.append(
          `itens[${ind}].embalagem.${embalagemItem[0]}`,
          embalagemItem[1]?.toString() || ""
        );
      });
    }

    if (item.produtoAgrotoxico) {
      Object.entries(item.produtoAgrotoxico).forEach((produtoItem) => {
        formData.append(
          `itens[${ind}].produtoAgrotoxico.${produtoItem[0]}`,
          produtoItem[1]?.toString() || ""
        );
      });
    }

    if (item.unidadeMedida) {
      Object.entries(item.unidadeMedida).forEach((unidadeMedidaItem) => {
        formData.append(
          `itens[${ind}].unidadeMedida.${unidadeMedidaItem[0]}`,
          unidadeMedidaItem[1]?.toString() || ""
        );
      });
    }
  });

  //MUNICIPIOS
  if (formValues.municipioFiscalizacao) {
    Object.entries(formValues.municipioFiscalizacao).forEach(
      (municipioForm) => {
        if (["uf"].includes(municipioForm[0])) return;

        formData.append(
          `municipioFiscalizacao.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );

    Object.entries(formValues.municipioFiscalizacao.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioFiscalizacao.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  if (formValues.municipioFiscalizado) {
    Object.entries(formValues.municipioFiscalizado).forEach((municipioForm) => {
      if (["uf"].includes(municipioForm[0])) return;

      formData.append(
        `municipioFiscalizado.${municipioForm[0]}`,
        municipioForm[1]?.toString() || ""
      );
    });

    Object.entries(formValues.municipioFiscalizado.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioFiscalizado.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  if (formValues.municipioDepositario) {
    Object.entries(formValues.municipioDepositario).forEach((municipioForm) => {
      if (["uf"].includes(municipioForm[0])) return;

      formData.append(
        `municipioDepositario.${municipioForm[0]}`,
        municipioForm[1]?.toString() || ""
      );
    });

    Object.entries(formValues.municipioDepositario.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioDepositario.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  //TIF
  if (formValues.tif) {
    Object.entries(formValues.tif).forEach((tif) => {
      if (["municipioFiscalizacao"].includes(tif[0])) return;

      formData.append(`tif.${tif[0]}`, tif[1]?.toString() || "");
    });

    Object.entries(formValues.tif.municipioFiscalizacao).forEach(
      (municipioForm) => {
        if (["uf"].includes(municipioForm[0])) return;

        formData.append(
          `tif.municipioFiscalizacao.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );

    Object.entries(formValues.tif.municipioFiscalizacao.uf).forEach(
      (municipioForm) => {
        formData.append(
          `tif.municipioFiscalizacao.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  //TERMO APREENSAO
  if (formValues.termoApreensao) {
    Object.entries(formValues.termoApreensao).forEach((termo) => {
      if (termo[0] !== "id") return;

      formData.append(
        `termoApreensao.${termo[0]}`,
        termo[1]?.toString() || "" || ""
      );
    });
  }

  //SERVIDORES
  formValues.servidores.forEach((serv, ind) => {
    formData.append(`servidores[${ind}].assinatura`, serv.assinatura || "");
    Object.entries(serv.servidor).forEach((servidorForm) => {
      formData.append(
        `servidores[${ind}].servidor.${servidorForm[0]}`,
        servidorForm[1]?.toString() || ""
      );
    });
  });

  //ANEXO
  const file = new File([], "");

  if (!anexos || anexos.length === 0) {
    formData.append("anexos", file);
  }

  anexos?.forEach((item) => {
    formData.append("anexos", item || file);
  });

  return formData;
};

import { useDispatch, useSelector } from "react-redux";
import { tidAction } from "../../../store/actions/tidAction";
import { IDadosAssinaturaFiscalizadoTID, ITID } from "../../../interfaces/ITid";
import { getTIDs } from "../../../store/feature/tidSlice";
import { getFiltroTid } from "../../../store/feature/filtroTidSlice";
import { filtroTidAction } from "../../../store/actions/filtroTidAction";

export const useActionsTID = () => {
  const {
    addTermo,
    addTermoCompleto,
    removeTermo,
    updateTermo,
    updateAssinatura,
  } = tidAction;
  const { addFiltro, removeFiltro } = filtroTidAction;
  const listaFiltroTID = useSelector(getFiltroTid);
  const listaTIDs = useSelector(getTIDs);
  const appDispatch = useDispatch();

  const adicionarTermo = (tidJson: ITID) => {
    appDispatch(addTermo(tidJson));
  };

  const adicionarTermoSubmited = (termo: ITID) => {
    appDispatch(addTermoCompleto(termo));
  };

  const removerTermo = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarTermo = (termo: ITID) => {
    appDispatch(updateTermo(termo));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltro(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltro());
  };

  const atualizarAssinatura = (
    dadosAssinatura: IDadosAssinaturaFiscalizadoTID,
    termoId: number,
    tid: ITID
  ) => {
    appDispatch(updateAssinatura(dadosAssinatura, termoId, tid));
  };

  return {
    adicionarTermo,
    removerTermo,
    atualizarTermo,
    adicionarTermoSubmited,
    listaTIDs,
    listaFiltroTID,
    removerFiltro,
    aplicarFiltro,
    atualizarAssinatura,
  };
};

import { IPerguntaTermoVistoria } from "../../../interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "../../../interfaces/ITermoVistoria";

export const getRespostasVistoria = (
  perguntas: IPerguntaTermoVistoria[],
  values: any
) => {
  const { respostas }: Partial<ITermoVistoria> = { respostas: [] };

  for (let i = 0; i < perguntas.length; i++) {
    delete perguntas[i].indPergunta;

    if (perguntas[i].pergunta.tipoResposta === "ALTERNATIVA") {
      respostas.push({
        pergunta: perguntas[i].pergunta,
        descricao: "",
        alternativa: perguntas[i].pergunta.alternativas.filter(
          (alt) =>
            alt.id ===
            values[`resposta_alternativa_${perguntas[i].pergunta.id}`]
        )[0],
        campos: perguntas[i].pergunta.campos.map((campo, ind) => {
          return {
            campo: campo,
            // id: ind,
            resposta:
              values[`resposta_campo_${ind}_${perguntas[i].pergunta.id}`],
          };
        }),
        order: perguntas[i].order,
      });
    } else if (perguntas[i].pergunta.tipoResposta === "DESCRITIVA") {
      respostas.push({
        pergunta: perguntas[i].pergunta,
        descricao: values[`resposta_descritiva_${perguntas[i].pergunta.id}`],
        alternativa: null,
        campos: perguntas[i].pergunta.campos.map((campo, ind) => {
          return {
            campo: campo,
            resposta:
              values[`resposta_campo_${ind}_${perguntas[i].pergunta.id}`],
          };
        }),
        order: perguntas[i].order,
      });
    } else {
      respostas.push({
        pergunta: perguntas[i].pergunta,
        descricao: "",
        alternativa: perguntas[i].pergunta.alternativas.filter(
          (alt) =>
            alt.id ===
            values[`resposta_alternativa_${perguntas[i].pergunta.id}`]
        )[0],
        campos: perguntas[i].pergunta.campos.map((campo, ind) => {
          return {
            campo: campo,
            resposta:
              values[`resposta_campo_${ind}_${perguntas[i].pergunta.id}`],
          };
        }),
        order: perguntas[i].order,
      });
    }
  }

  return respostas;
};

import jwtDecode from "jwt-decode";

interface ITokenPayload {
  exp: number;
  iat: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string; //id do usuario
  typ: string;
  azp: string;
  session_state: string;

  scope: string;
  sid: string;
  email_verified: string;
  cpf: string;
  name: string;
  preferred_username: string;
  given_name: string;
  dataNascimento: string;
  family_name: string;
  email: string;
  nomeMae: string;
}

export const getPayloadUser = (token: string | null) => {
  try {
    const decodedToken: ITokenPayload = jwtDecode(token || "");

    return decodedToken;
  } catch (error) {
    return;
  }
};

import { createAction } from "@reduxjs/toolkit";
import { IEmbalagem } from "../../interfaces/IEmbalagem";

const addEmbalagens = createAction(
  "EMBALAGEM/addEmbalagens",
  (embalagens: IEmbalagem[], createdAt: number) => ({
    payload: { embalagens, createdAt },
  })
);

const removeEmbalagens = createAction("EMBALAGEM/removeEmbalagens", () => ({
  payload: {},
}));

export const embalagemAction = { addEmbalagens, removeEmbalagens };

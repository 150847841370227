import { createAction } from "@reduxjs/toolkit";
import { IServidor } from "../../interfaces/IServidor";

const addServidores = createAction(
  "SERVIDOR/addServidores",
  (servidores: IServidor[]) => ({
    payload: { servidores },
  })
);

export const servidorAction = { addServidores };

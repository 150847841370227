import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../";
import { IEstabelecimento } from "../../interfaces/IEstabelecimento";

interface IEstabelecimentoState {
  items: {
    error?: string;
    municipioId: number;
    estabelecimentos: IEstabelecimento[];
  }[];
  persistedAt: number;
}

const initialState: IEstabelecimentoState = {
  items: [],
  persistedAt: 0,
};

const estabelecimentoSlice = createSlice({
  name: "ESTABELECIMENTO",
  initialState,
  reducers: {
    addEstabelecimentos: (state, action) => {
      const { items, dateTimeCreate } = action.payload;

      try {
        if (items.estabelecimentos.length === 0) {
          state.items.push({ ...items, error: "Erro estabelecimentos" });
          return;
        }

        if (!items) {
          state.items = [];
          return;
        }

        state.items.push(items);
        state.persistedAt = dateTimeCreate;
      } catch (error) {
        console.log(error);
      }
    },
    removeEstabelecimentos: (state, action) => {
      const idMunicipio = action.payload;
      const estabelecimentosFilter = state.items.filter(
        (item) => item.municipioId !== idMunicipio
      );
      state.items = estabelecimentosFilter;
    },
    updateEstabelecimentos: (state, action) => {
      const { items, dateTimeUpdate } = action.payload;

      try {
        if (items.estabelecimentos.length === 0) return;

        if (!items) return;

        const updateProps = state.items.map((estab: any) => {
          if (estab.municipioId === items.municipioId) {
            return items;
          } else {
            return estab;
          }
        });
        state.items = updateProps;
        state.persistedAt = dateTimeUpdate;
      } catch (error) {
        console.log(error);
      }
    },
  },
});

export const getEstabelecimentos = (state: RootState) =>
  state.estabelecimentos.items;

export default estabelecimentoSlice.reducer;

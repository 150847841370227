import { createAction } from "@reduxjs/toolkit";

const addFiltroTermoColeta = createAction(
  "FILTRO_TERMO_COLETA/addFiltroTermoColeta",
  (termos) => ({
    payload: {termos},
  })
);

const removeFiltroTermoColeta = createAction(
  "FILTRO_TERMO_COLETA/removeFiltroTermoColeta",
  () => ({
    payload: {},
  })
);

export const filtroTermoColetaAction = {
  addFiltroTermoColeta,
  removeFiltroTermoColeta,
};

import { useDispatch, useSelector } from "react-redux";
import { getPersistedAtTermoApreensaoLiberacao } from "../store/feature/termoApreensaoLiberacaoSlice";
import { termoApreensaoLiberacaoAction } from "../store/actions/termoApreensaoLiberacaoAction";
import { getItensTermoApreensaoLiberacao } from "../store/feature/itensTermoApreensaoLiberacaoSlice";
import { itensTermoApreensaoLiberacaoAction } from "../store/actions/itensTermoApreensaoLiberacaoAction";
import { IItemTermoApreensao } from "../interfaces/IItemTermoApreensao";

export const useDadosTermoApreensaoLiberacao = () => {
  const termoApreensaoLiberacaoPersistedAtItems = useSelector(
    getPersistedAtTermoApreensaoLiberacao
  );
  const listaItens = useSelector(getItensTermoApreensaoLiberacao);
  const { addItem, removeItem, removeAllItens, updateItem } =
    itensTermoApreensaoLiberacaoAction;

  const { addTimePersistedAtItems, removeTimePersistedAtItems } =
    termoApreensaoLiberacaoAction;
  const appDispatch = useDispatch();

  const adicionarPersistedAtTermoApreensaoLiberacao = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTermoApreensaoLiberacao = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  const adicionarItem = (item: IItemTermoApreensao) => {
    appDispatch(addItem(item));
  };

  const removerItem = (itemId: number) => {
    appDispatch(removeItem(itemId));
  };

  const removerTodosItems = () => {
    appDispatch(removeAllItens());
  };

  const atualizarItem = (item: IItemTermoApreensao) => {
    appDispatch(updateItem(item));
  };

  return {
    termoApreensaoLiberacaoPersistedAtItems,
    adicionarPersistedAtTermoApreensaoLiberacao,
    removerPersistedAtTermoApreensaoLiberacao,
    listaItens,
    adicionarItem,
    removerItem,
    removerTodosItems,
    atualizarItem,
  };
};

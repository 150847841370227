import { useDispatch, useSelector } from "react-redux";
import { getMaterialColetado } from "../store/feature/materialColetadoSlice";
import { materialColetadoAction } from "../store/actions/materialColetadoAction";
import { IMaterialColetado } from "../interfaces/IMaterialColetado";

export const useMateriaisColetados = () => {
  const listaMateriaisColetados = useSelector(getMaterialColetado);
  const { addMaterialColetado, removeMaterialColetado } =
    materialColetadoAction;
  const appDispatch = useDispatch();

  const adicionarMateriaisColetados = (
    materiais: IMaterialColetado[],
    persistedAt: number
  ) => {
    appDispatch(addMaterialColetado(materiais, persistedAt));
  };

  const removerMateriaisColetados = () => {
    appDispatch(removeMaterialColetado());
  };

  return {
    listaMateriaisColetados,
    adicionarMateriaisColetados,
    removerMateriaisColetados,
  };
};

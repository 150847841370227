import styled from "styled-components";
import { masks } from "../../../../../../functions/mascaras";
import { ITID } from "../../../../../../interfaces/ITid";
import { useAtividadeInterditada } from "../../../../../../hooks/useAtividadeInterditada";

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  height: 2vh;
  align-items: center;
  display: flex;
`;

export function BodyImpressaoTID({ tid }: { tid: ITID }) {
  const { cepMask, telefoneMask, cpfCnpjMask } = masks;
  const { listaAtividadesInterditadas } = useAtividadeInterditada();

  const atividadeInterditadas = listaAtividadesInterditadas?.filter(
    (atvInterdicao) => {
      if (
        tid.atividades?.find(
          (atv) => atv.atividade.id === atvInterdicao.atividade.id
        ) &&
        tid.atividades?.find(
          (atv) => atv.areaAtividade.id === atvInterdicao.areaAtividade.id
        )
      ) {
        return atvInterdicao;
      }

      return null;
    }
  );

  return (
    <>
      <tbody>
        <tr>
          <td
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <div style={{ borderBottom: "1px solid black" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "0px 20px",
                  backgroundColor: "#D9D9D9",
                }}
              >
                <div style={{ display: "flex", gap: 2 }}>
                  <input
                    type="checkbox"
                    readOnly
                    value={"PROPRIEDADE"}
                    checked={
                      tid.tif?.caracteristicaFiscalizado === "PROPRIEDADE"
                    }
                  />
                  <label>PROPRIEDADE</label>
                </div>

                <div style={{ display: "flex", gap: 2 }}>
                  <input
                    type="checkbox"
                    readOnly
                    value={"ESTABELECIMENTO"}
                    checked={
                      tid.tif?.caracteristicaFiscalizado === "ESTABELECIMENTO"
                    }
                  />
                  <label>ESTABELECIMENTO</label>
                </div>
              </div>
            </div>

            <Container style={{ paddingLeft: 5 }}>
              Nome/Razao social:
              {tid.tif?.nomePropriedade || tid.nomeFiscalizado}
            </Container>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CPF/CNPJ:{cpfCnpjMask(tid.cpfCnpjFiscalizado)}
              </div>
              <div
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                RG:{tid.inscricaoEstadualFiscalizado}
              </div>

              <div style={{ width: "30%", paddingLeft: 5 }}>RENASEM:{}</div>
            </Container>

            <div style={{ paddingLeft: 5, borderBottom: "1px solid black" }}>
              Endereço:{tid.enderecoFiscalizado}
            </div>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Municipio:{tid.tif?.municipioFiscalizacao?.nome}
              </div>
              <div
                style={{
                  width: "10%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                UF:{tid.tif?.municipioFiscalizacao?.uf.sigla}
              </div>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CEP:{cepMask(tid.cepFiscalizado)}
              </div>
              <div style={{ width: "25%", paddingLeft: 5 }}>
                Telefone:{telefoneMask(tid.telefoneFiscalizado)}
              </div>
            </Container>

            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Email: {tid.tif?.emailFiscalizado}
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                  paddingLeft: 5,
                }}
              >
                <span>Localização Geografica:</span>

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>S</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>O</span>
                </div>
              </div>
            </Container>

            <div style={{ borderBottom: "1px solid black" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "0px 20px",
                  backgroundColor: "#D9D9D9",
                }}
              >
                <div style={{ display: "flex", gap: 2 }}>
                  <input
                    type="checkbox"
                    readOnly
                    value={"PROPRIETÁRIO"}
                    checked={tid.tipoRepresentante === "PROPRIETARIO"}
                  />
                  <label>PROPRIETARIO</label>
                </div>

                <div style={{ display: "flex", gap: 2 }}>
                  <input
                    type="checkbox"
                    readOnly
                    value={"ARRENDATÁRIO"}
                    checked={tid.tipoRepresentante === "ARRENDATARIO"}
                  />
                  <label>ARRENDATARIO</label>
                </div>

                <div style={{ display: "flex", gap: 2 }}>
                  <input
                    type="checkbox"
                    readOnly
                    value={"OCUPANTE"}
                    checked={tid.tipoRepresentante === "OCUPANTE"}
                  />
                  <label>OCUPANTE</label>
                </div>
              </div>
            </div>

            <Container style={{ paddingLeft: 5 }}>
              Nome/Razao social:{tid.nomeFiscalizado}
            </Container>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CPF/CNPJ:{cpfCnpjMask(tid.cpfCnpjFiscalizado)}
              </div>
              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                RG:{tid.inscricaoEstadualFiscalizado}
              </div>

              <div style={{ width: "40%", paddingLeft: 5 }}>
                Email:{tid.emailFiscalizado}
              </div>
            </Container>

            <div style={{ paddingLeft: 5, borderBottom: "1px solid black" }}>
              Endereço:{tid.enderecoFiscalizado}
            </div>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Municipio:{tid.municipioFiscalizado?.nome}
              </div>
              <div
                style={{
                  width: "10%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                UF:{tid.municipioFiscalizado?.uf.sigla}
              </div>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CEP:{cepMask(tid.cepFiscalizado)}
              </div>
              <div style={{ width: "25%", paddingLeft: 5 }}>
                Telefone:{telefoneMask(tid.telefoneFiscalizado)}
              </div>
            </Container>

            <Container
              style={{
                backgroundColor: "#D9D9D9",
                fontWeight: "bold",
                justifyContent: "center",
              }}
            >
              ATIVIDADES{" "}
              {tid.tipoTermo === "DESINTERDICAO"
                ? "DESINTERDITADAS"
                : "INTERDITADAS"}{" "}
              E AMPARO LEGAL
            </Container>

            <div
              style={{
                minHeight: "40vh",
                borderBottom: "1px solid black",
                padding: 5,
              }}
            >
              {atividadeInterditadas.map((atv, ind) => {
                return (
                  <p key={ind}>
                    {ind + 1}- {atv.atividadeInterditada}
                  </p>
                );
              })}

              <p>{tid.atividadesInterditadas}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}

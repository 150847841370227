import { ITID } from "../../../interfaces/ITid";

export function converterTIDtoFormData(
  formValues: ITID,
  anexos?: (File | undefined)[]
) {
  const formData = new FormData();

  Object.entries(formValues).forEach((formValue) => {
    if (
      [
        "tif",
        "termoInterdicao",
        "municipioFiscalizado",
        "municipioFiscalizacao",
        "servidores",
        "anexos",
        "atividades",
      ].includes(formValue[0])
    )
      return;

    formData.append(formValue[0], formValue[1]);
  });

  //ATIVIDADES
  formValues.atividades.forEach((atv, i) => {
    formData.append(`atividades[${i}].id`, atv.id.toString() || "");

    Object.entries(atv.areaAtividade).forEach((areaAtividade) => {
      formData.append(
        `atividades[${i}].areaAtividade.${areaAtividade[0]}`,
        areaAtividade[1]?.toString() || ""
      );
    });

    Object.entries(atv.atividade).forEach((atividade) => {
      formData.append(
        `atividades[${i}].atividade.${atividade[0]}`,
        atividade[1]?.toString() || ""
      );
    });
  });

  //MUNICIPIOS
  if (formValues.municipioFiscalizacao) {
    Object.entries(formValues.municipioFiscalizacao).forEach(
      (municipioForm) => {
        if (["uf"].includes(municipioForm[0])) return;

        formData.append(
          `municipioFiscalizacao.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );

    Object.entries(formValues.municipioFiscalizacao.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioFiscalizacao.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  if (formValues.municipioFiscalizado) {
    Object.entries(formValues.municipioFiscalizado).forEach((municipioForm) => {
      if (["uf"].includes(municipioForm[0])) return;

      formData.append(
        `municipioFiscalizado.${municipioForm[0]}`,
        municipioForm[1]?.toString() || ""
      );
    });

    Object.entries(formValues.municipioFiscalizado.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioFiscalizado.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  //TERMO INTERDICAO
  if (formValues.termoInterdicao) {
    Object.entries(formValues.termoInterdicao).forEach((termo) => {
      if (termo[0] !== "id") return;

      formData.append(
        `termoInterdicao.${termo[0]}`,
        termo[1]?.toString() || "" || ""
      );
    });
  }

  //SERVIDORES
  formValues.servidores.forEach((serv, ind) => {
    formData.append(`servidores[${ind}].assinatura`, serv.assinatura || "");
    Object.entries(serv.servidor).forEach((servidorForm) => {
      formData.append(
        `servidores[${ind}].servidor.${servidorForm[0]}`,
        servidorForm[1]?.toString() || ""
      );
    });
  });

  //ANEXO
  const file = new File([], "");

  if (!anexos || anexos.length === 0) {
    formData.append("anexos", file);
  }

  anexos?.forEach((item) => {
    formData.append("anexos", item || file);
  });

  //TIF
  if (formValues.tif) {
    Object.entries(formValues.tif).forEach((formValue) => {
      if (
        [
          "anexosTIF",
          "atividades",
          "estabelecimento",
          "municipioFiscalizacao",
          "municipioFiscalizado",
          "produtor",
          "propriedade",
          "respostasAtividades",
          "servidores",
          "ule",
          "assinatura",
          "latGrauPropriedade",
          "latMinPropriedade",
          "latSegPropriedade",
          "longGrauPropriedade",
          "longMinPropriedade",
          "longSegPropriedade",
        ].includes(formValue[0])
      )
        return;

      formData.append(`tif.${formValue[0]}`, formValue[1]?.toString() || "");
    });

    //MUNICIPIOS
    if (formValues.tif.municipioFiscalizacao) {
      Object.entries(formValues.tif.municipioFiscalizacao).forEach(
        (municipioForm) => {
          if (["uf"].includes(municipioForm[0])) return;

          formData.append(
            `tif.municipioFiscalizacao.${municipioForm[0]}`,
            municipioForm[1]?.toString() || ""
          );
        }
      );

      Object.entries(formValues.tif.municipioFiscalizacao.uf).forEach(
        (municipioForm) => {
          formData.append(
            `tif.municipioFiscalizacao.uf.${municipioForm[0]}`,
            municipioForm[1]?.toString() || ""
          );
        }
      );
    }

    if (formValues.tif.municipioFiscalizado) {
      Object.entries(formValues.tif.municipioFiscalizado).forEach(
        (municipioForm) => {
          if (["uf"].includes(municipioForm[0])) return;

          formData.append(
            `tif.municipioFiscalizado.${municipioForm[0]}`,
            municipioForm[1]?.toString() || ""
          );
        }
      );

      Object.entries(formValues.tif.municipioFiscalizado.uf).forEach(
        (municipioForm) => {
          formData.append(
            `tif.municipioFiscalizado.uf.${municipioForm[0]}`,
            municipioForm[1]?.toString() || ""
          );
        }
      );
    }
  }

  return formData;
}

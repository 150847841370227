import { createAction } from "@reduxjs/toolkit";
import { IListagemFormularioPerguntas } from "../../interfaces/IListagemFormularioPerguntas";

const addFormsPerguntaVistoria = createAction(
  "FORMULARIO_PERGUNTAS_VISTORIA/addFormsPerguntaVistoria",
  (forms: IListagemFormularioPerguntas[], dateTimeExpire: number) => ({
    payload: { forms, dateTimeExpire },
  })
);

const removeFormsPerguntaVistoria = createAction(
  "FORMULARIO_PERGUNTAS_VISTORIA/removeFormsPerguntaVistoria",
  () => ({ payload: {} })
);

export const formularioPerguntasVistoriaAction = {
  addFormsPerguntaVistoria,
  removeFormsPerguntaVistoria,
};

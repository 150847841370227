import styles from "./style.module.css";
import { HeaderBoletimLasgal } from "./components/HeaderBoletimLasgal";
import { BodyBoletimLasgal } from "./components/BodyBoletimLasgal";
import { FooterBoletimLasgal } from "./components/FooterBoletimLasgal";
import { IBoletimAmostra } from "../../../../interfaces/IBoletimAmostra";
import { IAmostraDadosLasgal } from "../../../../interfaces/IAmostraDadosLasgal";

interface IImpressaoBoletimLasgalProps {
  boletim: IBoletimAmostra;
  amostra: IAmostraDadosLasgal;
}

export function ImpressaoBoletimLasgal({
  boletim,
  amostra,
}: IImpressaoBoletimLasgalProps) {
  return (
    <>
      <div className="marcadagua">HOMOLOGAÇÃO</div>
      <table className={`${styles.width}`}>
        <HeaderBoletimLasgal boletim={boletim} />
        <BodyBoletimLasgal boletim={boletim} amostra={amostra} />
        <FooterBoletimLasgal assinatura={boletim?.assinaturaServidor || ""} />
      </table>
    </>
  );
}

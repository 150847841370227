import { createAction } from "@reduxjs/toolkit";
import { IAmostra } from "../../interfaces/IAmostra";

const addAmostra = createAction(
  "AMOSTRAS/addAmostra",
  (amostra: IAmostra) => ({
    payload: { amostra },
  })
);

const removeAmostra = createAction(
  "AMOSTRAS/removeAmostra",
  (idAmostra: number) => ({
    payload: { idAmostra },
  })
);

const removeAllAmostras = createAction("AMOSTRAS/removeAllAmostras", () => ({
  payload: {},
}));

export const amostraAction = { addAmostra, removeAmostra, removeAllAmostras };

import { createAction } from "@reduxjs/toolkit";
import { IPerfil } from "../../interfaces/IPerfil";

const addPerfis = createAction("PERFIL/addPerfis", (perfis: IPerfil[]) => ({
  payload: { perfis },
}));

const removeItem = createAction("PERFIL/removeItem", (idPerfil: number) => ({
  payload: { idPerfil },
}));

const updateNome = createAction(
  "PERFIL/updateNome",
  (idPerfil: number, nomeNovo: string) => ({
    payload: { idPerfil, nomeNovo },
  })
);

const updatePermissoes = createAction(
  "PERFIL/updatePermissoes",
  (idPerfil: number, permissoes) => ({
    payload: { idPerfil, permissoes },
  })
);

const addItem = createAction("PERFIL/addItem", (perfil: IPerfil) => ({
  payload: { perfil },
}));

export const perfilAction = {
  addItem,
  addPerfis,
  updateNome,
  updatePermissoes,
  removeItem,
};

import { Button } from "antd";
import { ITabelaTesteLasgal } from "../../../../../interfaces/ITabelaTesteLasgal";
import { VALOR_PADRAO } from "../../constants";

export function ButtonConfirmaGerminacao({
  hasGerminacao,
  dataTable,
  armazenarDadosGerminacao,
}: {
  hasGerminacao: boolean;
  dataTable: ITabelaTesteLasgal[];
  armazenarDadosGerminacao: (value: ITabelaTesteLasgal[]) => void;
}) {
  const onChangeStatusGerminacao = () => {
    const auxDataTable = [
      dataTable[0],
      {
        ...dataTable[1],
        normais: hasGerminacao ? VALOR_PADRAO : "",
        anormais: hasGerminacao ? VALOR_PADRAO : "",
        duras: hasGerminacao ? VALOR_PADRAO : "",
        dormentes: hasGerminacao ? VALOR_PADRAO : "",
        mortas: hasGerminacao ? VALOR_PADRAO : "",
      },
    ];

    armazenarDadosGerminacao(auxDataTable);
  };

  return (
    <Button
      type={hasGerminacao ? "primary" : "default"}
      style={{ width: 250 }}
      onClick={onChangeStatusGerminacao}
    >
      {hasGerminacao ? "Não fez Germinação" : "Fez Germinação"}
    </Button>
  );
}

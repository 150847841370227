import styled from "styled-components";

export const AssinaturaContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
`;

import { createAction } from "@reduxjs/toolkit";
import { IFormAtividades } from "../../interfaces/IFormAtividades";
import { IRespostaAtividade } from "../../interfaces/IRespostaAtividade";

const addAtividades = createAction(
  "ATIVIDADE/addAtividades",
  (values: IFormAtividades) => ({
    payload: values,
  })
);

const updateAtividade = createAction(
  "ATIVIDADE/updateAtividade",
  (atividade: IFormAtividades) => ({ payload: { atividade } })
);

const removeAtividades = createAction(
  "ATIVIDADE/removeAtividades",
  (key: number, atividade: IFormAtividades) => ({
    payload: { key, atividade },
  })
);

const removeAllAtividades = createAction(
  "ATIVIDADE/removeAllAtividades",
  () => ({
    payload: {},
  })
);

const addRespostasAtividade = createAction(
  "ATIVIDADE/addRespostasAtividade",
  (respostas: IRespostaAtividade[]) => ({
    payload: { respostas },
  })
);

export const atividadeAction = {
  addAtividades,
  removeAtividades,
  removeAllAtividades,
  addRespostasAtividade,
  updateAtividade,
};

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Select,
  Space,
} from "antd";
import { useState } from "react";
import { ITipoAtividade } from "../../../../interfaces/ITipoAtividade";
import moment, { Moment } from "moment";
import { apiService } from "../../../../services/api";
import { useQuery } from "react-query";
import { openNotification } from "../../../../components/Notifications";
import { IFormAtividades } from "../../../../interfaces/IFormAtividades";
import { ESPECIFICACOES } from "./constants/ESPECIFICACOES";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useAreaAtividades } from "../../../../hooks/useAreaAtividades";
import { useDadosTif } from "../../../../hooks/useDadosTif";
import { TabelaAtividadeTIF } from "./components/TabelaAtividadeTIF";
import { IArmadilha } from "../../../../interfaces/IArmadilha";
import { useArmadilha } from "../../../../hooks/useArmadilha";

const ID_ATV_MOSCA_CARAMBOLA = 41;

interface IFormValuesAtividadeTif {
  prazo: Moment;
  programaId: number;
  atividadeId: number;
  especificacoes: string[];
  armadilhaId: number;
}

interface IAtividadesTIFProps {
  atividadeForm: FormInstance;
  isEdit?: boolean;
  municipioFiscalizacaoId: number | undefined;
}

export function AtividadesTIF({
  atividadeForm,
  isEdit,
  municipioFiscalizacaoId,
}: IAtividadesTIFProps) {
  const { programas } = useAreaAtividades();
  const { atividadesTif, adicionarAtividade } = useDadosTif();
  const { getArmadilhaByMunicipio } = useArmadilha();
  const [isAtvMoscaDaCarambola, setIsAtvMoscaDaCarambola] = useState(false);
  const [isSelectNotification, setIsSelectNotification] = useState(false);
  const [atividadesOptions, setAtividadesOptions] = useState<ITipoAtividade[]>(
    []
  );
  const [armadilhasDisponiveis, setArmadilhasDisponiveis] = useState<
    IArmadilha[]
  >([]);

  //QUERY DE REQUEST DAS ATIVIDADES
  const { data: listaProgramas } = useQuery({
    queryKey: ["PROGRAMA_ATIVIDADE"],
    initialData: programas,
    queryFn: () => apiService.programas(),
  });

  /**FUNCTIONS */

  const handleChangeEspecificacao = (checkedValue: CheckboxValueType[]) => {
    if (checkedValue.some((especificacao) => especificacao === "Notificação")) {
      setIsSelectNotification(true);
    } else {
      atividadeForm.setFieldValue("prazo", "");
      setIsSelectNotification(false);
    }
  };

  //SELEÇAO DE ATIVIDADES
  const handleChangePrograma = async (value: number) => {
    if (!listaProgramas) return;

    let atividades = listaProgramas.filter(
      (programa) => programa.id === value
    )[0].atividades;

    if (isEdit) {
      setAtividadesOptions(
        atividades.filter((atv) => atv.questoes.length === 0)
      );
      atividadeForm.setFieldsValue({ atividadeId: "" });
      return;
    }

    setAtividadesOptions(atividades);
    atividadeForm.setFieldsValue({ atividadeId: "" });
  };

  const handleChangeAtividade = async (value: number) => {
    if (value === ID_ATV_MOSCA_CARAMBOLA) {
      const armadilhas = await getArmadilhaByMunicipio(municipioFiscalizacaoId);
      setArmadilhasDisponiveis(armadilhas);
    }

    setIsAtvMoscaDaCarambola(value === ID_ATV_MOSCA_CARAMBOLA);

    atividadeForm.resetFields([
      `${value === ID_ATV_MOSCA_CARAMBOLA ? "especificacoes" : "armadilha"}`,
    ]);
  };

  const handleSubmit = (value: IFormValuesAtividadeTif) => {
    //VALIDACOES
    if (!listaProgramas) return;

    const isSameProgram = atividadesTif.find(
      (atv) => atv.programa.id === value.programaId
    );

    if (
      isSameProgram &&
      atividadesTif.some(
        (atv) =>
          atv.atividade.id === value.atividadeId &&
          value.atividadeId !== ID_ATV_MOSCA_CARAMBOLA
      )
    ) {
      openNotification({
        type: "info",
        message: "Não foi possível adicionar atividade",
        description: "Atividade já foi selecionada.",
      });
      return;
    }

    //se a atividade for igual e as duas moscas carambola
    const atividadesComMoscaCarambola = atividadesTif.filter(
      (atv) => atv.atividade.id === ID_ATV_MOSCA_CARAMBOLA
    );

    if (
      atividadesComMoscaCarambola.some(
        (atv) => atv.armadilha?.id === value.armadilhaId
      )
    ) {
      openNotification({
        type: "info",
        message: "Não foi possível adicionar atividade",
        description: "Atividade com armadilha ja foi selecionada.",
      });
      return;
    }

    const armadilhaSelected = armadilhasDisponiveis.find(
      (arm) => arm.id === value.armadilhaId
    );

    try {
      // RESULTADO
      const resposta: IFormAtividades[] = [];

      const especificacoes = armadilhaSelected
        ? armadilhaSelected.codigo
        : value.especificacoes
        ? value.especificacoes?.join(", ")
        : "";

      listaProgramas.forEach((programa) => {
        if (programa.id === value.programaId) {
          const atividade = programa.atividades.find(
            (atv) => atv.id === value.atividadeId
          );

          if (!atividade) return;

          resposta.push({
            programa: {
              id: programa.id,
              label: programa.nome,
              value: programa.nomePrograma,
            },
            atividade: atividade,
            prazo: value.prazo
              ? moment(value.prazo).locale("pt-br").format().slice(0, 10)
              : "",
            especificacoes: especificacoes,
            key: Date.now(),
            armadilha: armadilhaSelected ?? null,
          });
        }
      });

      // console.log(resposta);
      adicionarAtividade(resposta[0]);
      atividadeForm.resetFields();
      setIsSelectNotification(false);
      setIsAtvMoscaDaCarambola(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Form
        labelCol={{ span: 20, flex: "150px" }}
        wrapperCol={{ flex: 1, span: 14 }}
        labelWrap
        labelAlign="left"
        onFinish={handleSubmit}
        form={atividadeForm}
      >
        <Form.Item
          label="Programa"
          name="programaId"
          rules={[{ required: true }]}
        >
          <Select
            style={{ minWidth: "190px" }}
            allowClear
            onChange={handleChangePrograma}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input) ||
              (option!.children as unknown as string).includes(
                input.toUpperCase()
              )
            }
          >
            {listaProgramas?.map((programa) => (
              <Select.Option key={programa.id} value={programa.id}>
                {programa.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Atividade"
          name="atividadeId"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            style={{ minWidth: "190px" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input) ||
              (option!.children as unknown as string).includes(
                input.toUpperCase()
              )
            }
            onChange={handleChangeAtividade}
          >
            {atividadesOptions.map((atividade) => (
              <Select.Option key={atividade.id} value={atividade.id}>
                {atividade.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {isAtvMoscaDaCarambola && (
          <Form.Item
            label="Armadilha Monitorada"
            name="armadilhaId"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              style={{ minWidth: "190px" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input) ||
                (option!.children as unknown as string).includes(
                  input.toUpperCase()
                )
              }
            >
              {armadilhasDisponiveis.map((arm) => {
                if (!arm.ativo) return null;

                return (
                  <Select.Option key={arm.id} value={arm.id}>
                    {arm.codigo}-{arm.localidade}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="especificacoes" label="Especificações">
          <Checkbox.Group
            disabled={isAtvMoscaDaCarambola}
            options={ESPECIFICACOES}
            onChange={handleChangeEspecificacao}
          />
        </Form.Item>

        <Form.Item
          name="prazo"
          label="Prazo p/ adequação: "
          rules={[
            {
              required: isSelectNotification && !isAtvMoscaDaCarambola,
              message: "Selecione um prazo",
            },
          ]}
        >
          <DatePicker
            placeholder="Prazo"
            format={"DD/MM/YYYY"}
            disabled={!isSelectNotification || isAtvMoscaDaCarambola}
          />
        </Form.Item>

        <Row justify="center">
          <Space direction="vertical" size={10}>
            <Button type="primary" htmlType="submit">
              Adicionar Atividade
            </Button>
            <Col />
          </Space>
        </Row>
      </Form>

      <TabelaAtividadeTIF />
    </>
  );
}

import {
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { masks } from "../../../../functions/mascaras";
import { DECLARACOES, FITOSSANITARIA, OFICIAL } from "../../constants";
import { useEspecie } from "../../../../hooks/useEspecie";
import { useDadosTermoColeta } from "../../../../hooks/useDadosTermoColeta";
import { apiService } from "../../../../services/api";
import { useQueriesTermoColeta } from "../../hooks/useQueriesTermoColeta";
import { ICultivares } from "../../../../interfaces/ICultivares";
import { ITermoColetaAmostras } from "../../../../interfaces/ITermoColetaAmostras";

const TIPOS_AMOSTRAS = [OFICIAL, FITOSSANITARIA];

const DESTINOS = ["LASGAL", "EXTERNO", "AMBOS", "LSV"];

interface IDadosGeraisProps {
  formColeta: FormInstance;
  formDadosAmostra: FormInstance;
  inputRefTipoAmostra?: React.RefObject<any>;
  setIsOpenViewAmostras: React.Dispatch<React.SetStateAction<boolean>>;
  setCultivares: React.Dispatch<React.SetStateAction<ICultivares[]>>;
  termoEdit?: ITermoColetaAmostras;
}

export function DadosGeraisTCA({
  formColeta,
  formDadosAmostra,
  inputRefTipoAmostra,
  setIsOpenViewAmostras,
  setCultivares,
  termoEdit,
}: IDadosGeraisProps) {
  const { listaEspecies } = useEspecie();
  const { listaAmostras, removerTodasAmostras } = useDadosTermoColeta();
  const { especies } = useQueriesTermoColeta();
  const { semDigitoMask, renasemMask, tifMask } = masks;

  const [destinoSelected, setDestinoSelected] = useState("");
  const [labelTipoAmostra, setLabelTipoAmostra] = useState(
    "Dados de aplicação de Agrotoxicos"
  );

  const handleChangeDestino = (value: string) => {
    //RESETANDO O CAMPO DO TIPO DE AMOSTRA
    handleResetDadosAmostra();
    formColeta.setFieldValue("tipoAmostra", "");

    if (value === "AMBOS" || value === "LASGAL") {
      formColeta.setFieldValue("tipoAmostra", OFICIAL);
      setLabelTipoAmostra("Dados do Tratamento das Sementes");
      setIsOpenViewAmostras(true);
    } else if (value === "LSV") {
      formColeta.setFieldValue("tipoAmostra", FITOSSANITARIA);
      setLabelTipoAmostra(
        "Dados de aplicação de Agrotoxicos (Produtos e número de aplicações)"
      );
      setIsOpenViewAmostras(true);
    }

    setDestinoSelected(value);
  };

  const handleChangeTipoAmostra = (value: string) => {
    handleResetDadosAmostra();

    if (value === FITOSSANITARIA) {
      setLabelTipoAmostra(
        "Dados de aplicação de Agrotoxicos (Produtos e número de aplicações)"
      );
      setIsOpenViewAmostras(true);
    } else if (value === OFICIAL) {
      setLabelTipoAmostra("Dados do Tratamento das Sementes");
      setIsOpenViewAmostras(true);
    }
  };

  const handleResetDadosAmostra = () => {
    formDadosAmostra.resetFields();
    setIsOpenViewAmostras(false);
    removerTodasAmostras();
  };

  const handleChangeEspecie = async (id: number | undefined) => {
    if (!id) {
      setCultivares([]);
      return;
    }
    const especieSelected = especies.data?.filter(
      (especie) => especie.id === id
    );

    if (especieSelected?.length === 0 || !especieSelected) {
      await apiService.especie().then((data) => {
        const aux = data.filter((especie) => especie.id === id);
        setCultivares(aux[0].cultivares);
      });

      return;
    }

    setCultivares(especieSelected[0].cultivares);
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (e.key === "Enter") {
      document.getElementById(id)?.focus();
    }
  };

  useEffect(() => {
    if (termoEdit) {
      handleChangeEspecie(termoEdit.especie?.id);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Typography.Title level={3}>Dados Gerais</Typography.Title>

      <Form.Item
        name="destino"
        label="Destino"
        rules={[{ required: true, message: "Por favor,selecione um destino" }]}
      >
        <Select
          allowClear
          onChange={handleChangeDestino}
          value={destinoSelected}
          disabled={listaAmostras.length > 0}
          style={{ minWidth: "190px" }}
          placeholder="Selecione um Destino"
        >
          {DESTINOS.map((destino) => (
            <Select.Option key={destino} value={destino}>
              {destino}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {destinoSelected === "EXTERNO" && (
        <Form.Item name="nomeLaboratorio" label="Nome do Laboratório">
          <Input
            style={{ minWidth: "190px" }}
            onKeyDown={(e) => handleKeyPress(e, "numeroTIF")}
          />
        </Form.Item>
      )}

      <Form.Item name="numeroTIF" label="N° do Termo de Fiscalização">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "dataColeta")}
          maxLength={15}
          style={{ minWidth: "190px" }}
          onChange={(e) => {
            formColeta.setFieldValue("numeroTIF", tifMask(e.target.value));
          }}
        />
      </Form.Item>

      <Form.Item
        name="dataColeta"
        label="Data da Coleta"
        rules={[
          { required: true, message: "Por favor,selecione uma data da coleta" },
        ]}
      >
        <DatePicker
          format={"DD/MM/YYYY"}
          disabledDate={(date) => {
            if (date.valueOf() > Date.now()) {
              return true;
            }
            return false;
          }}
        />
      </Form.Item>

      <Form.Item name="especieId" label="Especie">
        <Select
          style={{ minWidth: "190px" }}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          placeholder="Selecione uma espécie"
          onChange={handleChangeEspecie}
        >
          {listaEspecies.length > 0 ? (
            <Fragment>
              {listaEspecies.map((especie) => (
                <Select.Option key={especie.id} value={especie.id}>
                  {especie.nomeComum}
                </Select.Option>
              ))}
            </Fragment>
          ) : (
            <Fragment>
              {especies.data?.map((especie) => (
                <Select.Option key={especie.id} value={especie.id}>
                  {especie.nomeComum}
                </Select.Option>
              ))}
            </Fragment>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        name="safra"
        label="Safra"
        rules={[{ required: true, message: "Por favor,digite uma safra" }]}
      >
        <Input onKeyDown={(e) => handleKeyPress(e, "renasemOrigem")} />
      </Form.Item>
      <Form.Item
        name="renasemOrigem"
        label="Renasem Origem Sementes"
        rules={[
          {
            required:
              formColeta.getFieldValue("tipoAmostra") !== FITOSSANITARIA,
          },
        ]}
      >
        <Input
          onKeyDown={(e) => handleKeyPress(e, "paisOrigem")}
          disabled={formColeta.getFieldValue("tipoAmostra") === FITOSSANITARIA}
          maxLength={13}
          onChange={(e) => {
            formColeta.setFieldValue(
              "renasemOrigem",
              renasemMask(e.target.value).toUpperCase()
            );
          }}
        />
      </Form.Item>
      <Form.Item name="paisOrigem" label="Pais de origem">
        <Input
          onKeyDown={(e) => handleKeyPress(e, "tipoAmostra")}
          onChange={(e) => {
            formColeta.setFieldValue(
              "paisOrigem",
              semDigitoMask(e.target.value)
            );
          }}
        />
      </Form.Item>
      <Form.Item
        name="tipoAmostra"
        label="Tipo de amostras"
        rules={[
          { required: true, message: "Por favor,selecione um tipo de amostra" },
        ]}
      >
        <Select
          ref={inputRefTipoAmostra}
          disabled={destinoSelected !== "EXTERNO" || listaAmostras.length > 0}
          allowClear
          style={{ minWidth: "190px" }}
          onChange={handleChangeTipoAmostra}
        >
          {TIPOS_AMOSTRAS.map((tipo) => (
            <Select.Option key={tipo} value={tipo}>
              {tipo}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {formColeta.getFieldValue("tipoAmostra") !== FITOSSANITARIA && (
        <Form.Item name="declaracoes" label="Declarações">
          <Checkbox.Group options={DECLARACOES} />
        </Form.Item>
      )}
      <Form.Item
        name="dadosAgrotoxicos"
        label={labelTipoAmostra}
        rules={[
          { required: formColeta.getFieldValue("tipoAmostra") === "OFICIAL" },
        ]}
      >
        <Input.TextArea rows={4} showCount maxLength={1500} />
      </Form.Item>
      <Form.Item name="observacoes" label="Observações">
        <Input.TextArea rows={4} showCount maxLength={1500} />
      </Form.Item>
    </Fragment>
  );
}

import moment from "moment";
import { IAmostraDadosLasgal } from "../../../../../../interfaces/IAmostraDadosLasgal";
import { IBoletimAmostra } from "../../../../../../interfaces/IBoletimAmostra";
import styles from "./style.module.css";

export function BodyBoletimLasgal({
  boletim,
  amostra,
}: {
  boletim: IBoletimAmostra;
  amostra: IAmostraDadosLasgal | undefined;
}) {
  return (
    <>
      <tbody>
        <tr>
          <td
            style={{
              border: "1px solid black",
            }}
          >
            <div className={styles["title-topico"]}>
              IDENTIFICAÇÃO DO REQUERENTE
            </div>

            <div className={styles.campo}>Requerente:</div>

            <div className={styles.campo}>Endereço:</div>

            <div className={styles["title-topico"]}>
              IDENTIFICAÇÃO DA AMOSTRA
            </div>

            <div className={styles["container-line-more-items"]}>
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Especie: {amostra?.especie}
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}>
                {" "}
                Cultivar: {amostra?.cultivar?.nome}
              </div>
            </div>

            <div className={styles["container-line-more-items"]}>
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                N° Lote: {amostra?.lote}
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}>
                Representatividade:
              </div>
            </div>

            <div className={styles["container-line-more-items"]}>
              <div
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Safra:
              </div>
              <div
                style={{
                  width: "30%",
                  paddingLeft: 5,
                  borderRight: "1px solid black",
                }}
              >
                Categoria: {amostra?.categoria?.nome}
              </div>

              <div style={{ width: "40%", paddingLeft: 5 }}> Procedência:</div>
            </div>

            <div className={styles["container-line-more-items"]}>
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Termo de coleta de amostra:{" "}
                {boletim?.amostra?.termoColetaAmostra}
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}>
                Autorização de importação:
              </div>
            </div>

            <div className={styles["container-line-more-items"]}>
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Nome do Amostrador:
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}> Matricula:</div>
            </div>

            <div
              style={{
                display: "flex",
                minHeight: "2vh",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Data da Amostragem:
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}> Peneira:</div>
            </div>

            <div className={styles["title-topico"]}>
              IDENTIFICAÇÃO DA AMOSTRA NO LABORATÓRIO
            </div>

            <div className={styles["container-line-more-items"]}>
              <div
                style={{
                  width: "50%",
                  paddingLeft: 5,
                  borderRight: "1px solid black",
                }}
              >
                N° da Amostra: {amostra?.numeroAmostraLasgal}
              </div>

              <div style={{ width: "50%", paddingLeft: 5 }}>
                Data do Recebimento:{" "}
                {moment(amostra?.dataRecebimentoAmostraLasgal).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>

            <div className={styles["title-topico"]}>RESULTADO DA ANÁLISE</div>

            <div
              style={{
                display: "flex",
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid black",
                    height: "5vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  PUREZA em 500g
                </div>
                <div
                  style={{
                    borderBottom: "1px solid black",
                  }}
                >
                  (%)
                </div>

                <div
                  style={{ borderBottom: "1px solid black", display: "flex" }}
                >
                  <div style={{ borderRight: "1px solid black", width: "33%" }}>
                    Sementes Puras
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "33%" }}>
                    Material Inerte
                  </div>

                  <div style={{ width: "33%" }}>Outras Sementes</div>
                </div>

                <div
                  style={{ borderBottom: "1px solid black", display: "flex" }}
                >
                  <div style={{ borderRight: "1px solid black", width: "33%" }}>
                    1
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "33%" }}>
                    2
                  </div>

                  <div style={{ width: "33%" }}>3</div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ borderRight: "1px solid black", width: "33%" }}>
                    {boletim?.sementePura}
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "33%" }}>
                    {boletim?.materialInertePureza}
                  </div>

                  <div style={{ width: "33%" }}>
                    {boletim?.outrasSementesPureza}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                <div
                  style={{ borderBottom: "1px solid black", height: "11vh" }}
                >
                  VERIFICAÇÃO DE OUTRAS CULTIVARES (N°) em -O- g
                </div>
                <div style={{ borderBottom: "1px solid black" }}>4</div>
                <div>{boletim?.outrasCultivares}</div>
              </div>

              <div
                style={{
                  width: "50%",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid black",
                    height: "5vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  GERMINAÇÃO
                </div>

                <div
                  style={{
                    borderBottom: "1px solid black",
                  }}
                >
                  (%)
                </div>

                <div
                  style={{ borderBottom: "1px solid black", display: "flex" }}
                >
                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    Plântulas Normais
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    Plântulas Anormais
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    Sementes Duras
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    Sementes Dormentes
                  </div>

                  <div style={{ width: "20%" }}>Sementes Mortas</div>
                </div>

                <div
                  style={{ borderBottom: "1px solid black", display: "flex" }}
                >
                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    5
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    6
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    7
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    8
                  </div>

                  <div style={{ width: "20%" }}>9</div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    {boletim?.normais}
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    {boletim?.anormais}
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    {boletim?.duras}
                  </div>

                  <div style={{ borderRight: "1px solid black", width: "20%" }}>
                    {boletim?.dormentes}
                  </div>

                  <div style={{ width: "20%" }}>{boletim?.mortas}</div>
                </div>
              </div>
            </div>

            <div
              style={{
                minHeight: "2vh",
                borderBottom: "1px solid black",
                paddingLeft: 5,
              }}
            >
              {boletim?.materiaisInertes
                ? boletim.materiaisInertes
                    .map((material: any) => material.nome)
                    .join(", ")
                : ""}
            </div>

            <div
              style={{
                minHeight: "2vh",
                borderBottom: "1px solid black",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderRight: "1px solid black",
                  width: "40%",
                  paddingLeft: 5,
                }}
              >
                Substrato: {boletim?.substrato}
              </div>
              <div
                style={{
                  borderRight: "1px solid black",
                  width: "20%",
                  paddingLeft: 5,
                }}
              >
                Temperatura(°C): {boletim?.temperatura}
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}>
                Tratamento Especial: {boletim?.tratamentoEspecial}
              </div>
            </div>

            <div
              style={{
                minHeight: "2vh",
                borderBottom: "1px solid black",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderRight: "1px solid black",
                  width: "60%",
                  paddingLeft: 5,
                }}
              >
                Data de Conclusão do Teste de Germinação:{" "}
                {moment(boletim?.dataFinalTesteGerminacao).format("DD/MM/YYYY")}
              </div>
              <div
                style={{
                  width: "40%",
                  paddingLeft: 5,
                }}
              >
                Duração do Teste de Germinação:{" "}
                {`${moment(boletim?.dataFinalTesteGerminacao).diff(
                  boletim?.dataInicialTesteGerminacao,
                  "days"
                )} Dia(s)`}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* LINHA 1 */}
                <div style={{ borderBottom: "1px solid black" }}>
                  OUTRAS SEMENTES POR NÚMERO
                </div>

                {/* LINHA 2 */}
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    Outras Espécies Cultivadas
                  </div>
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    Sementes Silvestres
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ borderBottom: "1px solid black" }}>
                      Sementes Nocivas
                    </div>

                    <div
                      style={{
                        display: "flex",
                        height: "65%",
                      }}
                    >
                      <div
                        style={{ width: "50%", borderRight: "1px solid black" }}
                      >
                        Toleradas
                      </div>

                      <div style={{ width: "50%" }}>Proibidas</div>
                    </div>
                  </div>
                </div>

                {/* LINHA 3 */}
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div style={{ width: "50%", borderRight: "1px solid black" }}>
                    (N) em 500g
                  </div>
                  <div style={{ width: "50%" }}> (N) em 500g</div>
                </div>

                {/* LINHA 4 */}
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    10
                  </div>
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {" "}
                    11
                  </div>
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    12
                  </div>
                  <div style={{ width: "25%" }}>13</div>
                </div>

                {/* LINHA 5 */}
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {boletim?.outrasCultivadas}
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {boletim?.silvestres}
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {boletim?.toleradas}
                  </div>

                  <div style={{ width: "25%" }}>{boletim?.proibidas}</div>
                </div>
              </div>
              <div style={{ width: "50%", textAlign: "center" }}>
                {/* LINHA 1 */}
                <div style={{ borderBottom: "1px solid black" }}>
                  OUTRAS DETERMINAÇÕES
                </div>

                {/* LINHA 2 */}
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid black",
                    height: 90,
                  }}
                >
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    TZ%
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    PMS
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    Vigna Unguiculata Em 1001g
                  </div>

                  <div style={{ width: "25%" }}>-O-</div>
                </div>

                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    14
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    15
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    16
                  </div>

                  <div style={{ width: "25%" }}>17</div>
                </div>

                {/* LINHA 3 */}
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {boletim?.tetrazolio}
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {boletim?.pms}
                  </div>

                  <div style={{ width: "25%", borderRight: "1px solid black" }}>
                    {boletim?.vigna}
                  </div>

                  <div style={{ width: "25%" }}>{boletim?.vazio}</div>
                </div>
              </div>
            </div>

            <div
              style={{
                minHeight: "5vh",
                borderBottom: "1px solid black",
                paddingLeft: 5,
              }}
            >
              OUTRAS SEMENTES POR NÚMERO: (identificação e número de sementes)
            </div>

            <div
              style={{
                minHeight: "5vh",
                paddingLeft: 5,
              }}
            >
              OBSERVAÇÕES:
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}

import { createAction } from "@reduxjs/toolkit";
import { IMunicipio } from "../../interfaces/IMunicipio";

const addMunicipios = createAction(
  "MUNICIPIO/addMunicipios",
  (municipios: IMunicipio[]) => ({
    payload: { municipios },
  })
);

export const municipiosAction = { addMunicipios };

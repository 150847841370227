import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IMenuOptions } from "../../interfaces/IMenuOptions";

interface IMenuSlice {
  selectedKey: string[];
  menuOptions: IMenuOptions[];
  selectedKeyAnaliseLasgal: string[];
}

const initialState: IMenuSlice = {
  selectedKey: ["1"],
  menuOptions: [],
  selectedKeyAnaliseLasgal: ["PUREZA"],
};

const menuSlice = createSlice({
  name: "MENU",
  initialState,
  reducers: {
    updateKey: (state, action) => {
      const value = action.payload;
      state.selectedKey = value;
    },
    addMenu: (state, action) => {
      const { menu }: { menu: IMenuOptions[] } = action.payload;
      const menuOrganizado: IMenuOptions[] = [];

      //ORGANIZAR ORDEM DOS ITENS NO MENU
      menu.forEach((item) => {
        if (item.codigo === "inicio") {
          menuOrganizado[0] = item;
        }
        if (item.codigo === "TIF") {
          menuOrganizado[1] = item;
        }

        if (item.codigo === "TermoColeta") {
          menuOrganizado[2] = item;
        }

        if (item.codigo === "TermoApreensaoLiberacao") {
          menuOrganizado[3] = item;
        }

        if (item.codigo === "TermoVistoria") {
          menuOrganizado[4] = item;
        }

        if (item.codigo === "TID") {
          menuOrganizado[5] = item;
        }

        if (item.codigo === "LASGAL") {
          menuOrganizado[6] = item;
        }

        if (item.codigo === "offlineMode") {
          menuOrganizado[7] = item;
        }

        if (item.codigo === "Perfil") {
          menuOrganizado[8] = item;
        }

        if (item.codigo === "config") {
          menuOrganizado[9] = item;
        }
      });

      state.menuOptions = menuOrganizado;
    },

    updateKeyAnaliseLasgal: (state, action) => {
      const { key } = action.payload;
      state.selectedKeyAnaliseLasgal = key;
    },
  },
});

export default menuSlice.reducer;

export const getMenuSelectedKey = (state: RootState) =>
  state.menuSisdev.selectedKey;

export const getMenuOptions = (state: RootState) =>
  state.menuSisdev.menuOptions;

export const getMenuSelectAnaliseLasgal = (state: RootState) =>
  state.menuSisdev.selectedKeyAnaliseLasgal;

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { openNotification } from "../../../../components/Notifications";
import { UseMutateFunction } from "react-query";
import { ButtonAplicarFiltro } from "../../../../components/ButtonAplicarFiltro";

const LASGAL_OPTIONS_FILTER = [
  { value: "municipioColeta", label: "Município" },
  { value: "numeroAmostraLasgal", label: "Nº amostra LASGAL" },
  { value: "numeroTermoColeta", label: "Nº do Termo de coleta" },
];

const NUM_AMOSTRA = "numeroAmostraLasgal";
const MUNICIPIO_COLETA = "municipioColeta";
const TERMO_COLETA = "numeroTermoColeta";

export function FiltroLASGAL({
  form,
  mutate,
  variables,
  setPageControl,
}: {
  form: FormInstance;
  mutate: UseMutateFunction<any, unknown, string, unknown>;
  variables: string | undefined;
  setPageControl: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtroSelect, setFiltroSelect] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const showDrawer = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
    // setFilterSelect("");
    setFiltroSelect([]);
  };

  const handleChangeFiltro = (values: any[]) => {
    setFiltroSelect(values);
  };

  const handleSubmitFilter = (values: any) => {
    if (!variables) return;
    const params = new URLSearchParams();

    setPageControl(1);

    params.append("size", "20");
    params.append("destinoAmostra", "0");
    params.append("page", "0");

    for (const filtro in values) {
      params.append(filtro, values[filtro].toUpperCase().trim());
    }
    setIsLoading(true);
    mutate(params.toString());
    setIsLoading(false);
    onClose();
    return;
  };

  useEffect(() => {
    const params = [];

    if (!variables) return;
    if (isModalOpen) {
      if (variables.includes(MUNICIPIO_COLETA)) {
        params.push(MUNICIPIO_COLETA);
      } else {
        form.setFieldValue(MUNICIPIO_COLETA, "");
      }

      if (variables.includes(NUM_AMOSTRA)) {
        params.push(NUM_AMOSTRA);
      } else {
        form.setFieldValue(NUM_AMOSTRA, "");
      }

      if (variables.includes(TERMO_COLETA)) {
        params.push(TERMO_COLETA);
      } else {
        form.setFieldValue(TERMO_COLETA, "");
      }

      setFiltroSelect(params);
    }

    //eslint-disable-next-line
  }, [isModalOpen]);

  return (
    <div>
      <Tooltip title="Filtro">
        <Button
          onClick={showDrawer}
          disabled={!navigator.onLine}
          style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
        >
          <FilterOutlined />
        </Button>
      </Tooltip>
      <Form
        id="form-filtro"
        layout="vertical"
        labelWrap
        form={form}
        onFinish={handleSubmitFilter}
        onFinishFailed={(e) => {
          openNotification({
            type: "error",
            message: "Campos em branco",
            description: "Termine de preencher todos os campos obrigatórios",
          });
        }}
      >
        <Drawer
          title="Filtro de amostras LASGAL"
          placement="right"
          closable={false}
          onClose={onClose}
          open={isModalOpen}
          width={350}
          key={"right"}
          extra={
            <Space>
              <Button onClick={onClose}>Fechar</Button>
            </Space>
          }
        >
          <Checkbox.Group onChange={handleChangeFiltro} value={filtroSelect}>
            <Space direction="vertical">
              {LASGAL_OPTIONS_FILTER.map((filtro) => {
                return (
                  <Checkbox key={filtro.value} value={filtro.value}>
                    {filtro.label}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>

          <Divider />

          {filtroSelect.includes(MUNICIPIO_COLETA) && (
            <Form.Item
              label="Digite o nome do município"
              name={MUNICIPIO_COLETA}
              rules={[
                {
                  required: filtroSelect.includes(MUNICIPIO_COLETA),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {filtroSelect.includes(NUM_AMOSTRA) && (
            <Form.Item
              label="Digite o Nº da amostra LASGAL"
              name={NUM_AMOSTRA}
              rules={[
                {
                  required: filtroSelect.includes(NUM_AMOSTRA),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input
                maxLength={9}
                onChange={(e) => {
                  let { value } = e.target;
                  value = value.replace(/\D/g, "");
                  value = value.replace(/(\d{4})(\d)/, "$1/$2");
                  form.setFieldValue(NUM_AMOSTRA, value);
                }}
              />
            </Form.Item>
          )}

          {filtroSelect.includes(TERMO_COLETA) && (
            <Form.Item
              label="Digite o Nº do Termo de coleta"
              name={TERMO_COLETA}
              rules={[
                {
                  required: filtroSelect.includes(TERMO_COLETA),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          <Row justify="center">
            <Space direction="vertical" size={10}>
              <ButtonAplicarFiltro
                isDisabled={filtroSelect.length === 0}
                isLoading={isLoading}
              />
              <Col />
            </Space>
          </Row>
        </Drawer>
      </Form>
    </div>
  );
}

import { createAction } from "@reduxjs/toolkit";
import { ITif } from "../../interfaces/ITif";

const addTermo = createAction("TIF/addTermo", (tif: ITif) => ({
  payload: { tif },
}));

const updateAtividades = createAction(
  "TIF/updateAtividades",
  (dadosEdit, tifId: number, tif: ITif) => ({
    payload: { dadosEdit, tifId },
    meta: {
      offline: {
        effect: {
          //CHAMADA DA API
          url: `/vegetal/tifs/${tifId}`,
          method: "PATCH",
          data: dadosEdit,
        },
        // action to dispatch when effect succeeds:
        // commit: {
        //   type: "TIF/updateAtividadesCommit",
        //   meta: { dadosEdit, tifId },
        // },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "TIF/updateAtividadesRollback",
          meta: { dadosEdit, tifId, tif },
        },
      },
    },
  })
);

const addItemByFilter = createAction("TIF/addItemByFilter", (tif: ITif) => ({
  payload: { tif },
}));

const removeItem = createAction("TIF/removeItem", (numTermo: string) => ({
  payload: { numTermo },
}));

//PERMISSAO PARA ADMINISTRADOR
const removeId = createAction("TIF/removeId", (id: number) => ({
  payload: { id },
}));

const updateItem = createAction("TIF/updateItem", (tif: ITif) => ({
  payload: { tif },
}));

const addTimePersistedAtItems = createAction(
  "TIF/addTimePersistedAtItems",
  (createdAt: number) => ({
    payload: { createdAt },
  })
);

const removeTimePersistedAtItems = createAction(
  "TIF/removeTimePersistedAtItems",
  () => ({
    payload: {},
  })
);

export const tifAction = {
  addTermo,
  removeItem,
  addItemByFilter,
  updateAtividades,
  removeId,
  updateItem,
  addTimePersistedAtItems,
  removeTimePersistedAtItems,
};

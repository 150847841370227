import QRCode from "react-qr-code";

export function FooterBoletimLasgal({ assinatura }: { assinatura: string }) {
  return (
    <>
      <tfoot>
        <tr>
          <td
            style={{
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 10,
                padding: 5,
              }}
            >
              <div
                style={{
                  paddingLeft: 5,
                }}
              >
                CUIABA, 28 de Setembro 2022
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 20,
                  alignItems: "center",
                }}
              >
                <QRCode
                  value="https://github.com/matheus-feijo"
                  size={80}
                  style={{
                    paddingLeft: 20,
                    width: "40%",
                  }}
                />

                <p
                  style={{
                    fontSize: 14,
                    textAlign: "justify",
                    marginBottom: 0,
                  }}
                >
                  Assinado eletronicamente por senha pelo Fiscal de Defesa
                  Agropecuária e Florestal{" "}
                  <strong>SILVANIA FERREIRA DE ALMEIDA</strong>. Engenheiro
                  Agronomo(a) sob CREA MT004846 na data{" "}
                  <strong>19/02/2024 11:08:58</strong> Esse documento pode ser
                  validado atrav6s do sitio
                  https://sistemas.lndea.mt.gov.br/SISDEV na aba Consulta.
                  Informe o cbdigo de segurang 2e14cc
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}

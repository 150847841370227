import { Button, Checkbox, FormInstance, Input, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { masks } from "../../../../functions/mascaras";
import { IAssinante } from "../../../../interfaces/IAssinante";
import { AssinaturaContainer } from "../../../../styles/AssinaturaContainer";
import { ModalAssinatura } from "../../../../components/ModalAssinatura";

interface IAssinaturasTermoProps {
  assinaturaFiscalizado: IAssinante;
  assinaturaAmostrador: string;
  setAssinaturaFiscalizado: React.Dispatch<React.SetStateAction<IAssinante>>;
  setAssinaturaAmostrador: React.Dispatch<React.SetStateAction<string>>;
  setStatusAssinatura: React.Dispatch<
    React.SetStateAction<CheckboxValueType[]>
  >;
  statusAssinatura: CheckboxValueType[];
  tipoColeta: string | undefined;
  formRegistro: FormInstance;
  refAmostrador: React.MutableRefObject<any>;
}

export function AssinaturasTermo({
  assinaturaAmostrador,
  assinaturaFiscalizado,
  setAssinaturaAmostrador,
  setAssinaturaFiscalizado,
  statusAssinatura,
  setStatusAssinatura,
  formRegistro,
  refAmostrador,
}: IAssinaturasTermoProps) {
  const refAssinante = useRef<any>(null);
  const { cpfCnpjMask } = masks;

  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [isOpenModalAmostrador, setIsOpenModalAmostrador] = useState(false);

  const handleCloseModalFiscalizado = async () => {
    setIsOpenModalFiscalizado(false);
    setAssinaturaFiscalizado({
      ...assinaturaFiscalizado,
      assinatura: refAssinante.current,
    });
  };

  const handleCloseModalAmostrador = async () => {
    setIsOpenModalAmostrador(false);

    if (refAmostrador.current.isEmpty()) {
      setAssinaturaAmostrador("");
      return;
    }

    setAssinaturaAmostrador(
      refAmostrador.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  const handleOpenModal = (assinante: string) => {
    if (assinante === "FISCALIZADO") {
      setIsOpenModalFiscalizado(true);
    } else {
      setIsOpenModalAmostrador(true);
    }
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeFiscalizadoAusente = (
    checkedValue: CheckboxValueType[]
  ) => {
    setStatusAssinatura(checkedValue);

    if (checkedValue[0] === "RECUSADO") {
      cleanSignatureFields(refAssinante.current);
    }

    if (checkedValue[0] === "AUSENTE") {
      cleanSignatureFields(refAssinante.current);
      setAssinaturaFiscalizado({
        assinatura: null,
        nomeAssinante: "",
        cpfAssinante: "",
        funcaoAssinante: "",
      });
      return;
    }

    setAssinaturaFiscalizado({
      ...assinaturaFiscalizado,
      nomeAssinante: formRegistro.getFieldValue("nomeOrigem"),
      cpfAssinante: formRegistro.getFieldValue("cpfCnpjOrigem"),
    });

    if (checkedValue[0] === "ASSINADO_FISICAMENTE") {
      cleanSignatureFields(refAssinante.current);
      cleanSignatureFields(refAmostrador.current);

      setAssinaturaAmostrador("");
      setAssinaturaFiscalizado({ ...assinaturaFiscalizado, assinatura: null });

      return;
    }
  };

  return (
    <Fragment>
      <div>
        <div
          style={{
            display: "flex",
            padding: "50px 15px 15px 15px",
          }}
        >
          <Checkbox.Group
            style={{
              display: "flex",
              flexDirection: "row",
              width: 250,
            }}
            onChange={handleChangeFiscalizadoAusente}
            value={statusAssinatura}
          >
            <Checkbox value="ASSINADO_FISICAMENTE">Assinatura Física</Checkbox>
          </Checkbox.Group>
        </div>
        <AssinaturaContainer>
          <div className="servidor-signature-form">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="container-img-assinatura">
                {assinaturaAmostrador && (
                  <img
                    src={assinaturaAmostrador}
                    alt={`assinatura Amostrador`}
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Amostrador
              </Typography>
              <Button
                disabled={statusAssinatura[0] === "ASSINADO_FISICAMENTE"}
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  handleOpenModal("AMOSTRADOR");
                }}
              >
                Clique aqui para assinar
              </Button>
            </div>
          </div>

          <div className="fiscalizado-signature-form" style={{ width: 600 }}>
            <div>
              <div className="container-img-assinatura">
                {assinaturaFiscalizado.assinatura && (
                  <img
                    src={assinaturaFiscalizado.assinatura
                      .getTrimmedCanvas()
                      .toDataURL("image/png")}
                    alt={`assinatura Fiscalizado`}
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Fiscalizado
              </Typography>
              <Button
                disabled={statusAssinatura.length > 0}
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  handleOpenModal("FISCALIZADO");
                }}
              >
                Clique aqui para assinar
              </Button>

              <div style={{ display: "flex", paddingTop: 10 }}>
                <Checkbox.Group
                  onChange={handleChangeFiscalizadoAusente}
                  value={statusAssinatura}
                >
                  <Checkbox value="AUSENTE">Ausente</Checkbox>
                </Checkbox.Group>

                <Checkbox.Group
                  onChange={handleChangeFiscalizadoAusente}
                  value={statusAssinatura}
                >
                  <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                </Checkbox.Group>
              </div>

              <div
                className="dados-assinante-tif"
                style={{
                  display: "flex",
                  gap: 10,
                  maxWidth: 600,
                }}
              >
                <div>
                  <span>Nome:</span>
                  <Input
                    disabled={
                      statusAssinatura.length > 0 &&
                      !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                        statusAssinatura[0].toString()
                      )
                    }
                    name="nome_assinante"
                    value={assinaturaFiscalizado?.nomeAssinante}
                    onChange={(e) =>
                      setAssinaturaFiscalizado({
                        ...assinaturaFiscalizado,
                        nomeAssinante: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <span>CPF:</span>
                  <Input
                    disabled={
                      statusAssinatura.length > 0 &&
                      !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                        statusAssinatura[0].toString()
                      )
                    }
                    name="cpf_assinante"
                    value={assinaturaFiscalizado?.cpfAssinante}
                    onChange={(e) => {
                      setAssinaturaFiscalizado({
                        ...assinaturaFiscalizado,
                        cpfAssinante: cpfCnpjMask(e.target.value),
                      });
                    }}
                  />
                </div>

                <div>
                  <span>Função:</span>
                  <Input
                    disabled={
                      statusAssinatura.length > 0 &&
                      !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                        statusAssinatura[0].toString()
                      )
                    }
                    name="funcao_assinante"
                    value={assinaturaFiscalizado?.funcaoAssinante}
                    onChange={(e) =>
                      setAssinaturaFiscalizado({
                        ...assinaturaFiscalizado,
                        funcaoAssinante: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </AssinaturaContainer>
      </div>

      {/**ASSINATURA FISCALIZADO */}
      <ModalAssinatura
        isOpen={isOpenModalFiscalizado}
        cleanSignature={cleanSignatureFields}
        refAssinante={refAssinante}
        handleClose={handleCloseModalFiscalizado}
        nomeAssinante="Fiscalizado"
      />

      {/**ASSINATURA AMOSTRADOR */}
      <ModalAssinatura
        isOpen={isOpenModalAmostrador}
        cleanSignature={cleanSignatureFields}
        refAssinante={refAmostrador}
        handleClose={handleCloseModalAmostrador}
        nomeAssinante="Amostrador"
      />
    </Fragment>
  );
}

import { Form, Input, Modal, Radio, Typography } from "antd";
import { useQuery } from "react-query";
import { apiService } from "../../../../services/api";
import { Fragment } from "react";
import { openNotification } from "../../../../components/Notifications";

interface IModalRecusaLasgalProps {
  isOpen: boolean;
  handleUpdateList: () => void;
  handleBack: () => void;
  amostraId: number | undefined;
}

export function ModalRecusaLASGAL({
  isOpen,
  handleUpdateList,
  amostraId,
  handleBack,
}: IModalRecusaLasgalProps) {
  const [form] = Form.useForm();

  const { data, status } = useQuery({
    queryKey: ["MOTIVOS_RECUSA_AMOSTRA_LASGAL"],
    queryFn: () => apiService.amostraSemente.motivosRecusaAmostraLasgal(),
  });

  const handleSubmit = async (values: {
    motivoRecusa: number;
    descricao: string;
  }) => {
    if (!amostraId || !data) return;

    if (!values.motivoRecusa) {
      openNotification({
        type: "error",
        message: "Não foi possivel recusar amostra",
        description: "Escolha alguma opção de motivo para recusa da amostra.",
      });
      return;
    }

    if (!values.descricao) {
      openNotification({
        type: "error",
        message: "Não foi possivel recusar amostra",
        description: "Descreva o motivo da recusa da amostra.",
      });
      return;
    }

    const formValues = {
      descricaoRecusa: values.descricao,
      motivoRecusaAmostra: data.filter(
        (value) => value.id === values.motivoRecusa
      )[0],
    };

    await apiService.amostraSemente.recusaAmostraLasgal(amostraId, formValues);

    openNotification({
      type: "success",
      message: "Amostra recusada com sucesso!",
    });

    handleBack();
    handleUpdateList();
  };

  return (
    <Modal
      key={"MODAL_RECUSA_LASGAL"}
      title={
        <h1
          style={{
            fontSize: 18,
            textAlign: "center",
            marginBottom: 0,
            padding: "0px 15px",
          }}
        >
          Recusa de amostra
        </h1>
      }
      open={isOpen}
      cancelText="Não"
      cancelButtonProps={{
        danger: true,
        form: "form-recusa-amostra",
        htmlType: "reset",
      }}
      onCancel={() => {
        handleBack();
      }}
      okText="Sim"
      okButtonProps={{
        type: "primary",
        form: "form-recusa-amostra",
        htmlType: "submit",
      }}
    >
      {status === "success" && (
        <Form
          layout="vertical"
          form={form}
          id="form-recusa-amostra"
          onFinish={handleSubmit}
        >
          <div>
            <Typography.Title level={5}>Motivo Recusa:</Typography.Title>{" "}
            <Form.Item noStyle={true} name="motivoRecusa">
              <Radio.Group>
                {data.map((opt, ind) => (
                  <Fragment key={ind}>
                    <Radio value={opt.id}>{opt.nome}</Radio>
                    <br />
                  </Fragment>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>

          <div style={{ marginTop: 25 }}>
            <span className="title-description-cancelamento">
              Descreva detalhes do motivo da recusa.
            </span>
            <Form.Item noStyle={true} name="descricao">
              <Input.TextArea maxLength={100} showCount rows={4} />
            </Form.Item>
          </div>
        </Form>
      )}
    </Modal>
  );
}

import { notification } from "antd";

interface INotifications {
  type: "success" | "info" | "warning" | "error";
  message: string;
  description?: string;
}

export const openNotification = ({
  type,
  message,
  description,
}: INotifications) => {
  notification[type]({
    message,
    description,
    placement: "top",
    duration: 3,
    style: {
      width: 400,
      whiteSpace: "pre-line",
    },
  });
};

import styled from "styled-components";

export const TitleTermo = styled.h2`
  font-size: 1.875rem;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 1.625rem;
  }
`;

import { Alert, ConfigProvider, Layout } from "antd";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { HeaderForm } from "../../components/HeaderForm";
import { SideBar } from "../../components/SideBar";
import { apiService } from "../../services/api";
import { useQuery } from "react-query";
import { Content, Footer } from "antd/lib/layout/layout";
import { menuAction } from "../../store/actions/menuAction";
import { perfilAction } from "../../store/actions/perfilAction";
import { getPayloadUser } from "../../functions/getPayloadUser";
import { userControllerAction } from "../../store/actions/userControllerAction";
import styles from "./style.module.css";
import locale from "antd/lib/locale/pt_BR";
import { CACHE_VERSION } from "../../constants/CACHE_VERSION";
import { usePermission } from "../../hooks/usePermission";
import { useMunicipio } from "../../hooks/useMunicipio";
import { useServidores } from "../../hooks/useServidores";
import { useUle } from "../../hooks/useUle";
import { useAuth } from "react-oidc-context";
import { useAtividadeInterditada } from "../../hooks/useAtividadeInterditada";

export function CustomRoute({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const user = getPayloadUser(localStorage.getItem("access_token"));
  const { isRouteEnabled } = usePermission();
  const [collapsed, setCollapsed] = useState(false);
  const appDispatch = useDispatch();
  const { adicionarListaComTodosMunicipios } = useMunicipio();
  const { adicionarTodosServidores } = useServidores();
  const { adicionarUles } = useUle();
  const { adicionarAtividadesInterditadasSync } = useAtividadeInterditada();
  const { addMenu } = menuAction;
  const { addUserController } = userControllerAction;
  const { addPerfis } = perfilAction;

  useQuery({
    queryKey: ["MUNICIPIOS"],
    queryFn: () => apiService.municipios(),
    onSuccess: (data) => adicionarListaComTodosMunicipios(data),
    refetchOnReconnect: true,
    onError: (erro: any) => {
      if (erro?.response?.status === 401) {
        auth.signinSilent().then((value) => {
          localStorage.setItem("access_token", value?.access_token || "");
          window.location.reload();
        });
      }
    },
  });

  useQuery({
    queryKey: ["ULES"],
    queryFn: () => apiService.ules(),
    onSuccess: (data) => adicionarUles(data),
    refetchOnReconnect: true,
  });

  useQuery({
    queryKey: ["SERVIDORES"],
    queryFn: () => apiService.servidores.todos(),
    onSuccess: (data) => adicionarTodosServidores(data),
    refetchOnReconnect: true,
  });

  useQuery({
    queryKey: ["MENU"],
    queryFn: () => apiService.menu(),
    onSuccess: (data) => appDispatch(addMenu(data)),
    refetchOnReconnect: true,
  });

  useQuery({
    queryKey: ["LISTAGEM_PERFIS"],
    refetchOnReconnect: true,
    enabled: isRouteEnabled("perfis"),
    queryFn: () => apiService.perfis.buscar(),
    onSuccess: (data) => {
      appDispatch(addPerfis(data));
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ["USER_CONTROLLER"],
    refetchOnReconnect: true,
    queryFn: () => apiService.userController(user?.cpf || ""),
    onSuccess: (data) => {
      appDispatch(addUserController(data));
    },
    retry: false,
    onError: (err) => console.log(err),
  });

  useQuery({
    queryKey: ["ATIVIDADES_INTERDITADAS"],
    refetchOnReconnect: true,
    queryFn: () => apiService.atividadesInterdicao.listar(),
    onSuccess: (data) => {
      adicionarAtividadesInterditadasSync(data);
    },
    retry: false,
    onError: (err) => console.log(err),
  });

  return (
    <Fragment>
      <ConfigProvider locale={locale}>
        <Layout hasSider>
          <SideBar collapsed={collapsed} />
          <Layout
            className={styles["site-layout"]}
            style={{
              minHeight: "100vh",
              transition: "margin-left 0.2s",
              marginLeft: collapsed ? 100 : 200,
            }}
          >
            <HeaderForm collapsed={collapsed} setCollapsed={setCollapsed} />
            {!navigator.onLine && (
              <Alert
                message="Sem acesso a internet"
                type="error"
                style={{ textAlign: "center", fontWeight: "bold" }}
              />
            )}
            <Content
              className={`${styles["site-layout-background"]} ${styles.content}`}
            >
              {children}
            </Content>

            <Footer style={{ textAlign: "center" }}> {CACHE_VERSION}</Footer>
          </Layout>
        </Layout>
      </ConfigProvider>
    </Fragment>
  );
}

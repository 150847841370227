import {
  Button,
  Checkbox,
  Divider,
  Input,
  Result,
  Spin,
  Typography,
} from "antd";
import { Fragment, useRef, useState } from "react";
import { ModalAssinatura } from "../../../../components/ModalAssinatura";
import ReactSignatureCanvas from "react-signature-canvas";
import { IAssinante } from "../../../../interfaces/IAssinante";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { AssinaturaContainer } from "../../../../styles/AssinaturaContainer";
import { masks } from "../../../../functions/mascaras";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../../../../services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { ButtonFooterContainer } from "../../../../styles/ButtonFooterContainer";
import { TitleTermo } from "../../../../styles/TitleTermo";
import { openNotification } from "../../../../components/Notifications";
import { getStatusAssinatura } from "../../../../functions/statusAssinatura";
import { useActionsTAL } from "../../hooks/useActionsTAL";

export function EdicaoPosEnvioTermoApreensaoLiberacao() {
  const { termoId } = useParams();
  const navigate = useNavigate();
  const refFiscalizado = useRef(null);
  const refDepositario = useRef(null);
  const { atualizarAssinatura, atualizarTermo, listaTermos } = useActionsTAL();
  const { cpfCnpjMask } = masks;
  const [assinaturaFisica, setAssinaturaFisica] = useState<CheckboxValueType[]>(
    []
  );
  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [isOpenModalDepositario, setIsOpenModalDepositario] = useState(false);
  const [motivoNaoAssinaturaFiscalizado, setMotivoNaoAssinaturaFiscalizado] =
    useState<CheckboxValueType[]>([]);

  const [motivoNaoAssinaturaDepositario, setMotivoNaoAssinaturaDepositario] =
    useState<CheckboxValueType[]>([]);

  const [dadosFiscalizado, setDadosFiscalizado] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [dadosDepositario, setDadosDepositario] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });

  const { data, status } = useQuery({
    queryKey: ["EDITAR_POS_ENVIO_TERMO_APREENSAO_LIBERACAO"],
    queryFn: () =>
      apiService.termoApreensaoLiberacao.byId(parseInt(termoId || "")),
    initialData: listaTermos.find(
      (termo) => termo.id === parseInt(termoId || "")
    ),
  });

  const handleBack = () => {
    navigate("/termo-apreensao-liberacao/listagem");
  };

  const handleSubmit = async () => {
    if (!data) return;
    let dadosAtualizados = {
      depositario: {
        nomeAssinante: data.nomeAssinanteDepositario,
        cpfAssinante: data.cpfAssinanteDepositario,
        funcaoAssinante: data.funcaoAssinanteDepositario,
        statusAssinatura: data.statusAssinaturaDepositario,
        assinatura: data.assinaturaDepositario,
      },
      fiscalizado: {
        nomeAssinante: data.nomeAssinanteFiscalizado,
        cpfAssinante: data.cpfAssinanteFiscalizado,
        funcaoAssinante: data.funcaoAssinanteFiscalizado,
        statusAssinatura: data.statusAssinaturaFiscalizado,
        assinatura: data.assinaturaFiscalizado,
      },
    };

    if (data?.statusAssinaturaFiscalizado === "AUSENTE") {
      if (
        (!dadosFiscalizado.nomeAssinante ||
          !dadosFiscalizado.cpfAssinante ||
          !dadosFiscalizado.funcaoAssinante) &&
        motivoNaoAssinaturaFiscalizado.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao salvar Termo de Apreensão/Liberação.",
          description:
            "Preencha os dados do assinante Fiscalizado para salvar o Termo de Apreensao/Liberação",
        });

        return;
      }

      if (
        dadosFiscalizado?.assinatura?.toData().length === 0 &&
        motivoNaoAssinaturaFiscalizado.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao salvar Termo de Apreensão/Liberação.",
          description: "Preencha as assinaturas pendentes",
        });
        return;
      }

      dadosAtualizados = {
        ...dadosAtualizados,
        fiscalizado: {
          ...dadosAtualizados.fiscalizado,
          nomeAssinante: dadosFiscalizado.nomeAssinante,
          cpfAssinante: dadosFiscalizado.cpfAssinante,
          funcaoAssinante: dadosFiscalizado.funcaoAssinante,
          statusAssinatura:
            assinaturaFisica.length > 0
              ? getStatusAssinatura(assinaturaFisica)
              : getStatusAssinatura(motivoNaoAssinaturaFiscalizado),
          assinatura:
            motivoNaoAssinaturaFiscalizado.length === 0
              ? dadosFiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() ||
                ""
              : "",
        },
      };
    }

    if (data?.statusAssinaturaDepositario === "AUSENTE") {
      if (
        (!dadosDepositario.nomeAssinante ||
          !dadosDepositario.cpfAssinante ||
          !dadosDepositario.funcaoAssinante) &&
        motivoNaoAssinaturaDepositario.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao salvar Termo de Apreensão/Liberação.",
          description:
            "Preencha os dados do assinante Depositario para salvar o Termo de Apreensao/Liberação",
        });

        return;
      }

      if (
        dadosDepositario?.assinatura?.toData().length === 0 &&
        motivoNaoAssinaturaDepositario.length === 0
      ) {
        openNotification({
          type: "error",
          message: "Erro ao salvar Termo de Apreensão/Liberação.",
          description: "Preencha as assinaturas pendentes",
        });
        return;
      }

      dadosAtualizados = {
        ...dadosAtualizados,
        depositario: {
          ...dadosAtualizados.depositario,
          nomeAssinante: dadosDepositario.nomeAssinante,
          cpfAssinante: dadosDepositario.cpfAssinante,
          funcaoAssinante: dadosDepositario.funcaoAssinante,
          statusAssinatura:
            assinaturaFisica.length > 0
              ? getStatusAssinatura(assinaturaFisica)
              : getStatusAssinatura(motivoNaoAssinaturaDepositario),
          assinatura:
            motivoNaoAssinaturaDepositario.length === 0
              ? dadosDepositario?.assinatura?.getTrimmedCanvas().toDataURL() ||
                ""
              : "",
        },
      };
    }

    const objDados = {
      assinaturaDepositario: dadosAtualizados.depositario.assinatura,
      assinaturaFiscalizado: dadosAtualizados.fiscalizado.assinatura,
      cpfAssinanteDepositario: dadosAtualizados.depositario.cpfAssinante,
      cpfAssinanteFiscalizado: dadosAtualizados.fiscalizado.cpfAssinante,
      funcaoAssinanteDepositario: dadosAtualizados.depositario.funcaoAssinante,
      funcaoAssinanteFiscalizado: dadosAtualizados.fiscalizado.funcaoAssinante,
      nomeAssinanteDepositario: dadosAtualizados.depositario.nomeAssinante,
      nomeAssinanteFiscalizado: dadosAtualizados.fiscalizado.nomeAssinante,
      statusAssinaturaDepositario:
        dadosAtualizados.depositario.statusAssinatura,
      statusAssinaturaFiscalizado:
        dadosAtualizados.fiscalizado.statusAssinatura,
    };

    const tipoTermoFormatado =
      data.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação";

    if (navigator.onLine) {
      await apiService.termoApreensaoLiberacao
        .update(objDados, parseInt(termoId || ""))
        .then((res) => {
          // console.log(res);
          atualizarTermo({
            ...res,
            ...objDados,
          });
          openNotification({
            type: "success",
            message: `Termo de ${tipoTermoFormatado} atualizado com sucesso!`,
          });

          handleBack();
        })
        .catch((error: any) => {
          console.log(error);
          openNotification({
            type: "error",
            message: `Não foi possivel atualizar Termo de ${tipoTermoFormatado}`,
            description: error.response.data.detail,
          });
        });
    } else {
      atualizarAssinatura(objDados, parseInt(termoId || ""), data);
      openNotification({
        type: "success",
        message: `Termo de ${tipoTermoFormatado} atualizado com sucesso!`,
      });

      handleBack();
    }
  };

  const handleCloseModalFiscalizado = async () => {
    setIsOpenModalFiscalizado(false);
    setDadosFiscalizado({
      ...dadosFiscalizado,
      assinatura: refFiscalizado.current,
    });
  };

  const handleCloseModalDepositario = async () => {
    setIsOpenModalDepositario(false);
    setDadosDepositario({
      ...dadosDepositario,
      assinatura: refDepositario.current,
    });
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeMotivoNaoAssinaturaFiscalizado = (
    checkedValue: CheckboxValueType[]
  ) => {
    if (checkedValue.length > 0) {
      setAssinaturaFisica([]);
    }

    if (checkedValue[0] === "AUSENTE") {
      setDadosFiscalizado({
        assinatura: null,
        nomeAssinante: "",
        cpfAssinante: "",
        funcaoAssinante: "",
      });
    }

    cleanSignatureFields(refFiscalizado.current);
    setMotivoNaoAssinaturaFiscalizado(checkedValue);
  };

  const handleChangeMotivoNaoAssinaturaDepositario = (
    checkedValue: CheckboxValueType[]
  ) => {
    if (checkedValue.length > 0) {
      setAssinaturaFisica([]);
    }

    if (checkedValue[0] === "AUSENTE") {
      setDadosDepositario({
        assinatura: null,
        nomeAssinante: "",
        cpfAssinante: "",
        funcaoAssinante: "",
      });
    }

    cleanSignatureFields(refDepositario.current);
    setMotivoNaoAssinaturaDepositario(checkedValue);
  };

  const handleOpenModal = (assinante: string) => {
    if (assinante === "FISCALIZADO") {
      setIsOpenModalFiscalizado(true);
      return;
    }

    if (assinante === "DEPOSITARIO") {
      setIsOpenModalDepositario(true);
    }
  };

  if (status === "error") {
    return (
      <Fragment>
        <Result
          status="error"
          title="Erro ao Editar Termo de Apreensão/Liberação"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
          extra={
            <Button
              onClick={() => navigate("/termo-apreensao-liberacao/listagem")}
            >
              Voltar
            </Button>
          }
        />
      </Fragment>
    );
  } else if (status === "idle" || status === "loading") {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <>
        <TitleTermo>
          Editar Termo de {data.tipoTermo.toLowerCase()} {data.numero}
        </TitleTermo>

        <Divider />

        <div
          style={{
            display: "flex",
            padding: "50px 15px 15px 15px",
          }}
        >
          <Checkbox.Group
            style={{
              display: "flex",
              flexDirection: "row",
              width: 250,
            }}
            onChange={(checkValue) => {
              setAssinaturaFisica(checkValue);
              setMotivoNaoAssinaturaDepositario([]);
              setMotivoNaoAssinaturaFiscalizado([]);
            }}
            value={assinaturaFisica}
          >
            <Checkbox value="ASSINADO_FISICAMENTE">Assinatura Física</Checkbox>
          </Checkbox.Group>
        </div>

        <AssinaturaContainer>
          {data.statusAssinaturaFiscalizado === "AUSENTE" && (
            <div className="fiscalizado-signature-form">
              <div>
                <div className="container-img-assinatura">
                  {dadosFiscalizado.assinatura && (
                    <img
                      src={dadosFiscalizado.assinatura
                        .getTrimmedCanvas()
                        .toDataURL("image/png")}
                      alt="assinatura Fiscalizado"
                      style={{
                        width: "90%",
                        height: "90%",
                      }}
                    />
                  )}
                </div>

                <Typography style={{ textAlign: "center" }}>
                  Assinatura Fiscalizado
                </Typography>
                <Button
                  disabled={
                    motivoNaoAssinaturaFiscalizado.length > 0 ||
                    assinaturaFisica.length > 0
                  }
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => {
                    handleOpenModal("FISCALIZADO");
                  }}
                >
                  Clique aqui para assinar
                </Button>

                <div style={{ display: "flex", paddingTop: 10 }}>
                  <Checkbox.Group
                    onChange={(e) =>
                      handleChangeMotivoNaoAssinaturaFiscalizado(e)
                    }
                    value={motivoNaoAssinaturaFiscalizado}
                  >
                    <Checkbox value="AUSENTE">Ausente</Checkbox>
                  </Checkbox.Group>

                  <Checkbox.Group
                    value={motivoNaoAssinaturaFiscalizado}
                    onChange={(e) =>
                      handleChangeMotivoNaoAssinaturaFiscalizado(e)
                    }
                  >
                    <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                  </Checkbox.Group>
                </div>

                <div
                  className="dados-assinante-tif"
                  style={{
                    display: "flex",
                    gap: 10,
                    maxWidth: 600,
                  }}
                >
                  <div>
                    <span>Nome:</span>
                    <Input
                      disabled={
                        motivoNaoAssinaturaFiscalizado.length > 0 &&
                        !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                          motivoNaoAssinaturaFiscalizado[0].toString()
                        )
                      }
                      name="nome_assinante_fiscalizado"
                      value={dadosFiscalizado?.nomeAssinante}
                      onChange={(e) =>
                        setDadosFiscalizado({
                          ...dadosFiscalizado,
                          nomeAssinante: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div>
                    <span>CPF:</span>
                    <Input
                      disabled={
                        motivoNaoAssinaturaFiscalizado.length > 0 &&
                        !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                          motivoNaoAssinaturaFiscalizado[0].toString()
                        )
                      }
                      name="cpf_assinante_fiscalizado"
                      value={dadosFiscalizado?.cpfAssinante}
                      onChange={(e) => {
                        setDadosFiscalizado({
                          ...dadosFiscalizado,
                          cpfAssinante: cpfCnpjMask(e.target.value),
                        });
                      }}
                    />
                  </div>

                  <div>
                    <span>Função:</span>
                    <Input
                      disabled={
                        motivoNaoAssinaturaFiscalizado.length > 0 &&
                        !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                          motivoNaoAssinaturaFiscalizado[0].toString()
                        )
                      }
                      name="funcao_assinante_fiscalizado"
                      value={dadosFiscalizado?.funcaoAssinante}
                      onChange={(e) =>
                        setDadosFiscalizado({
                          ...dadosFiscalizado,
                          funcaoAssinante: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {data.statusAssinaturaDepositario === "AUSENTE" && (
            <div className="fiscalizado-signature-form">
              <div>
                <div className="container-img-assinatura">
                  {dadosDepositario.assinatura && (
                    <img
                      src={dadosDepositario.assinatura
                        .getTrimmedCanvas()
                        .toDataURL("image/png")}
                      alt="assinatura Depositario"
                      style={{
                        width: "90%",
                        height: "90%",
                      }}
                    />
                  )}
                </div>

                <Typography style={{ textAlign: "center" }}>
                  Assinatura Depositário
                </Typography>
                <Button
                  disabled={
                    motivoNaoAssinaturaDepositario.length > 0 ||
                    assinaturaFisica.length > 0
                  }
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => {
                    handleOpenModal("DEPOSITARIO");
                  }}
                >
                  Clique aqui para assinar
                </Button>

                <div style={{ display: "flex", paddingTop: 10 }}>
                  <Checkbox.Group
                    onChange={(e) =>
                      handleChangeMotivoNaoAssinaturaDepositario(e)
                    }
                    value={motivoNaoAssinaturaDepositario}
                  >
                    <Checkbox value="AUSENTE">Ausente</Checkbox>
                  </Checkbox.Group>

                  <Checkbox.Group
                    value={motivoNaoAssinaturaDepositario}
                    onChange={(e) =>
                      handleChangeMotivoNaoAssinaturaDepositario(e)
                    }
                  >
                    <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                  </Checkbox.Group>
                </div>

                <div
                  className="dados-assinante-tif"
                  style={{
                    display: "flex",
                    gap: 10,
                    maxWidth: 600,
                  }}
                >
                  <div>
                    <span>Nome:</span>
                    <Input
                      disabled={
                        motivoNaoAssinaturaDepositario.length > 0 &&
                        !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                          motivoNaoAssinaturaDepositario[0].toString()
                        )
                      }
                      name="nome_assinante_depositario"
                      value={dadosDepositario?.nomeAssinante}
                      onChange={(e) =>
                        setDadosDepositario({
                          ...dadosDepositario,
                          nomeAssinante: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div>
                    <span>CPF:</span>
                    <Input
                      disabled={
                        motivoNaoAssinaturaDepositario.length > 0 &&
                        !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                          motivoNaoAssinaturaDepositario[0].toString()
                        )
                      }
                      name="cpf_assinante_depositario"
                      value={dadosDepositario?.cpfAssinante}
                      onChange={(e) => {
                        setDadosDepositario({
                          ...dadosDepositario,
                          cpfAssinante: cpfCnpjMask(e.target.value),
                        });
                      }}
                    />
                  </div>

                  <div>
                    <span>Função:</span>
                    <Input
                      disabled={
                        motivoNaoAssinaturaDepositario.length > 0 &&
                        !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                          motivoNaoAssinaturaDepositario[0].toString()
                        )
                      }
                      name="funcao_assinante_depositario"
                      value={dadosDepositario?.funcaoAssinante}
                      onChange={(e) =>
                        setDadosDepositario({
                          ...dadosDepositario,
                          funcaoAssinante: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </AssinaturaContainer>

        <ButtonFooterContainer style={{ marginTop: 40 }}>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleSubmit} type="primary">
            Atualizar Termo
          </Button>
        </ButtonFooterContainer>

        <ModalAssinatura
          refAssinante={refFiscalizado}
          isOpen={isOpenModalFiscalizado}
          cleanSignature={cleanSignatureFields}
          handleClose={handleCloseModalFiscalizado}
          nomeAssinante="Fiscalizado"
        />
        <ModalAssinatura
          refAssinante={refDepositario}
          isOpen={isOpenModalDepositario}
          cleanSignature={cleanSignatureFields}
          handleClose={handleCloseModalDepositario}
          nomeAssinante="Depositário"
        />
      </>
    );
  }
}

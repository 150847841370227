import { Button, FormInstance } from "antd";
import { Fragment, useEffect, useState, useCallback, useRef } from "react";
import { openNotification } from "../../../../components/Notifications";
import { ITermoApreensaoLiberacao } from "../../../../interfaces/ITermoApreensaoLiberacao";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { generateId } from "../../../../functions/generateId";
import { IAssinante } from "../../../../interfaces/IAssinante";
import { IAnexos } from "../../../../interfaces/IAnexos";
import { HeaderTermoApreensaoLiberacaoImpressao } from "../../listagemTermoApreensaoLiberacao/VisualizacaoImpressaoTermoApreensaoLiberacao/components/HeaderTermoApreensaoLiberacaoImpressao";
import { BodyTermoApreensaoLiberacaoImpressao } from "../../listagemTermoApreensaoLiberacao/VisualizacaoImpressaoTermoApreensaoLiberacao/components/BodyTermoApreensaoLiberacaoImpressao";
import { FooterTermoApreensaoLiberacaoImpressao } from "../../listagemTermoApreensaoLiberacao/VisualizacaoImpressaoTermoApreensaoLiberacao/components/FooterTermoApreensaoLiberacaoImpressao";
import { ImagesTermoApreensaoLiberacaoImpressao } from "../../listagemTermoApreensaoLiberacao/VisualizacaoImpressaoTermoApreensaoLiberacao/components/ImagesTermoApreensaoLiberacaoImpressao";
import { TIPO_IMAGENS } from "../../../../constants/TIPO_IMAGENS";
import { useMunicipio } from "../../../../hooks/useMunicipio";
import { useServidores } from "../../../../hooks/useServidores";
import { useUle } from "../../../../hooks/useUle";
import { useDadosTermoApreensaoLiberacao } from "../../../../hooks/useDadosTermoApreensaoLiberacao";
import { getAnexosFormatadosBase64 } from "../../../../functions/getAnexosFormatadosBase64";

export function PreviaTermoApreensaoLiberacao({
  formApreensao,
  isTifExisist,
  tifSelected,
  fiscalizadoSignature,
  depositarioSignature,
  anexos,
  termoApreensaoSelected,
}: {
  formApreensao: FormInstance;
  isTifExisist: boolean;
  tifSelected: any;
  fiscalizadoSignature: IAssinante;
  depositarioSignature: IAssinante;
  anexos: any[];
  termoApreensaoSelected: ITermoApreensaoLiberacao | null;
}) {
  const [printedTermo, setPrintedTermo] =
    useState<ITermoApreensaoLiberacao | null>(null);

  const { getMunicipioPorId } = useMunicipio();
  const { listaUles } = useUle();
  const { servidoresSelecionados } = useServidores();
  const { listaItens } = useDadosTermoApreensaoLiberacao();

  const termoApreensaoRef = useRef(null);

  const handleAfterPrint = useCallback(() => {
    setPrintedTermo(null);
    // tslint:disable-line no-console
  }, []);

  const handlePrint = useReactToPrint({
    content: () => termoApreensaoRef.current,
    documentTitle: `Termo de ${printedTermo?.tipoTermo} ${printedTermo?.numero}`,
    suppressErrors: true,
    removeAfterPrint: true,
    onAfterPrint: handleAfterPrint,
    onPrintError: (errorLocation, error) => {
      openNotification({
        type: "error",
        message: `Não foi possivel fazer impressão desse Termo de ${printedTermo?.tipoTermo}`,
      });
    },
  });

  const handlePreview = async () => {
    if (!isTifExisist || !tifSelected) {
      openNotification({
        type: "error",
        message: "Erro ao gerar Prévia do Termo.",
        description:
          "Termine de preencher os campos obrigatorios para gerar prévia do termo.",
      });

      return;
    }

    if (!formApreensao.getFieldValue("depositario")) {
      openNotification({
        type: "error",
        message: "Erro ao gerar Prévia do Termo.",
        description: "Preencha qual o tipo do Depositário.",
      });
      return;
    }

    const [uleSelect] = listaUles.filter(
      (ule) =>
        ule.municipio.id ===
          formApreensao.getFieldValue("municipioFiscalizacaoId") &&
        ule.tipoUnidade === "UL"
    );

    const numTermo = formApreensao.getFieldValue("numTermo")
      ? formApreensao.getFieldValue("numTermo")
      : `${generateId()}/${uleSelect.codigo.slice(
          uleSelect.codigo.length - 3
        )}/${moment().year()}`;

    //PREENCHER DADOS DO TERMO DE APREENSAO/LIBERACAO
    let formValue: ITermoApreensaoLiberacao = {
      produto: "",
      anexos: [],
      amparoLegal: formApreensao.getFieldValue("amparoLegal") || "",
      assinaturaFiscalizado: "",
      assinaturaDepositario: "",
      cepDepositario: formApreensao.getFieldValue("cepDepositario") || "",
      cepFiscalizado: formApreensao.getFieldValue("cepFiscalizado") || "",
      cpfAssinanteFiscalizado: fiscalizadoSignature.cpfAssinante,
      cpfAssinanteDepositario: depositarioSignature.cpfAssinante,
      cpfCnpjDepositario:
        formApreensao
          .getFieldValue("cpfCnpjDepositario")
          ?.replace(/[^\d]+/g, "") || "",
      cpfCnpjFiscalizado:
        formApreensao
          .getFieldValue("cpfCnpjFiscalizado")
          ?.replace(/[^\d]+/g, "") || "",
      dataFiscalizacao:
        moment(formApreensao.getFieldValue("dataTIF"))
          .locale("pt-br")
          .format()
          .slice(0, 10) || "",
      dataCadastro: moment().format(),
      descricao: "",
      emailDepositario: formApreensao.getFieldValue("emailDepositario") || "",
      emailFiscalizado: formApreensao.getFieldValue("emailFiscalizado") || "",
      enderecoDepositario:
        formApreensao.getFieldValue("enderecoDepositario") || "",
      enderecoFiscalizado:
        formApreensao.getFieldValue("enderecoFiscalizado") || "",
      funcaoAssinanteFiscalizado: fiscalizadoSignature.funcaoAssinante,
      funcaoAssinanteDepositario: depositarioSignature.funcaoAssinante,
      inscricaoEstadualDepositario:
        formApreensao.getFieldValue("rgDepositario") || "",
      inscricaoEstadualFiscalizado:
        formApreensao.getFieldValue("rgFiscalizado") || "",
      itens: listaItens,
      localDeposito: "",
      municipioDepositario: getMunicipioPorId(
        formApreensao.getFieldValue("municipioDepositarioId")
      ),
      municipioFiscalizacao: getMunicipioPorId(
        formApreensao.getFieldValue("municipioFiscalizacaoId")
      ),
      municipioFiscalizado: getMunicipioPorId(
        formApreensao.getFieldValue("municipioFiscalizadoId")
      ),
      nomeAssinanteFiscalizado: fiscalizadoSignature.nomeAssinante,
      nomeAssinanteDepositario: depositarioSignature.nomeAssinante,
      nomeDepositario: formApreensao.getFieldValue("nomeDepositario") || "",
      nomeFiscalizado: formApreensao.getFieldValue("nomeFiscalizado") || "",
      numero: numTermo,
      providencias: formApreensao.getFieldValue("providencias") || "",
      renasemDepositario:
        formApreensao.getFieldValue("renasemDepositario") || "",
      renasemFiscalizado:
        formApreensao.getFieldValue("renasemFiscalizado") || "",
      servidores: [],
      statusAssinaturaFiscalizado: "ASSINADO_FISICAMENTE",
      statusAssinaturaDepositario: "ASSINADO_FISICAMENTE",
      telefoneDepositario:
        formApreensao
          .getFieldValue("telefoneDepositario")
          ?.replace(/[^\d]+/g, "") || "",
      telefoneFiscalizado:
        formApreensao
          .getFieldValue("telefoneFiscalizado")
          ?.replace(/[^\d]+/g, "") || "",
      termoApreensao: null,
      tif: tifSelected,
      tipoTermo: formApreensao.getFieldValue("termo"),
    };

    const servs = servidoresSelecionados.map((servidor) => {
      return {
        assinatura: "",
        servidor: {
          cpfCnpj: servidor.servidor.cpfCnpj,
          id: servidor.servidor.id,
          inscricaoEstadual: servidor.servidor.inscricaoEstadual,
          matricula: servidor.servidor.matricula,
          nome: servidor.servidor.nome,
        },
      };
    });

    if (termoApreensaoSelected) {
      formValue = {
        ...formValue,
        termoApreensao: {
          amparoLegal: termoApreensaoSelected.amparoLegal,
          dataFiscalizacao:
            moment(termoApreensaoSelected.dataFiscalizacao).format(
              "DD/MM/YYYY"
            ) === "Data inválida"
              ? termoApreensaoSelected.dataFiscalizacao
              : moment(termoApreensaoSelected.dataFiscalizacao).format(
                  "DD/MM/YYYY"
                ),
          id: termoApreensaoSelected.id || 0,
          municipioFiscalizacao: termoApreensaoSelected.municipioFiscalizacao,
          nomeFiscalizado: termoApreensaoSelected.nomeFiscalizado,
          produto: termoApreensaoSelected.produto,
          numero: termoApreensaoSelected.numero,
          providencias: termoApreensaoSelected.providencias,
          tipoTermo: termoApreensaoSelected.tipoTermo,
        },
      };
    }

    formValue = { ...formValue, servidores: servs };

    if (anexos.length > 0) {
      let anexosImage: IAnexos[] = [];

      await getAnexosFormatadosBase64(anexos).then((res) => {
        anexosImage = res;
      });

      formValue = {
        ...formValue,
        anexos: anexosImage.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ),
      };
    }

    setPrintedTermo(formValue);
  };

  useEffect(() => {
    if (printedTermo) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [printedTermo]);

  return (
    <Fragment>
      <Button onClick={handlePreview}>Gerar prévia PDF</Button>

      {printedTermo && (
        <div style={{ display: "none" }}>
          {printedTermo && (
            <div ref={termoApreensaoRef}>
              <div className="marcadagua">MINUTA</div>
              <table
                style={{ width: 780 }}
                className="termo-apreensao-liberacao-impressao-font"
              >
                <HeaderTermoApreensaoLiberacaoImpressao
                  numTermo={printedTermo?.numero || ""}
                  tipoTermo={printedTermo?.tipoTermo || ""}
                />
                <BodyTermoApreensaoLiberacaoImpressao
                  termo={printedTermo}
                  propriedade={printedTermo.tif?.nomePropriedade || ""}
                />
                <FooterTermoApreensaoLiberacaoImpressao termo={printedTermo} />
              </table>

              {printedTermo.anexos.map((anexo, ind) => {
                return (
                  <ImagesTermoApreensaoLiberacaoImpressao
                    key={ind}
                    termo={printedTermo}
                    anexo={anexo}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

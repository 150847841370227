import { apiService } from "../../../services/api";
import { readerBlobToFileReader } from "../../../functions/readerBlobToFileReader";
import { useActionsTAL } from "./useActionsTAL";

export const useTAL = () => {
  const { listaTermos } = useActionsTAL();

  const getTermoApreensao = async (numTermo: string) => {
    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("numero", numTermo);

      const termoApreensaoContent =
        await apiService.termoApreensaoLiberacao.listar(params.toString());

      if (termoApreensaoContent.content.length === 0) {
        return {
          termo: null,
          message:
            "Por favor verifique se o número do termo está preenchido corretamente.",
        };
      }

      const termoApreensaoOnline =
        await apiService.termoApreensaoLiberacao.byId(
          termoApreensaoContent.content[0].id
        );

      if (termoApreensaoOnline.tipoTermo === "LIBERACAO") {
        return {
          termo: null,
          message:
            "O Número do Termo preenchido corresponde a um Termo de Liberação.",
        };
      }

      if (
        termoApreensaoOnline &&
        termoApreensaoOnline.servidores.length === 0
      ) {
        return {
          termo: null,
          message:
            "Termo de Apreensão informado possivelmente foi criado no antigo Sisdev-mobile",
        };
      }

      return {
        termo: termoApreensaoOnline,
        message: "Termo de Apreensão encontrado com sucesso!",
      };
    } else {
      const termoSelected = listaTermos.find(
        (termo) => termo.numero === numTermo
      );

      if (!termoSelected) {
        return {
          termo: null,
          message:
            "Por favor verifique se o número do termo está preenchido corretamente.",
        };
      }

      if (termoSelected?.tipoTermo === "LIBERACAO") {
        return {
          termo: null,
          message:
            "O Número do Termo preenchido corresponde a um Termo de Liberação.",
        };
      }

      return {
        termo: termoSelected,
        message: "Termo de Apreensão encontrado com sucesso!",
      };
    }
  };

  const getAnexo = async (
    dadosAnexos: {
      nomeUpload: string;
      nomeArquivo: string;
    }[]
  ) => {
    let dataUrls: Blob[] = [];
    let response: any[] = [];

    await Promise.allSettled(
      dadosAnexos.map((anexo) =>
        apiService.termoApreensaoLiberacao.getAnexo(anexo.nomeUpload)
      )
    ).then((data) => {
      data.forEach((infoAnexo) => {
        if (infoAnexo.status === "fulfilled") {
          dataUrls.push(infoAnexo.value.data);
        }
      });
    });

    await Promise.allSettled(
      dataUrls.map((data) => readerBlobToFileReader(data))
    ).then((res) => {
      res.forEach((infoAnexo, ind) => {
        if (infoAnexo.status === "fulfilled") {
          // console.log(infoAnexo.value.result);
          response.push({
            ...dadosAnexos[ind],
            arquivo: infoAnexo.value.result,
          });
        }
      });
    });

    return response;
  };

  return {
    getTermoApreensao,
    getAnexo,
  };
};

import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { MinusOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { useState } from "react";
import { TitleTermo } from "../../../styles/TitleTermo";
import { apiService } from "../../../services/api";
import { ITipoAtividade } from "../../../interfaces/ITipoAtividade";
import { openNotification } from "../../../components/Notifications";

export function TifConfig() {
  const [form] = Form.useForm();
  const [messageApi, contextholderMessage] = message.useMessage();
  const [atividadesOptions, setAtividadesOptions] = useState<ITipoAtividade[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  //QUERY DE REQUEST DAS ATIVIDADES
  const { data: listaProgramas } = useQuery({
    queryKey: ["PROGRAMA_ATIVIDADE"],
    initialData: [],
    queryFn: () => apiService.programas(),
  });

  const { data: listaAtividades, refetch } = useQuery({
    queryKey: ["ATIVIDADES_INTERDITADAS"],
    queryFn: () => apiService.atividadesInterdicao.listar(),
  });

  const columns: ColumnsType<any> = [
    {
      title: "Programa",
      dataIndex: "programa",
      render: (programa: string) => <p>{programa}</p>,
    },
    {
      title: "Atividade",
      dataIndex: "atividade",
      render: (atv: string) => <p>{atv}</p>,
    },
    {
      title: "Nome na Impressão",
      dataIndex: "atividadeInterditada",
      render: (atv: string) => <p>{atv}</p>,
    },

    {
      title: "Ações",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Remover">
            <Button
              danger
              onClick={() => handleRemoveAtv(record.key)}
              disabled={isLoading || !navigator.onLine}
            >
              <MinusOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSubmit = async (values: any) => {
    const keyMessage = "atividade";
    if (!listaProgramas) return;

    const isSameProgram = listaAtividades?.find(
      (atv) => atv.areaAtividade?.id === values.programaId
    );

    if (
      isSameProgram &&
      listaAtividades?.some((atv) => atv.atividade?.id === values.atividadeId)
    ) {
      openNotification({
        type: "info",
        message: "Não foi possível adicionar atividade",
        description: "Atividade já foi selecionada.",
      });
      return;
    }

    let resultado = null;

    listaProgramas.forEach((programa) => {
      if (programa.id === values.programaId) {
        const atividade = programa.atividades.find(
          (atv) => atv.id === values.atividadeId
        );

        if (!atividade) return;

        resultado = {
          areaAtividade: {
            id: programa.id,
            nome: programa.nomePrograma,
          },
          atividade: {
            id: atividade.id,
            nome: atividade.nome,
          },
          atividadeInterditada: values.nomeAtividade || "",
        };
      }
    });

    setIsLoading(true);
    messageApi.loading({
      duration: 0,
      key: keyMessage,
      content: "Adicionando atividade...",
    });

    if (!resultado) return;

    await apiService.atividadesInterdicao
      .adicionar(resultado)
      .then(() => {
        message.destroy(keyMessage);

        openNotification({
          type: "success",
          message: "Interdição adicionada na atividade com sucesso!",
        });
        form.resetFields();

        refetch();
      })
      .catch((erro) => {
        message.destroy(keyMessage);
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel colocar atividade como interdição",
          description: erro,
        });
      });

    setIsLoading(false);
  };

  const handleRemoveAtv = async (
    areaAtividadeInterdicaoId: number | undefined
  ) => {
    const keyMessage = "atividade";
    if (!areaAtividadeInterdicaoId) return;

    // console.log(resultado);

    setIsLoading(true);
    messageApi.loading({
      duration: 0,
      key: keyMessage,
      content: "Removendo atividade...",
    });

    await apiService.atividadesInterdicao
      .remover(areaAtividadeInterdicaoId)
      .then(() => {
        message.destroy(keyMessage);
        openNotification({
          type: "success",
          message: "Interdição removida da atividade com sucesso!",
        });

        refetch();
      })
      .catch((erro) => {
        console.log(erro);
        message.destroy(keyMessage);
        openNotification({
          type: "error",
          message: "Não foi possivel remover interdição da atividade",
          description: erro,
        });
      });
    setIsLoading(false);
  };

  const handleChangePrograma = async (value: number) => {
    if (!listaProgramas) return;

    let atividades = listaProgramas.filter(
      (programa) => programa.id === value
    )[0].atividades;

    setAtividadesOptions(atividades);
    form.setFieldsValue({ atvidadeId: "" });
  };

  return (
    <>
      <TitleTermo>Configurações TIF</TitleTermo>
      {contextholderMessage}
      <Divider />

      <Typography.Title level={3}>Atividades</Typography.Title>
      <Typography style={{ opacity: 0.7, paddingBottom: 20, fontSize: 13 }}>
        Selecionar atividades que geram Interdição
      </Typography>
      <Form
        labelCol={{ span: 20, flex: "150px" }}
        wrapperCol={{ flex: 1, span: 14 }}
        labelWrap
        labelAlign="left"
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label="Programa"
          name="programaId"
          rules={[{ required: true }]}
        >
          <Select
            style={{ minWidth: "190px" }}
            allowClear
            onChange={handleChangePrograma}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input) ||
              (option!.children as unknown as string).includes(
                input.toUpperCase()
              )
            }
          >
            {listaProgramas?.map((programa) => (
              <Select.Option key={programa.id} value={programa.id}>
                {programa.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Atividade"
          name="atividadeId"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            style={{ minWidth: "190px" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input) ||
              (option!.children as unknown as string).includes(
                input.toUpperCase()
              )
            }
            // onChange={handleChangeAtividade}
          >
            {atividadesOptions.map((atividade) => (
              <Select.Option key={atividade.id} value={atividade.id}>
                {atividade.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Nome na Impressão"
          name="nomeAtividade"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Row justify="center">
          <Space direction="vertical" size={10}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading || !navigator.onLine}
            >
              Adicionar Atividade
            </Button>
            <Col />
          </Space>
        </Row>
      </Form>

      <Divider>
        <Typography.Title level={4}>
          Atividades que Geram interdição
        </Typography.Title>
      </Divider>
      <Table
        bordered
        locale={{
          emptyText: (
            <Empty
              description={"Sem dados"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        dataSource={listaAtividades?.map((atv) => {
          return {
            key: atv?.id,
            programa: atv.areaAtividade?.nome,
            atividadeInterditada: atv.atividadeInterditada,
            atividade: atv.atividade.nome,
          };
        })}
        columns={columns}
        style={{
          overflowX: "auto",
        }}
      />
    </>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { tifAction } from "../../../store/actions/tifAction";
import { ITif } from "../../../interfaces/ITif";
import { filtroTifAction } from "../../../store/actions/filtroTifAction";
import { getFiltroTif } from "../../../store/feature/filtroTifSlice";
import { getTIFs } from "../../../store/feature/tifSlice";

export const useActionsTif = () => {
  const { addTermo, removeItem, updateItem, addItemByFilter } = tifAction;
  const { addFiltroTif, removeFiltroTif } = filtroTifAction;
  const appDispatch = useDispatch();
  const listaTermoFiltro = useSelector(getFiltroTif);
  const listaTif = useSelector(getTIFs);

  const adicionarTif = (tif: ITif) => {
    appDispatch(addTermo(tif));
  };

  const removerTif = (numTif: string) => {
    appDispatch(removeItem(numTif));
  };

  const atualizarTif = (tif: ITif) => {
    appDispatch(updateItem(tif));
  };

  const adicionarTifByFiltro = (tif: ITif) => {
    appDispatch(addItemByFilter(tif));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTif(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTif());
  };

  return {
    adicionarTif,
    removerTif,
    atualizarTif,
    adicionarTifByFiltro,
    aplicarFiltro,
    removerFiltro,
    listaTermoFiltro,
    listaTif,
  };
};

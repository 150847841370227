import { createAction } from "@reduxjs/toolkit";
import { IUnidadeMedida } from "../../interfaces/IUnidadeMedida";

const addUnidadeMedida = createAction(
  "UNIDADE_MEDIDA/addUnidadeMedida",
  (unidades: IUnidadeMedida[], dateTimeExpire: number) => ({
    payload: { unidades, dateTimeExpire },
  })
);

const removeUnidadeMedida = createAction(
  "UNIDADE_MEDIDA/removeUnidadeMedida",
  () => ({
    payload: {},
  })
);

export const unidadeMedidaAction = { addUnidadeMedida, removeUnidadeMedida };

import { createAction } from "@reduxjs/toolkit";
import { IMenuOptions } from "../../interfaces/IMenuOptions";

const updateKey = createAction("MENU/updateKey", (value: string[]) => ({
  payload: value,
}));

const addMenu = createAction("MENU/addMenu", (menu: IMenuOptions[]) => ({
  payload: { menu },
}));

const updateKeyAnaliseLasgal = createAction("MENU/updateKeyAnaliseLasgal",(key:string[])=>({
  payload:{key}
}))



export const menuAction = { updateKey, addMenu,updateKeyAnaliseLasgal };

import { useState } from "react";
import { openNotification } from "../../../../components/Notifications";
import { useCategoriaSemente } from "../../../../hooks/useCategoriaSemente";
import { useDadosTermoApreensaoLiberacao } from "../../../../hooks/useDadosTermoApreensaoLiberacao";
import { useDadosTermoColeta } from "../../../../hooks/useDadosTermoColeta";
import { useEspecie } from "../../../../hooks/useEspecie";
import { useEstadioFenologico } from "../../../../hooks/useEstadioFenologico";
import { useMateriaisColetados } from "../../../../hooks/useMateriaisColetados";
import { apiService } from "../../../../services/api";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosTermoColeta() {
  const { adicionarCategorias, listaCategoriaSemente, removerCategorias } =
    useCategoriaSemente();

  const { adicionarEstadios, listaEstadioFenologico, removerEstadios } =
    useEstadioFenologico();
  const {
    adicionarMateriaisColetados,
    listaMateriaisColetados,
    removerMateriaisColetados,
  } = useMateriaisColetados();
  const { listaEspecies, removerEspecies, sincronizarEspecies } = useEspecie();
  const {
    termoColetaPersistedAtItems,
    adicionarPersistedAtTermoColeta,
    removerPersistedAtTermoColeta,
  } = useDadosTermoColeta();
  const { termoApreensaoLiberacaoPersistedAtItems } =
    useDadosTermoApreensaoLiberacao();
  const [isDownloading, setIsDownloading] = useState(false);

  const termoColetaDownloadDados = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([
        apiService.estadiosFenologicos(),
        apiService.materialColetado(),
        apiService.especie(),
        apiService.categorias(),
      ]).then((res) => {
        const requestRejected = res.filter(
          (data) => data.status === "rejected"
        );

        if (requestRejected.length > 0) {
          openNotification({
            type: "error",
            message: `Erro ao ${
              action === "adicionar" ? "baixar" : "atualizar"
            } informações`,
            description:
              "Tente novamente, se o erro persistir entre em contato com o suporte",
          });
          return;
        }

        if (res[0].status === "fulfilled") {
          adicionarEstadios(res[0].value, dateTimeExpire);
        }

        if (res[1].status === "fulfilled") {
          adicionarMateriaisColetados(res[1].value, dateTimeExpire);
        }

        if (res[2].status === "fulfilled") {
          sincronizarEspecies(res[2].value, dateTimeExpire);
        }

        if (res[3].status === "fulfilled") {
          adicionarCategorias(res[3].value, dateTimeExpire);
        }

        adicionarPersistedAtTermoColeta(dateTimeExpire);
        openNotification({
          type: "success",
          message: `Dados para o Termo de Coleta ${
            action === "adicionar" ? "sincronizados" : "atualizados"
          } com Sucesso!`,
        });
      });

      setIsDownloading(false);
    } else {
      removerEstadios();
      removerMateriaisColetados();
      termoApreensaoLiberacaoPersistedAtItems === 0 && removerEspecies();
      removerCategorias();
      removerPersistedAtTermoColeta();

      openNotification({
        type: "success",
        message:
          "Dados sincronizados do Termo de Coleta removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Dados para o Termo de Coleta de Amostras:"
      dadosTermoDownloading={isDownloading}
      handleChange={termoColetaDownloadDados}
      isDataDownloaded={
        listaEstadioFenologico.length > 0 &&
        listaMateriaisColetados.length > 0 &&
        listaEspecies.length > 0 &&
        listaCategoriaSemente.length > 0
      }
      timeExpireData={termoColetaPersistedAtItems}
    />
  );
}

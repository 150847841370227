import { createAction } from "@reduxjs/toolkit";
import { IProdutoAgrotoxico } from "../../interfaces/IProdutoAgrotoxico";

const addProdutosAgrotoxico = createAction(
  "PRODUTO_AGROTOXICO/addProdutosAgrotoxico",
  (produtos: IProdutoAgrotoxico[], createdAt: number) => ({
    payload: { produtos, createdAt },
  })
);

const removeProdutosAgrotoxico = createAction(
  "PRODUTO_AGROTOXICO/removeProdutosAgrotoxico",
  () => ({
    payload: {},
  })
);

export const produtoAgrotoxicoAction = {
  addProdutosAgrotoxico,
  removeProdutosAgrotoxico,
};

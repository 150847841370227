import { Divider, Form, FormInstance, Input } from "antd";
import moment from "moment";
import styles from "./style.module.css";
import { transformStringToNumber } from "../../../../../utils/transformStringToNumber";
import { IAmostraDadosLasgal } from "../../../../../interfaces/IAmostraDadosLasgal";
import { ITabelaTesteLasgal } from "../../../../../interfaces/ITabelaTesteLasgal";

export function FormDadosAmostraLASGAL({
  form,
  formPureza,
  dataTable,
  setDataTable,
  mudarStatusOutrasCultivares,
  amostra,
}: {
  form: FormInstance;
  formPureza: FormInstance;
  amostra: IAmostraDadosLasgal;
  dataTable: ITabelaTesteLasgal[];
  setDataTable: React.Dispatch<React.SetStateAction<ITabelaTesteLasgal[]>>;
  mudarStatusOutrasCultivares: (value: boolean) => void;
}) {
  const sumNocivas = () => {
    if (!form.getFieldValue("silvOesp") && !form.getFieldValue("pesoNocivas")) {
      form.setFieldValue("nocivas", "0");
      return;
    }

    if (form.getFieldValue("silvOesp") && !form.getFieldValue("pesoNocivas")) {
      form.setFieldValue("nocivas", form.getFieldValue("silvOesp"));
      return;
    }

    if (form.getFieldValue("pesoNocivas") && !form.getFieldValue("silvOesp")) {
      form.setFieldValue("nocivas", form.getFieldValue("pesoNocivas"));
      return;
    }

    const valuePesoNocivas = transformStringToNumber(
      form.getFieldValue("pesoNocivas")
    );
    const valueSilvOesp = transformStringToNumber(
      form.getFieldValue("silvOesp")
    );

    const result = valueSilvOesp + valuePesoNocivas;

    form.setFieldValue(
      "nocivas",
      result.toString().replace(".", ",").slice(0, 5)
    );
  };

  return (
    <>
      <h1 className={styles["title-topico"]}>Dados Amostra</h1>
      {/* DADOS AMOSTRA */}
      <div className={styles["content-dados-amostra"]}>
        <p>Lote: {amostra.lote}</p>
        <p>Especie: {amostra.especie}</p>
        <p>
          Data entrada:{" "}
          {moment(amostra.dataRecebimentoAmostraLasgal).format("DD/MM/YYYY")}
        </p>

        <p>Categoria: {amostra.categoria?.nome}</p>
        <p>Cultivar: {amostra.cultivar?.nome}</p>
        <p>Operador:</p>
      </div>
      <Divider />
      {/*  */}
      <Form
        labelWrap
        labelAlign="left"
        id="teste-lasgal"
        form={form}
        wrapperCol={{ flex: 1, span: 10 }}
      >
        <div className={styles.container}>
          <Form.Item label="Outras cultivares em" name="outraCultivares">
            <Input
              style={{ minWidth: 150 }}
              maxLength={5}
              placeholder="g"
              onChange={(e) => {
                const { value } = e.target;

                form.setFieldValue(
                  "outraCultivares",
                  value.replace(/[^0-9,]/g, "")
                );

                const auxDataTable = [
                  dataTable[0],
                  {
                    ...dataTable[1],
                    outraCultivares: value.replace(/[^0-9,]/g, ""),
                  },
                ];

                setDataTable(auxDataTable);
                mudarStatusOutrasCultivares(true);
              }}
            />
          </Form.Item>
          <Form.Item label="Peso Inicial" name="pesoInicial">
            <Input
              maxLength={5}
              placeholder="g"
              style={{ minWidth: 150 }}
              onChange={(e) => {
                const { value } = e.target;

                form.setFieldValue(
                  "pesoInicial",
                  value.replace(/[^0-9,]/g, "")
                );

                formPureza.setFieldValue(
                  "pesoInicial",
                  value.replace(/[^0-9,]/g, "")
                );
              }}
            />
          </Form.Item>
          <Form.Item
            label="Silv + OESP em"
            name="silvOesp"
            labelCol={{ flex: "115px", span: 20 }}
          >
            <Input
              style={{ minWidth: 150 }}
              placeholder="g"
              maxLength={5}
              onChange={(e) => {
                const { value } = e.target;
                form.setFieldValue("silvOesp", value.replace(/[^0-9,]/g, ""));
                sumNocivas();
              }}
            />
          </Form.Item>

          <Form.Item label="Peso nocivas" name="pesoNocivas">
            <Input
              style={{ minWidth: 150 }}
              maxLength={5}
              placeholder="g"
              onChange={(e) => {
                const { value } = e.target;
                form.setFieldValue(
                  "pesoNocivas",
                  value.replace(/[^0-9,]/g, "")
                );
                sumNocivas();
              }}
            />
          </Form.Item>

          <Form.Item label="d=b+c Nocivas em" name="nocivas">
            <Input style={{ minWidth: 150 }} disabled placeholder="g" />
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

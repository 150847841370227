import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../";
import { IProgramaAtividades } from "../../interfaces/IProgramaAtividades";

interface IProgramaSlice {
  items: IProgramaAtividades[];
  persistedAt: number;
}

const initialState: IProgramaSlice = {
  items: [],
  persistedAt: 0,
};

const programaSlice = createSlice({
  name: "PROGRAMA_ATIVIDADES",
  initialState,
  reducers: {
    addProgramas: (state, action: any) => {
      const { programas, persistedAt } = action.payload;
      state.items = programas;
      state.persistedAt = persistedAt;
    },
    removeProgramas: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export const getTipoProgramas = (state: RootState) => {
  return state.programas.items;
};

export default programaSlice.reducer;

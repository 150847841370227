import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ITermoColetaAmostras } from "../../interfaces/ITermoColetaAmostras";
import { openNotification } from "../../components/Notifications";

interface ITermoColetaSlice {
  items: ITermoColetaAmostras[];
  persistedAtItemsSync: number;
}

const initialState: ITermoColetaSlice = {
  items: [],
  persistedAtItemsSync: 0,
};

const termoColetaSlice = createSlice({
  name: "TERMO_COLETA",
  initialState,
  reducers: {
    addTermo: (state, action) => {
      const { termo } = action.payload;

      state.items.push(termo);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    removeTermo: (state, action) => {
      const { numTermo } = action.payload;
      state.items = state.items.filter(
        (item) => item.termoColetaAmostra !== numTermo
      );
    },

    updateTermo: (state, action) => {
      const { termo } = action.payload;

      state.items = state.items.map((item) => {
        if (item.termoColetaAmostra === termo.termoColetaAmostra) return termo;

        return item;
      });
    },
    updateAssinatura: (state, action) => {
      const { dadosEdit, termoId } = action.payload;
      state.items = state.items.map((termo) => {
        if (termo.id === termoId) {
          return {
            ...termo,
            ...dadosEdit,
          };
        }
        return termo;
      });
    },

    updateAssinaturaRollback: (state, action: any) => {
      const { termoColeta }: { termoColeta: ITermoColetaAmostras } =
        action.meta;

      state.items = state.items.map((item) => {
        if (item.id === termoColeta.id) return termoColeta;
        return item;
      });

      openNotification({
        type: "error",
        message: `Não foi possivel atualizar Termo de Coleta ${termoColeta.termoColetaAmostra}`,
        description: action.payload.response.data.detail,
      });
    },

    addTermoByFilter: (state, action) => {
      const { termo } = action.payload;
      state.items.push(termo);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    removeIdTermoColeta: (state, action) => {
      const { id } = action.payload;

      const forms = state.items.map((termo) => {
        if (termo.id === id) {
          delete termo.id;
        }

        return termo;
      });

      state.items = forms;
    },

    addTimePersistedAtItems: (state, action) => {
      const { createdAt } = action.payload;
      state.persistedAtItemsSync = createdAt;
    },

    removeTimePersistedAtItems: (state, action) => {
      state.persistedAtItemsSync = 0;
    },
  },
});

export default termoColetaSlice.reducer;

export const getTermosColeta = (state: RootState) => {
  const novo = state.termosColeta.items.map((form, ind, lista) => {
    return lista[lista.length - ind - 1];
  });

  return novo;
};

export const getTermosLasgal = (state: RootState) => {
  let termos = state.termosColeta.items.map((form, ind, lista) => {
    return lista[lista.length - ind - 1];
  });

  termos = termos.filter((termo) => termo.destino === "LASGAL");

  return termos.map((termo) => {
    return {
      key: termo.id || 0,
      numTermoColeta: termo.termoColetaAmostra,
      dataColeta: termo.dataColeta,
      especie: termo.especie?.nome || "",
      lote: "",
      dataRecebimento: "",
      situacao: termo.situacao,
    };
  });
};

export const getPersistedAtTermoColeta = (state: RootState) => {
  return state.termosColeta.persistedAtItemsSync;
};

import moment from "moment";

const letras = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

// 1- PRIMEIRO CARACTER É O MES SEGUINDO O ALFABETO ex: janeiro = A, fevereiro = b...
// 2- dois caracteres representando o DIA de criação do TIF
// 3- caracter de horario seguindo o alfabeto
// 4- os dois caracteres de minutos da criação do TIF

export const generateId = () => {
  const dia = moment().format("L").slice(0, 2);

  const mes = moment().month();

  const horario = parseInt(moment().format().slice(11, 13));
  const minutos = moment().format().slice(14, 16);

  const id = `${letras[mes].toUpperCase()}${dia}${letras[
    horario
  ].toUpperCase()}${minutos}`;

  return id;
};

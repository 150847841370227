import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ITif } from "../../interfaces/ITif";
import { openNotification } from "../../components/Notifications";

interface IItemsTifSlice extends ITif {
  error?: boolean;
}

export interface ITifSlice {
  items: IItemsTifSlice[];
  persistedAtItemsSync: number;
}

const initialState: ITifSlice = {
  items: [],
  persistedAtItemsSync: 0,
};

const tifSlice = createSlice({
  name: "TIF",
  initialState,
  reducers: {
    addTermo: (state, action) => {
      const { tif } = action.payload;

      state.items.push(tif);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    removeItem: (state, action) => {
      const { numTermo } = action.payload;

      state.items = state.items.filter((item) => item.numero !== numTermo);
    },

    updateItem: (state, action) => {
      const { tif } = action.payload;
      state.items = state.items.map((item) => {
        if (item.numero === tif.numero) return tif;

        return item;
      });
    },
    updateAtividades: (state, action) => {
      const { dadosEdit, tifId } = action.payload;
      state.items = state.items.map((tif) => {
        if (tif.id === tifId) {
          return {
            ...tif,
            ...dadosEdit,
          };
        }
        return tif;
      });
    },

    updateAtividadesRollback: (state, action: any) => {
      const { tif }: { tif: ITif } = action.meta;

      state.items = state.items.map((item) => {
        if (item.id === tif.id) return tif;
        return item;
      });

      openNotification({
        type: "error",
        message: `Não foi possivel atualizar TIF ${tif.numero}`,
        description: action.payload.response.data.detail,
      });
    },

    removeId: (state, action) => {
      const { id } = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === id) {
          delete item.id;
        }

        return item;
      });
    },

    addItemByFilter: (state, action) => {
      const { tif } = action.payload;
      state.items.push(tif);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    addTimePersistedAtItems: (state, action) => {
      const { createdAt } = action.payload;
      state.persistedAtItemsSync = createdAt;
    },

    removeTimePersistedAtItems: (state, action) => {
      state.persistedAtItemsSync = 0;
    },
  },
});

export default tifSlice.reducer;

export const getTIFs = (state: RootState) => {
  const novo = state.tif.items.map((form, ind, lista) => {
    return lista[lista.length - ind - 1];
  });

  return novo;
};

export const getPersistedAtTif = (state: RootState) => {
  return state.tif.persistedAtItemsSync;
};

import {Middleware} from "@reduxjs/toolkit"

export const logger:Middleware = (store)=> (next) => (action)=>{
    //console.log('dispatching',action);

    // console.log(localStorage.getItem('access_token'));

    let result = next(action);
    //console.log("next states", store.getState());
    return result;

}
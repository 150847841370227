import { IFormValuesTermoColeta } from "../../../interfaces/IFormValuesTermoColeta";
import { useSelector } from "react-redux";
import { useQueriesTermoColeta } from "./useQueriesTermoColeta";
import { apiService } from "../../../services/api";
import { getTIFs } from "../../../store/feature/tifSlice";
import { IAssinante } from "../../../interfaces/IAssinante";
import { getStatusAssinatura } from "../../../functions/statusAssinatura";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { IServidor } from "../../../interfaces/IServidor";
import { ITermoColetaAmostras } from "../../../interfaces/ITermoColetaAmostras";
import moment from "moment";
import { IEspecie } from "../../../interfaces/IEspecie";
import { useServidores } from "../../../hooks/useServidores";
import { useDadosTermoColeta } from "../../../hooks/useDadosTermoColeta";
import { useMunicipio } from "../../../hooks/useMunicipio";
import { usePropriedade } from "../../../hooks/usePropriedade";
import { useEstabelecimento } from "../../../hooks/useEstabelecimento";
import { getTermosColeta } from "../../../store/feature/termoColetaSlice";

interface IGetFormSubmitProps {
  assinaturaFiscalizado: IAssinante;
  statusAssinaturaColeta: CheckboxValueType[];
  assinaturaAmostrador: string;
  values: IFormValuesTermoColeta;
  especieSelected: IEspecie | null;
  numTermoColeta: string;
  amostrador: IServidor | null;
}

export const useTermoColeta = () => {
  const { listaServidores } = useServidores();
  const listaTifs = useSelector(getTIFs);
  const { listaAmostras } = useDadosTermoColeta();
  const { especies } = useQueriesTermoColeta();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { getMunicipioPorId } = useMunicipio();
  const listaTCA = useSelector(getTermosColeta);

  const getAmostrador = (matricula: string) => {
    const auxServidor = listaServidores.filter(
      (serv) => serv.matricula === matricula
    );
    if (auxServidor.length === 0) return null;

    return auxServidor[0];
  };

  const getEspecieSelected = (idEspecie?: number | "") => {
    if (!idEspecie) return null;
    if (especies.data) {
      return especies.data.filter((especie) => especie.id === idEspecie)[0];
    } else {
      return null;
    }
  };

  const getTermoExistente = async (numTIF?: string) => {
    if (!numTIF) return;
    const tif = listaTifs.filter((tif) => tif.numero === numTIF);

    if (tif.length > 0) {
      return true;
    }

    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("numero", numTIF);

      const data = await apiService.tif.listar(params.toString());

      return data.content.length > 0 ? true : false;
    } else {
      return false;
    }
  };

  const getFormSubmit = async ({
    assinaturaFiscalizado,
    statusAssinaturaColeta,
    assinaturaAmostrador,
    values,
    especieSelected,
    numTermoColeta,
    amostrador,
  }: IGetFormSubmitProps) => {
    let formValues: ITermoColetaAmostras = {
      cpfAssinante: assinaturaFiscalizado.cpfAssinante.replace(/[^\d]+/g, ""),
      funcaoAssinante: assinaturaFiscalizado.funcaoAssinante,
      nomeAssinante: assinaturaFiscalizado.nomeAssinante,
      assinaturaFiscalizado:
        statusAssinaturaColeta.length === 0
          ? assinaturaFiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() ||
            ""
          : "",
      assinaturaAmostrador: assinaturaAmostrador,
      statusAssinatura: getStatusAssinatura(statusAssinaturaColeta),
      dispensadoColetaDuplicata: values.declaracoes
        ? values.declaracoes.some(
            (declaracao: string) => declaracao === "dispensado"
          )
        : false,
      recebidoColetaDuplicata: values.declaracoes
        ? values.declaracoes.some(
            (declaracao: string) => declaracao === "recebido"
          )
        : false,
      propriedade: null,
      produtor: null,
      municipioColeta: getMunicipioPorId(values.municipioColetaId || undefined),
      destino: values.destino,
      nomeLaboratorio: values.nomeLaboratorio || "",
      termoFiscalizacao: values.numeroTIF || "",
      dataColeta: moment(values.dataColeta)
        .locale("pt-br")
        .format()
        .slice(0, 10),
      especie: especieSelected,
      safra: values.safra || "",
      tipoColeta: values.tipoAmostra,
      renasemOrigemSementes: values.renasemOrigem || "",
      paisOrigem: values.paisOrigem || "",
      dadosTratamentoSementes: values.dadosAgrotoxicos || "",
      observacao: values.observacoes || "",
      termoColetaAmostra: numTermoColeta || "",
      itens: listaAmostras,
      dataImpressao: moment().locale("pt-br").format("YYYY-MM-DD[T]HH:mm:ss"),
      nomeArquivo: `Termo ${numTermoColeta}`,
      cpfCnpjRequerente: values.cpfCnpjOrigem.replace(/[^\d]+/g, ""),
      nomeRequerente: values.nomeOrigem,
      enderecoRequerente: "",
      requerente: null,
      creaAmostrador: "",
      nomeAmostrador: amostrador ? amostrador.pessoa.nome : "",
      amostrador: amostrador,
      municipio: null,
      nomeUpload: `Termo ${numTermoColeta}`,
      codigo: 0,
      dar: "",
      renasemMatriculaAmostrador: values.matriculaAmostrador || "",
      situacao: "ANALISADA",
      tipo: "FISCALIZAÇÃO",
      plantaVoluntaria: false,
    };

    if (values.propriedadeId && !values.estabelecimentoId) {
      const dadosProdutor = await buscarPropriedadePeloCpfCnpj(
        values.cpfCnpjOrigem.replace(/[^\d]+/g, "")
      );
      const propriedade = dadosProdutor.find(
        (prop) => prop.id === values.propriedadeId
      );

      formValues = {
        ...formValues,
        propriedade: propriedade || null,
        produtor: propriedade?.produtores[0],
        municipio: propriedade?.municipio || null,
      };
    } else {
      const estabelecimento = await buscarEstabelecimentoPeloCnpj(
        values.cpfCnpjOrigem.replace(/[^\d]+/g, "")
      );

      formValues = {
        ...formValues,
        produtor: estabelecimento[0],
        municipio: estabelecimento[0].endereco.municipio,
      };
    }

    return formValues;
  };

  const buscarTermoColeta = async (numTermo: string) => {
    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("termoColetaAmostra", numTermo);
      const listaTermos = await apiService.termoColeta.listar(
        params.toString()
      );

      if (listaTermos.content.length > 0) {
        const termo = await apiService.termoColeta.byId(
          listaTermos.content[0].id
        );

        return termo;
      }
    } else {
      const termo = listaTCA.find((tca) => tca.termoColetaAmostra === numTermo);

      return termo;
    }
  };

  return {
    getAmostrador,
    getEspecieSelected,
    getTermoExistente,
    getFormSubmit,
    buscarTermoColeta,
  };
};

import { createAction } from "@reduxjs/toolkit";
import { IPropriedade } from "../../interfaces/IPropriedade";

const addPropriedades = createAction(
  "PROPRIEDADE/addPropriedades",
  (
    items: { municipioId: number; propriedades: IPropriedade[] },
    dateTimeCreate: number
  ) => ({
    payload: { items, dateTimeCreate },
  })
);

const removePropriedades = createAction(
  "PROPRIEDADE/removePropriedades",
  (idMunicipio: number) => ({
    payload: idMunicipio,
  })
);

const updatePropriedades = createAction(
  "PROPRIEDADE/updatePropriedades",
  (
    items: { municipioId: number; propriedades: IPropriedade[] },
    dateTimeUpdate: number
  ) => ({
    payload: { items, dateTimeUpdate },
  })
);

export const propriedadeAction = {
  addPropriedades,
  removePropriedades,
  updatePropriedades,
};

export const TIPO_IMAGENS = [
  "jpg",
  "png",
  "jpeg",
  "JPG",
  "PNG",
  "JPEG",
  "BMP",
  "bmp",
];

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IContentListagemTCA } from "../../interfaces/IListagemTermoColeta";

interface IFiltroTermoColetaSlice {
  items: {
    filtros: string[];
    params: string;
    termos: IContentListagemTCA[];
    numberPages: number;
  }[];
}

const initialState: IFiltroTermoColetaSlice = {
  items: [],
};

const filtroTermoColetaSlice = createSlice({
  name: "FILTRO_TERMO_COLETA",
  initialState,
  reducers: {
    addFiltroTermoColeta: (state, action) => {
      const { termos } = action.payload;

      state.items = termos;
    },
    removeFiltroTermoColeta: (state) => {
      state.items = [];
    },
  },
});

export default filtroTermoColetaSlice.reducer;

export const getFiltroTermoColeta = (state: RootState) =>
  state.filtroTermoColeta.items;

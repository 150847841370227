import { createSlice } from "@reduxjs/toolkit";
import { IUserController } from "../../interfaces/IUserController";
import { RootState } from "..";

interface IUserControllerSlice {
  user: IUserController | null;
}

const initialState: IUserControllerSlice = {
  user: null,
};

const userControllerSlice = createSlice({
  name: "USER_CONTROLLER",
  initialState,
  reducers: {
    addUserController: (state, action) => {
      const { userController }: { userController: IUserController } =
        action.payload;

      state.user = userController;
    },
    removeUserController: (state, action) => {
      state.user = null;
    },
  },
});

export default userControllerSlice.reducer;

export const getPermissoes = (state: RootState) =>
  state.userController.user?.permissaoMenu;

export const getUser = (state: RootState) => {
  return state.userController.user;
};

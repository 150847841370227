import styled from "styled-components";

export const ContainerAmostraPDF = styled.div<{
  width?: string;
  hasBorder?: boolean;
}>`
  border-right: ${(props) => (props.hasBorder ? "1px solid black" : "none")};
  display: grid;
  place-items: center;
  width: ${(props) => props.width || "auto"};
`;

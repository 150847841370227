export const FITOSSANITARIA = "FITOSSANITÁRIA";

export const OFICIAL = "OFICIAL";

export const DECLARACOES = [
  {
    label: "O detentor declara ter recebido a(s) Amostra(s) em duplicata(s)",
    value: "recebido",
  },
];

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IMaterialColetado } from "../../interfaces/IMaterialColetado";

interface IMaterialColetadoSlice{
    items:IMaterialColetado[],
    persistedAt:number,
}


const initialState:IMaterialColetadoSlice = {
    items:[],
    persistedAt:0,
}


const materialColetadoSlice = createSlice({
    name:"MATERIAL_COLETADO",
    initialState,
    reducers:{
        addMaterialColetado:(state,action)=>{
            const {materialColetado,dateTimeExpire} = action.payload;
            state.items = materialColetado;
            state.persistedAt = dateTimeExpire;
        },
        removeMaterialColetado:(state,action)=>{
            state.items = [];
            state.persistedAt = 0;
        }

    },
})


export default materialColetadoSlice.reducer;

export const getMaterialColetado = (state:RootState)=> state.materialColetado.items;
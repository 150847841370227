import { createAction } from "@reduxjs/toolkit";

const addFiltroTermoVistoria = createAction(
  "FILTRO_TERMO_VISTORIA/addFiltroTermoVistoria",
  (termos) => ({
    payload: { termos },
  })
);

const removeFiltroTermoVistoria = createAction(
  "FILTRO_TERMO_VISTORIA/removeFiltroTermoVistoria",
  () => ({
    payload: {},
  })
);

export const filtroTermoVistoriaAction = {
  addFiltroTermoVistoria,
  removeFiltroTermoVistoria,
};

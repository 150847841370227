import { createAction } from "@reduxjs/toolkit";

const addFiltro = createAction("FILTRO_TID/addFiltro", (tids) => ({
  payload: { tids },
}));

const removeFiltro = createAction("FILTRO_TID/removeFiltro", () => ({
  payload: {},
}));

export const filtroTidAction = { addFiltro, removeFiltro };

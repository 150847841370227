import { useState } from "react";
import { openNotification } from "../../../../components/Notifications";
import { useDadosTermoVistoria } from "../../../../hooks/useDadosTermoVistoria";
import { apiService } from "../../../../services/api";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosTermoVistoria() {
  const {
    adicionarFormsPerguntasTermoVistoria,
    listaFormsPerguntaTermoVistoria,
    removerFormsPerguntasTermoVistoria,
    adicionarPersistedAtTermoVistoria,
    removerPersistedAtTermoVistoria,
    termoVistoriaPersistedAtItems,
  } = useDadosTermoVistoria();
  const [isDownloading, setIsDownloading] = useState(false);

  const termoVistoriaDownloadingDados = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([apiService.listagemFormularios()]).then(
        (res) => {
          const requestRejected = res.filter(
            (data) => data.status === "rejected"
          );

          if (requestRejected.length > 0) {
            openNotification({
              type: "error",
              message: "Erro ao baixar informações",
              description:
                "Tente novamente, se o erro persistir entre em contato com o suporte",
            });
            return;
          }

          if (res[0].status === "fulfilled") {
            adicionarFormsPerguntasTermoVistoria(res[0].value, dateTimeExpire);
          }

          adicionarPersistedAtTermoVistoria(dateTimeExpire);

          openNotification({
            type: "success",
            message: `Formularios de Perguntas para o Termo de Vistoria ${
              action === "adicionar" ? "sincronizados" : "atualizados"
            } com Sucesso!`,
          });
        }
      );

      setIsDownloading(false);
    } else {
      removerFormsPerguntasTermoVistoria();
      removerPersistedAtTermoVistoria();

      openNotification({
        type: "success",
        message:
          "Formularios de Perguntas para o Termo de Vistoria removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Formulários de Perguntas para o Termo de Vistoria:"
      dadosTermoDownloading={isDownloading}
      handleChange={termoVistoriaDownloadingDados}
      isDataDownloaded={listaFormsPerguntaTermoVistoria.length > 0}
      timeExpireData={termoVistoriaPersistedAtItems}
    />
  );
}

import styled from "styled-components";

export const ModalContainerPostAnexos = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  span {
    opacity: 0.7;
    color: black;
  }
`;

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IFormAtividades } from "../../interfaces/IFormAtividades";
import { IRespostaAtividade } from "../../interfaces/IRespostaAtividade";

interface IAtividadeState {
  items: IFormAtividades[];
  respostasAtividades: IRespostaAtividade[];
}

const initialState: IAtividadeState = {
  items: [],
  respostasAtividades: [],
};

const atividadeSlice = createSlice({
  name: "ATIVIDADE",
  initialState,
  reducers: {
    addAtividades: (state, action: any) => {
      const values = action.payload;
      state.items.push(values);
    },
    updateAtividade: (state, action) => {
      const { atividade }: { atividade: IFormAtividades } = action.payload;

      state.items = state.items.map((atv) => {
        if (atv.key === atividade.key) return atividade;

        return atv;
      });
    },

    removeAtividades: (state, action: any) => {
      const { key, atividade } = action.payload;

      //verificando se possui alguma resposta dessa atividade
      state.respostasAtividades = state.respostasAtividades.filter(
        (atv) => atv.atividade.id !== atividade.atividade.id
      );

      state.items = state.items.filter((item) => item.key !== parseInt(key));
    },

    removeAllAtividades: (state) => {
      state.items = [];
      state.respostasAtividades = [];
    },

    addRespostasAtividade: (state, action) => {
      const { respostas }: { respostas: IRespostaAtividade[] } = action.payload;

      //CASO ATIVIDADE SEJA MOSCA CARAMBOLA
      if (respostas[0]?.armadilha) {
        state.respostasAtividades = state.respostasAtividades.filter((res) => {
          if (res.armadilha) {
            if (res.armadilha.id === respostas[0].armadilha?.id) {
              return null;
            }

            return res;
          }

          return res;
        });

        respostas.forEach((res) => {
          state.respostasAtividades.push(res);
        });

        return;
      }

      //ZERAR AS RESPOSTAS ANTERIORES DA ATIVIDADE
      state.respostasAtividades = state.respostasAtividades.filter(
        (res) => res.atividade.id !== respostas[0].atividade.id
      );

      // ADICIONAR AS NOVAS RESPOSTAS
      respostas.forEach((res) => {
        state.respostasAtividades.push(res);
      });
    },
  },
});

export const getAtividades = (state: RootState) => state.atividadesTIF.items;

export const getRespostasAtividades = (state: RootState) =>
  state.atividadesTIF.respostasAtividades;

export default atividadeSlice.reducer;

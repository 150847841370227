import { iconsLogo } from "../../../../../../../icons";

export function HeaderOficialImpressao() {
  return (
    <>
      <thead>
        <tr>
          <td style={{ width: "100vw" }}>
            <div
              style={{
                display: "block",
                border: "1px solid black",
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  padding: "10px 10px 0px 10px",
                  gap: 10,
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={iconsLogo[3].image}
                    alt={iconsLogo[3].name}
                    style={{ height: 60 }}
                  />
                </div>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={iconsLogo[2].image}
                    alt={iconsLogo[2].name}
                    style={{
                      height: 60,
                    }}
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </thead>
    </>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { termoColetaAction } from "../store/actions/termoColetaAction";
import { getPersistedAtTermoColeta } from "../store/feature/termoColetaSlice";
import { getAmostras } from "../store/feature/amostraSlice";
import { amostraAction } from "../store/actions/amostraAction";
import { IAmostra } from "../interfaces/IAmostra";

export const useDadosTermoColeta = () => {
  const termoColetaPersistedAtItems = useSelector(getPersistedAtTermoColeta);
  const listaAmostras = useSelector(getAmostras);
  const { addAmostra, removeAmostra, removeAllAmostras } = amostraAction;
  const { addTimePersistedAtItems, removeTimePersistedAtItems } =
    termoColetaAction;
  const appDispatch = useDispatch();

  const adicionarPersistedAtTermoColeta = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTermoColeta = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  const adicionarAmostra = (amostra: IAmostra) => {
    appDispatch(addAmostra(amostra));
  };

  const removerAmostra = (amostraId: number) => {
    appDispatch(removeAmostra(amostraId));
  };

  const removerTodasAmostras = () => {
    appDispatch(removeAllAmostras());
  };
  return {
    termoColetaPersistedAtItems,
    adicionarPersistedAtTermoColeta,
    removerPersistedAtTermoColeta,
    listaAmostras,
    adicionarAmostra,
    removerAmostra,
    removerTodasAmostras,
  };
};

import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export function Page404() {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe mas essa pagina não existe."
      extra={
        <Button type="primary" onClick={(e) => navigate("/home")}>
          Ir para pagina inicial
        </Button>
      }
    />
  );
}

import { createAction } from "@reduxjs/toolkit";
import { IDadosAssinaturaFiscalizadoTID, ITID } from "../../interfaces/ITid";

const addTermo = createAction("TID/addTermo", (tidJson: ITID) => ({
  payload: { tidJson },
}));

const updateAssinatura = createAction(
  "TID/updateAssinatura",
  (dadosEdit: IDadosAssinaturaFiscalizadoTID, termoId: number, tid: ITID) => ({
    payload: { dadosEdit, termoId },
    meta: {
      offline: {
        effect: {
          //CHAMADA DA API
          url: `/tids/${termoId}`,
          method: "PUT",
          data: dadosEdit,
        },

        // action to dispatch if network action fails permanently:
        rollback: {
          type: "TID/updateAssinaturaRollback",
          meta: { tid },
        },
      },
    },
  })
);

const updateTermo = createAction("TID/updateTermo", (tidJson: ITID) => ({
  payload: { tidJson },
}));

const removeTermo = createAction("TID/removeTermo", (numTermo: string) => ({
  payload: { numTermo },
}));

const addTermoCompleto = createAction(
  "TID/addTermoCompleto",
  (termo: ITID) => ({
    payload: { termo },
  })
);

export const tidAction = {
  addTermo,
  addTermoCompleto,
  updateTermo,
  removeTermo,
  updateAssinatura,
};

import { Form } from "antd";
import React from "react";
import { TabelaGerminacaoContext } from "../TabelaGerminacaoContext";

interface EdicaoLinhaTabelaGerminacaoProps {
  index: number;
}

export const EdicaoLinhaTabelaGerminacao: React.FC<
  EdicaoLinhaTabelaGerminacaoProps
> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <TabelaGerminacaoContext.Provider value={form}>
        <tr {...props} />
      </TabelaGerminacaoContext.Provider>
    </Form>
  );
};

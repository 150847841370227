import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import App from "./App";
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from "./services/authConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>
);

const configuration = {
  onUpdate: (registration: any) => {
    if (registration && registration.waiting) {
      if (
        window.confirm(
          "Nova versão disponivel!  Deseja atualizar o aplicativo?"
        )
      ) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      }
    }
  },
};

serviceWorkerRegistration.register(configuration);
 
reportWebVitals();

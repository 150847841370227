import { createAction } from "@reduxjs/toolkit";
import { IUserController } from "../../interfaces/IUserController";

const addUserController = createAction(
  "USER_CONTROLLER/addUserController",
  (userController: IUserController) => ({
    payload: { userController },
  })
);

const removeUserController = createAction(
  "USER_CONTROLLER/removeUserController",
  () => ({
    payload: {},
  })
);

export const userControllerAction = { addUserController, removeUserController };

export const TIPOS_TERMO = [
  { label: "Inspeção", value: "inspecao" },
  { label: "Fiscalização", value: "fiscalizacao" },
  { label: "Notificação", value: "notificacao" },
];

export const TIPOS_ATUADO = [
  { label: "Propriedade", value: "propriedade" },
  { label: "Estabelecimento", value: "estabelecimento" },
  { label: "Transito", value: "transito" },
  { label: "Profissional", value: "profissional" },
];

export const TIPOS_REPRESENTANTES = [
  { label: "Proprietário", value: "PROPRIETARIO" },
  { label: "Arrendatario", value: "ARRENDATARIO" },
  { label: "Ocupante", value: "OCUPANTE" },
  { label: "Transportador", value: "TRANSPORTADOR" },
];

export const FISCALIZACAO_FRONTEIRA = [
  { label: "Sim", value: "Sim" },
  { label: "Não", value: "Não" },
];

import { createAction } from "@reduxjs/toolkit";
import { ICategoria } from "../../interfaces/ICategoria";


const addCategorias = createAction(
    'CATEGORIAS/addCategorias',
    (categorias:ICategoria[],dateTimeExpire:number)=>({
        payload:{categorias,dateTimeExpire}
    })
)

const removeCategorias = createAction(
    'CATEGORIAS/removeCategorias',
    ()=>({
        payload:{}
    })
)


export const categoriaAction = {addCategorias,removeCategorias};
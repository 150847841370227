import { iconsLogo } from "../../icons";
import { Button, Typography } from "antd";
import styles from "./style.module.css";

export function Login({ singIn }: { singIn: () => void }) {
  return (
    <div className={styles["login-panel"]}>
      <div className={styles["login-form"]}>
        <Typography className={styles["titulo-sisdev"]}>
          SISDEV-mobile
        </Typography>
        <img
          src={iconsLogo[4].image}
          alt={iconsLogo[4].name}
          className={styles["login-logo"]}
        />

        <Button type="primary" onClick={singIn}>
          Entrar
        </Button>
      </div>
    </div>
  );
}

import { iconsLogo } from "../../../../../../icons";

export function HeaderTermoApreensaoLiberacaoImpressao({
  numTermo,
  tipoTermo,
}: {
  numTermo?: string;
  tipoTermo: string;
}) {
  return (
    <>
      <thead>
        <tr>
          <td>
            <div
              style={{
                display: "block",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "10px 10px 0px 10px",
                  gap: 150,
                }}
              >
                <div>
                  <img
                    src={iconsLogo[3].image}
                    alt={iconsLogo[3].name}
                    style={{
                      height: 70,
                    }}
                  />
                </div>

                <div>
                  <img
                    src={iconsLogo[2].image}
                    alt={iconsLogo[2].name}
                    style={{ height: 70 }}
                  />
                </div>
              </div>
            </div>

            {numTermo && (
              <div
                style={{
                  borderRight: "1px solid black",
                  borderLeft: "1px solid black",
                  paddingLeft: 10,
                  borderBottom: "1px solid black",
                  paddingBottom: 5,
                  display: "flex",
                  gap: 150,
                }}
              >
                <div>Número:{numTermo}</div>

                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Termo de{" "}
                  {tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação"}
                </div>
              </div>
            )}
          </td>
        </tr>
      </thead>
    </>
  );
}

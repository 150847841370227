import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IContentListagemTAL } from "../../interfaces/IListagemTermoApreensaoLiberacao";

interface IFiltroTermoApreensaoSlice {
  items: {
    filtros: string[];
    params: string;
    termos: IContentListagemTAL[];
    numberPages: number;
  }[];
}

const initialState: IFiltroTermoApreensaoSlice = {
  items: [],
};

const filtroTermoApreensaoSlice = createSlice({
  name: "FILTRO_TERMO_APREENSAO_LIBERACAO",
  initialState,
  reducers: {
    addFiltroTermoApreensao: (state, action) => {
      const { termos } = action.payload;

      state.items = termos;
    },
    removeFiltroTermoApreensao: (state, action) => {
      state.items = [];
    },
  },
});

export default filtroTermoApreensaoSlice.reducer;

export const getFiltroTermoApreensao = (state: RootState) =>
  state.filtroTermoApreensao.items;

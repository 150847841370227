import { useDispatch, useSelector } from "react-redux";
import { getUles } from "../store/feature/uleSlice";
import { uleAction } from "../store/actions/uleAction";
import { IUle } from "../interfaces/IUle";

export const useUle = () => {
  const listaUles = useSelector(getUles);
  const { addUles } = uleAction;
  const appDispatch = useDispatch();

  const adicionarUles = (ules: IUle[]) => {
    appDispatch(addUles(ules));
  };

  const getUle = (municipioId: number | "" | undefined) => {
    const ule = listaUles.find(
      (ule) => ule.municipio.id === municipioId && ule.tipoUnidade === "UL"
    );

    if (!ule) return null;

    return ule;
  };

  return { listaUles, adicionarUles, getUle };
};

import { createAction } from "@reduxjs/toolkit";


const ADD_FILTRO_TIF = 'FILTRO_TIF/addFiltroTif';

const addFiltroTif = createAction(
    ADD_FILTRO_TIF,
    (tif)=>({payload:tif})
)

const REMOVE_FILTRO_TIF = 'FILTRO_TIF/removeFiltroTif';

const removeFiltroTif  = createAction(
    REMOVE_FILTRO_TIF,
    ()=>({payload:{}})
)



export const filtroTifAction = {addFiltroTif,removeFiltroTif}
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { menuAction } from "../../../../../store/actions/menuAction";
import { getMenuSelectAnaliseLasgal } from "../../../../../store/feature/menuSlice";

export function MenuTesteLASGAL() {
  const styleItems = { width: 250, justifyContent: "center", display: "flex" };
  const appDispatch = useDispatch();
  const { updateKeyAnaliseLasgal } = menuAction;
  const menuSelected = useSelector(getMenuSelectAnaliseLasgal);

  return (
    <>
      <Menu
        onClick={(e) => {
          appDispatch(updateKeyAnaliseLasgal([e.key]));
        }}
        selectedKeys={menuSelected}
        mode="horizontal"
        style={{
          fontSize: 18,
          display: "flex",
          justifyContent: "center",
        }}
        items={[
          {
            key: "PUREZA",
            label: "PUREZA",
            style: styleItems,
          },
          {
            key: "GERMINACAO",
            label: "GERMINAÇÃO",
            style: styleItems,
          },
          {
            key: "DOSN",
            label: "DOSN",
            style: styleItems,
          },
        ]}
      />
    </>
  );
}

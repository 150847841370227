import { createAction } from "@reduxjs/toolkit";
import { IEspecie } from "../../interfaces/IEspecie";


const addEspecies = createAction(
    'ESPECIES/addEspecies',
    (especies:IEspecie[],dateTimeExpire:number)=>({
        payload:{especies,dateTimeExpire}
    })
)


const removeEspecies = createAction(
    "ESPECIES/removeEspecies",
    ()=>({
        payload:{}
    })
)



export const especieAction = { addEspecies,removeEspecies};
import { Button } from "antd";
import { ITabelaTesteLasgal } from "../../../../../interfaces/ITabelaTesteLasgal";
import { ITabelaContaminantesInformados } from "../../../../../interfaces/ITabelaContaminantesInformados";
import { VALOR_PADRAO } from "../../constants";

export function ButtonConfirmaNocivas({
  hasNocivas,
  dataTable,
  listaContaminanteSelecionados,
  armazenarDados,
}: {
  hasNocivas: boolean;
  dataTable: ITabelaTesteLasgal[];
  listaContaminanteSelecionados: ITabelaContaminantesInformados[];
  armazenarDados: (
    dadosTabela: ITabelaTesteLasgal[],
    dadosContaminantes: ITabelaContaminantesInformados[]
  ) => void;
}) {
  const onChange = () => {
    const auxDataTable = [
      dataTable[0],
      {
        ...dataTable[1],
        proibidas: hasNocivas ? VALOR_PADRAO : "",
        toleradas: hasNocivas ? VALOR_PADRAO : "",
      },
    ];

    const contaminanteSemProbidasToleradas =
      listaContaminanteSelecionados.filter(
        (contaminante) =>
          contaminante.grupo !== "PROBIDA" && contaminante.grupo !== "TOLERADA"
      );

    armazenarDados(auxDataTable, contaminanteSemProbidasToleradas);
  };

  return (
    <Button
      type={hasNocivas ? "primary" : "default"}
      style={{ width: 250 }}
      onClick={onChange}
    >
      {hasNocivas ? "Não fez DOSN (Nociva)" : "Fez DOSN (Nociva)"}
    </Button>
  );
}

import { ReactNode } from "react";
import styled from "styled-components";

const Title = styled.h1<{ hasMake?: boolean }>`
  color: ${(props) => (props.hasMake ? "rgba(0, 0, 0, 0.85)" : "red")};
  text-align: center;
  font-size: 14px;
`;

export function TitleItemTabelaTesteLASGAL({
  children,
  hasMakeTest,
}: {
  children: ReactNode;
  hasMakeTest: boolean;
}) {
  return (
    <Title hasMake={hasMakeTest}>
      {children}{" "}
      {hasMakeTest ? (
        ""
      ) : (
        <>
          <br /> <span>(Não Fez)</span>
        </>
      )}
    </Title>
  );
}

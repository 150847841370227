import { Button, Divider, Form, Input, Modal, Result, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import fs from "file-saver";
import { TitleTermo } from "../../../styles/TitleTermo";
import { useForms } from "../../../hooks/useForms";
import { RegistroFiscalizadoTID } from "../components/RegistroFiscalizadoTID";
import { AssinaturaTID } from "../components/AssinaturaTID";
import { ButtonFooterContainer } from "../../../styles/ButtonFooterContainer";
// import { formatarData } from "../../../functions/formatarData";
import { masks } from "../../../functions/mascaras";
import { useMunicipio } from "../../../hooks/useMunicipio";
import { ITID } from "../../../interfaces/ITid";
import { IServidorForm } from "../../../interfaces/IServidorForm";
import { IAssinante } from "../../../interfaces/IAssinante";
import { openNotification } from "../../../components/Notifications";
import { useServidores } from "../../../hooks/useServidores";
import { IAnexos } from "../../../interfaces/IAnexos";
import { useActionsTID } from "../hooks/useActionsTID";
import { getStatusAssinatura } from "../../../functions/statusAssinatura";
import { IFormValuesTID } from "../../../interfaces/IFormValuesTID";
import { getAnexosFormatadosBase64 } from "../../../functions/getAnexosFormatadosBase64";
import { converterBase64ToFile } from "../../../functions/converterBase64ToFile";
import { converterTIDtoFormData } from "../functions/converterTIDtoFormData";
import { apiService } from "../../../services/api";
import { SelectTipoTermoTID } from "../components/SelectTipoTermoTID";
import { useFunctionsTID } from "../hooks/useFunctionsTID";
import { TabelaServidoresForm } from "../../../components/TabelaServidoresForm";
import { UploadFilesForm } from "../../../components/UploadFilesForm";
import { AtividadeTID } from "../components/AtividadeTID";
import { ITif } from "../../../interfaces/ITif";

export function EdicaoTID() {
  const { numTermo } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValuesTID>();
  const [formServidores] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const { listaTIDs, adicionarTermo, removerTermo, adicionarTermoSubmited } =
    useActionsTID();
  const { buscarTermoInterdicao, resetarCamposFiscalizado } = useFunctionsTID();
  const tid = listaTIDs.find(
    (termo) => termo.numero === numTermo?.replaceAll("_", "/")
  );

  const { messageSubmitFailed } = useForms();
  const { getMunicipioPorId } = useMunicipio();
  const { removerTodosServidoresSelecionados, selecionarServidor } =
    useServidores();
  const { cepMask, telefoneMask, cpfCnpjMask } = masks;

  const [isInterdicao, setIsInterdicao] = useState(true);
  const [tifSelected, setTifSelected] = useState<Partial<ITif> | null>(null);
  const [anexo, setAnexo] = useState<any[]>([]);
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);

  const [servidores, setServidores] = useState<IServidorForm[]>([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [isSavingTid, setIsSavingTid] = useState(false);

  const handleSubmit = async (formValues: IFormValuesTID) => {
    const keyMessage = "tid";

    if (!tid) return;
    const termoInterdicaoSelected = await buscarTermoInterdicao(
      formValues.numTermoInterdicao
    );

    const termoInterdicaoSelectedSubmit = termoInterdicaoSelected
      ? {
          dataFiscalizacao: termoInterdicaoSelected.dataFiscalizacao,
          id: termoInterdicaoSelected.id || 0,
          municipioFiscalizacao: termoInterdicaoSelected.municipioFiscalizacao,
          nomeFiscalizado: termoInterdicaoSelected.nomeFiscalizado,
          numero: termoInterdicaoSelected.numero,
          tipoTermo: termoInterdicaoSelected.tipoTermo,
          statusAssinaturaFiscalizado:
            termoInterdicaoSelected.statusAssinaturaFiscalizado,
        }
      : null;

    const dadosAssinante = Object.entries(fiscalizadoSignature).filter(
      (item) => item[0] !== "assinatura"
    );

    if (!tifSelected) {
      openNotification({
        type: "info",
        message: "Não foi possivel Atualizar TID.",
        description: "Adicione um TIF válido",
      });
      return;
    }

    if (
      formValues.tipoTermo === "DESINTERDICAO" &&
      !termoInterdicaoSelectedSubmit
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel Atualizar TID.",
        description: "Adicione um Termo de Interdição válido",
      });
      return;
    }

    if (
      dadosAssinante.some((item) => !item[1]) &&
      (motivoNaoAssinatura.length === 0 ||
        motivoNaoAssinatura[0] === "ASSINADO_FISICAMENTE")
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel Atualizar TID.",
        description:
          "Preencha os dados do assinante Fiscalizado para salvar o TID",
      });
      return;
    }

    if (servidores.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel Atualizar TID.",
        description: "Adicione ao menos um servidor.",
      });
      return;
    }

    if (!isSignaturesFilled()) {
      openNotification({
        type: "info",
        message: "Não foi possivel Atualizar TID.",
        description: "Preencha as assinaturas pendentes",
      });
      return;
    }

    const servidorSelected = servidores.map((serv) => {
      return {
        assinatura:
          getStatusAssinatura(motivoNaoAssinatura) === "ASSINADO_FISICAMENTE"
            ? ""
            : serv.assinatura,
        servidor: {
          cpfCnpj: serv.servidor.cpfCnpj,
          id: serv.servidor.id,
          inscricaoEstadual: serv.servidor.inscricaoEstadual,
          matricula: serv.servidor.matricula,
          nome: serv.servidor.nome,
        },
      };
    });

    const atividadesSelected = tifSelected?.atividades
      ?.filter((atv) => formValues.atividadesId.includes(atv.id))
      .map((atv) => {
        return {
          areaAtividade: atv.areaAtividade,
          atividade: {
            id: atv.atividade.id,
            nome: atv.atividade.nome,
            interdicao: atv.interdicao,
          },
          id: atv.id,
        };
      });

    try {
      let tidSubmited: ITID = {
        dataCadastro: `${moment().format("YYYY-MM-DD[T]HH:mm:ss")}`,
        servidores: servidorSelected,
        anexos: [],
        assinaturaFiscalizado:
          motivoNaoAssinatura.length === 0
            ? fiscalizadoSignature?.assinatura
                ?.getTrimmedCanvas()
                .toDataURL() || ""
            : "",
        nomeAssinanteFiscalizado: fiscalizadoSignature.nomeAssinante,
        funcaoAssinanteFiscalizado: fiscalizadoSignature.funcaoAssinante,
        atividadesInterditadas: formValues.amparoLegal || "",
        cpfAssinanteFiscalizado: fiscalizadoSignature.cpfAssinante.replace(
          /[^\d]+/g,
          ""
        ),
        cepFiscalizado:
          form.getFieldValue("cepFiscalizado")?.replace(/[^\d]+/g, "") || "",
        emailFiscalizado: form.getFieldValue("emailFiscalizado") || "",
        cpfCnpjFiscalizado:
          form.getFieldValue("cpfCnpjFiscalizado")?.replace(/[^\d]+/g, "") ||
          "",
        nomeFiscalizado: form.getFieldValue("nomeFiscalizado") || "",
        inscricaoEstadualFiscalizado: form.getFieldValue("rgFiscalizado") || "",
        renasemFiscalizado: form.getFieldValue("renasemFiscalizado") || "",
        enderecoFiscalizado: form.getFieldValue("enderecoFiscalizado") || "",
        numero: tid.numero,
        tipoTermo: formValues.tipoTermo,
        tif: tifSelected,
        municipioFiscalizacao: getMunicipioPorId(
          form.getFieldValue("municipioFiscalizacaoId") || ""
        ),
        municipioFiscalizado: getMunicipioPorId(
          form.getFieldValue("municipioFiscalizadoId") || ""
        ),
        tipoRepresentante:
          form.getFieldValue("tipoRepresentante") !== "TRANSPORTADOR"
            ? form.getFieldValue("tipoRepresentante")
            : "",
        telefoneFiscalizado:
          form.getFieldValue("telefoneFiscalizado")?.replace(/[^\d]+/g, "") ||
          "",
        statusAssinaturaFiscalizado: getStatusAssinatura(motivoNaoAssinatura),
        termoInterdicao: termoInterdicaoSelectedSubmit,
        dataFiscalizacao: tid.dataFiscalizacao,
        atividades: atividadesSelected || [],
      };

      let anexosSubmitToTermo: (File | undefined)[] = [];

      if (anexo.length > 0) {
        await getAnexosFormatadosBase64(anexo).then((res) => {
          tidSubmited = { ...tid, anexos: res };

          const aux = res.map((anexo) => {
            return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
          });

          anexosSubmitToTermo = aux.filter((anexo) => anexo !== undefined);
        });
      }

      const formData = converterTIDtoFormData(tidSubmited, anexosSubmitToTermo);
      const tipoTermoFormatado =
        tidSubmited.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição";

      if (navigator.onLine) {
        setIsSavingTid(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        await apiService.tid
          .enviar(formData)
          .then((data) => {
            adicionarTermoSubmited({ ...tidSubmited, id: data.id });
            setIsSavingTid(false);
            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} Atualizado com sucesso!`,
            });

            navigate("/TID/listagem");
          })
          .catch((erro) => {
            console.log(erro);
            setIsSavingTid(false);
            message.destroy(keyMessage);
            openNotification({
              type: "error",
              message: `Não foi possivel Atualizar Termo de ${tipoTermoFormatado}`,
              description: erro.response?.data.detail,
            });
          });
      } else {
        removerTermo(tid.numero);
        adicionarTermo(tidSubmited);
        // MODAL DE CONFIRMAÇÃO SE O USUARIO QUISER BAIXAR UM BACKUP

        modal.info({
          title: `Gostaria de baixar um backup do Termo de ${tipoTermoFormatado}?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(tidSubmited)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(
              blob,
              `Termo de ${tipoTermoFormatado} ${tidSubmited.numero}.txt`,
              {
                autoBom: true,
              }
            );
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} Atualizado com sucesso!`,
              description: `Salvamento do Termo de ${tipoTermoFormatado} pendente, para quando aplicativo houver conexão com a internet.`,
            });

            navigate("/TID/listagem");
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      setIsSavingTid(false);
      message.destroy(keyMessage);
      openNotification({
        type: "error",
        message: `Não foi possivel Atualizar TID`,
        description: `Erro: ${error}`,
      });
    }
  };

  const isSignaturesFilled = () => {
    if (motivoNaoAssinatura[0] === "ASSINADO_FISICAMENTE") {
      return true;
    }

    if (
      fiscalizadoSignature?.assinatura?.toData().length === 0 &&
      motivoNaoAssinatura.length === 0
    ) {
      return false;
    }

    const isSignatureServEmpty = servidores.some(
      (serv) => serv.assinatura.length === 0
    );

    if (isSignatureServEmpty) {
      return false;
    }

    return true;
  };

  const modalConfirm = () => {
    modal.confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        navigate("/TID/listagem");
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const formatAnexos = (anexos: IAnexos[]) => {
    const auxAnexos = anexos.map((anexo) => {
      return {
        uid: anexo.nomeArquivo,
        name: anexo.nomeArquivo,
        status: "done",
        url: anexo.arquivo,
      };
    });

    return auxAnexos;
  };

  useEffect(() => {
    if (!tid) return;
    removerTodosServidoresSelecionados();
    setFiscalizadoSignature({
      nomeAssinante: tid.nomeAssinanteFiscalizado,
      cpfAssinante: cpfCnpjMask(tid.cpfAssinanteFiscalizado || ""),
      assinatura: null,
      funcaoAssinante: tid.funcaoAssinanteFiscalizado,
    });
    setTifSelected(tid.tif);

    if (tid.termoInterdicao) {
      const auxTermo = listaTIDs.find(
        (termo) =>
          termo.numero === tid.termoInterdicao?.numero &&
          termo.tipoTermo === "INTERDICAO"
      );

      if (!auxTermo) return;
    }

    if (tid.anexos.length > 0) {
      setAnexo(formatAnexos(tid.anexos));
    }

    if (tid.tipoTermo === "DESINTERDICAO") {
      form.setFieldValue("numTermoInterdicao", tid.termoInterdicao?.numero);
      setIsInterdicao(false);
    }

    tid.servidores.map((servidor) => {
      selecionarServidor(servidor);
      return servidor;
    });

    form.setFieldsValue({
      tipoTermo: tid.tipoTermo,
      tipoRepresentante: tid.tipoRepresentante,
      numTIF: tid.tif?.numero,
      dataTIF: moment(tid.dataFiscalizacao),
      nomeFiscalizado: tid.nomeFiscalizado,
      cpfCnpjFiscalizado: cpfCnpjMask(tid.cpfCnpjFiscalizado || ""),
      ufFiscalizadoId: tid.municipioFiscalizado?.uf.id,
      municipioFiscalizadoId: tid.municipioFiscalizado?.id,
      rgFiscalizado: tid.inscricaoEstadualFiscalizado,
      renasemFiscalizado: tid.renasemFiscalizado,
      enderecoFiscalizado: tid.enderecoFiscalizado,
      cepFiscalizado: cepMask(tid.cepFiscalizado || ""),
      telefoneFiscalizado: telefoneMask(tid.telefoneFiscalizado || ""),
      emailFiscalizado: tid.emailFiscalizado,
      municipioFiscalizacaoId: tid.municipioFiscalizacao?.id,
      amparoLegal: tid.atividadesInterditadas,
      localizacao: tid.tif?.caracteristicaFiscalizado,
      enderecoPropriedade: tid.tif?.enderecoPropriedade,
      nomePropriedade: tid.tif?.nomePropriedade,
      atividadesId: tid.atividades.map((atv) => {
        return atv.id;
      }),
    });

    //eslint-disable-next-line
  }, []);

  if (!tid)
    return (
      <>
        <Result
          status="error"
          title="Erro ao buscar TID"
          subTitle="Por favor tente novamente, se o erro persistir entre em contato com o suporte."
        />
      </>
    );

  return (
    <Fragment>
      {contextHolder}
      <TitleTermo>Termo de Interdição/Desinterdição</TitleTermo>
      <Divider />

      <Form
        id="tid"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={messageSubmitFailed}
      >
        <SelectTipoTermoTID
          form={form}
          resetFields={() => {
            setTifSelected(null);
            form.resetFields();
          }}
          setIsInterdicao={setIsInterdicao}
        />

        {!isInterdicao && (
          <Form.Item
            label="N° Termo de Interdição"
            name="numTermoInterdicao"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Digite o N° do Termo de Interdição"
              onChange={(e) => {
                setTifSelected(null);
                resetarCamposFiscalizado(form);
              }}
            />
          </Form.Item>
        )}

        <RegistroFiscalizadoTID
          form={form}
          setFiscalizadoSignature={setFiscalizadoSignature}
          setTifSelected={setTifSelected}
          tidEdit={tid}
        />

        <Divider />

        <UploadFilesForm setAnexos={setAnexo} anexos={anexo} />

        <AtividadeTID
          isInterdicao={isInterdicao}
          isTifSelected={Boolean(tifSelected)}
          atividadeOptions={tifSelected?.atividades}
        />
      </Form>

      <Divider />

      <TabelaServidoresForm form={formServidores} />

      <AssinaturaTID
        ativarModalInformarNumTermo={() => {}}
        form={form}
        servidores={servidores}
        setServidores={setServidores}
        fiscalizado={fiscalizadoSignature}
        setFiscalizado={setFiscalizadoSignature}
        motivoNaoAssinatura={motivoNaoAssinatura}
        setMotivoNaoAssinatura={setMotivoNaoAssinatura}
      />

      <ButtonFooterContainer>
        <Button form="tid" onClick={modalConfirm} disabled={isSavingTid}>
          Cancelar
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          form="tid"
          disabled={isSavingTid}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </Fragment>
  );
}

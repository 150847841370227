import { createAction } from "@reduxjs/toolkit";
import { IMaterialColetado } from "../../interfaces/IMaterialColetado";


const addMaterialColetado = createAction(
    'MATERIAL_COLETADO/addMaterialColetado',
    (materialColetado:IMaterialColetado[],dateTimeExpire:number)=>({
        payload:{materialColetado,dateTimeExpire}
    })
)

const removeMaterialColetado = createAction(
    'MATERIAL_COLETADO/removeMaterialColetado',
    ()=>({
        payload:{}
    })
)


export const materialColetadoAction = {addMaterialColetado,removeMaterialColetado};
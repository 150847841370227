import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ITermoApreensaoLiberacao } from "../../interfaces/ITermoApreensaoLiberacao";
import { openNotification } from "../../components/Notifications";

interface ITermoApreensaoLiberacaoSlice {
  items: ITermoApreensaoLiberacao[];
  persistedAtItemsSync: number;
}

const initialState: ITermoApreensaoLiberacaoSlice = {
  items: [],
  persistedAtItemsSync: 0,
};

const termoApreensaoLiberacaoSlice = createSlice({
  name: "TERMO_APREENSAO_LIBERACAO",
  initialState,
  reducers: {
    addTermo: (state, action) => {
      const { termoJson }: { termoJson: ITermoApreensaoLiberacao } =
        action.payload;

      state.items.push(termoJson);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    removeTermo: (state, action) => {
      const { numTermo }: { numTermo: string } = action.payload;
      const termos = state.items.filter((item) => item.numero === numTermo);
      //VERIFICAR SE POSSUI MAIS DE UM TERMO COM ESSE NUMERO
      if (termos.length > 1) {
        state.items = state.items.filter((termo) => {
          if (termo.numero === numTermo && !termo.id) return null;

          return termo;
        });

        return;
      }

      state.items = state.items.filter((item) => item.numero !== numTermo);
    },
    updateTermo: (state, action) => {
      const { termo } = action.payload;

      state.items = state.items.map((item) => {
        if (item.numero === termo.numero) return termo;

        return item;
      });
    },
    updateAssinatura: (state, action) => {
      const { dadosEdit, termoId } = action.payload;
      state.items = state.items.map((termo) => {
        if (termo.id === termoId) {
          return {
            ...termo,
            ...dadosEdit,
          };
        }
        return termo;
      });
    },

    updateAssinaturaRollback: (state, action: any) => {
      const { termo }: { termo: ITermoApreensaoLiberacao } = action.meta;

      state.items = state.items.map((item) => {
        if (item.id === termo.id) return termo;
        return item;
      });

      const tipoTermoFormatado =
        termo.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação";

      openNotification({
        type: "error",
        message: `Não foi possivel atualizar Termo de ${tipoTermoFormatado} ${termo.numero}`,
        description: action.payload.response.data.detail,
      });
    },
    addTermoByFilter: (state, action) => {
      const { termo } = action.payload;
      state.items.push(termo);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },
    removeIdTermo: (state, action) => {
      const { id } = action.payload;

      const forms = state.items.map((termo) => {
        if (termo.id === id) {
          delete termo.id;
        }

        return termo;
      });

      state.items = forms;
    },

    addTimePersistedAtItems: (state, action) => {
      const { createdAt } = action.payload;
      state.persistedAtItemsSync = createdAt;
    },

    removeTimePersistedAtItems: (state, action) => {
      state.persistedAtItemsSync = 0;
    },
  },
});

export default termoApreensaoLiberacaoSlice.reducer;

export const getTermosApreensaoLiberacao = (state: RootState) => {
  const ordenadoMaisRecente = state.termosApreensaoLiberacao.items.map(
    (form, ind, lista) => {
      return lista[lista.length - ind - 1];
    }
  );

  return ordenadoMaisRecente;
};

export const getPersistedAtTermoApreensaoLiberacao = (state: RootState) => {
  return state.termosApreensaoLiberacao.persistedAtItemsSync;
};

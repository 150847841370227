import { IAnexos } from "../../../../../../interfaces/IAnexos";
import { ITermoApreensaoLiberacao } from "../../../../../../interfaces/ITermoApreensaoLiberacao";
import { HeaderTermoApreensaoLiberacaoImpressao } from "../HeaderTermoApreensaoLiberacaoImpressao";

export function ImagesTermoApreensaoLiberacaoImpressao({
  termo,
  anexo,
}: {
  termo: ITermoApreensaoLiberacao;
  anexo: IAnexos;
}) {
  return (
    <>
      <table
        style={{ width: 780, pageBreakBefore: "always" }}
        className="termo-apreensao-liberacao-impressao-font"
      >
        <HeaderTermoApreensaoLiberacaoImpressao
          numTermo={termo.numero}
          tipoTermo={termo.tipoTermo}
        />

        <tbody>
          <tr>
            <td
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                textAlign: "center",
                backgroundColor: "#D9D9D9",
                fontWeight: "bold",
                display: "block",
              }}
            >
              Anexo de imagens
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                display: "block",
                minHeight: "80vh",
              }}
            >
              <div
                style={{
                  padding: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={anexo.arquivo}
                  alt={anexo.nomeArquivo}
                  width="75%"
                  // height="auto"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

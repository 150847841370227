import { Form } from "antd";
import React from "react";
import { TabelaDOSNContext } from "../TabelaDOSNContext";

interface EdicaoLinhaTabelaDOSNProps {
  index: number;
}

export const EdicaoLinhaTabelaDOSN: React.FC<EdicaoLinhaTabelaDOSNProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <TabelaDOSNContext.Provider value={form}>
        <tr {...props} />
      </TabelaDOSNContext.Provider>
    </Form>
  );
};

import { Form, Input, InputRef } from "antd";
import { useRef, useState, useContext, useEffect } from "react";
import { TabelaGerminacaoContext } from "../TabelaGerminacaoContext";
import styles from "./style.module.css";

interface Item {
  key: string;
  data: string;
  validade: string;
  normais: string;
  anormais: string;
  duras: string;
  dormentes: string;
  mortas: string;
  rolo2: string;
  rolo4: string;
  rolo8: string;
  rolo16: string;
}

interface EdicaoCelulaTabelaGerminacaoProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: any;
  handleSave: (record: Item) => void;
  setDataTable: React.Dispatch<React.SetStateAction<any[]>>;
  dataTable: any;
}

export const EdicaoCelulaTabelaGerminacao: React.FC<
  EdicaoCelulaTabelaGerminacaoProps
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  dataTable,
  setDataTable,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(TabelaGerminacaoContext)!;
  const listaRolos = ["rolo2", "rolo4", "rolo8", "rolo16"];

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      //VERIFICANDO SE JA TEM UM ROLO PREENCHIDO
      if (
        listaRolos.includes(dataIndex) &&
        listaRolos.some((rolo) => record[rolo])
      ) {
        //LIMPANDO O VALOR DO ROLO ANTIGO E DEIXANDO SOMENTE O NOVO
        handleSave({
          ...record,
          rolo2: "",
          rolo4: "",
          rolo8: "",
          rolo16: "",
          ...values,
        });
        return;
      }

      handleSave({ ...record, ...values });

      //SALVANDO -N- QUANDO O VALOR FOR VAZIO
      if (values[dataIndex]) {
        setDataTable([dataTable[0], { ...dataTable[1], ...values }]);
      } else {
        setDataTable([dataTable[0], { ...dataTable[1], [dataIndex]: "-N-" }]);
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          style={{ minWidth: 50 }}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          maxLength={listaRolos.includes(dataIndex) ? 2 : 5}
          placeholder={listaRolos.includes(dataIndex) ? "OK" : "%"}
          onChange={(e) => {
            const { value } = e.target;

            if (listaRolos.includes(dataIndex)) {
              form.setFieldValue(
                dataIndex,
                value.replace(/\d/g, "").toUpperCase()
              );
            } else {
              form.setFieldValue(dataIndex, value.replace(/[^0-9,]/g, ""));
            }
          }}
        />
      </Form.Item>
    ) : (
      <div
        style={{ minWidth: 50 }}
        className={styles["editable-cell-value-wrap"]}
        onClick={toggleEdit}
        placeholder="-N-"
        contentEditable={true}
        suppressContentEditableWarning={true}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

import { Button, Modal } from "antd";
import { ITif } from "../../../../interfaces/ITif";
import { useMenu } from "../../../../hooks/useMenu";
import { useNavigate } from "react-router-dom";

export function ModalGerarTID({
  tif,
  setTif,
}: {
  tif: ITif;
  setTif: React.Dispatch<React.SetStateAction<ITif | null>>;
}) {
  const { atualizarMenuSelected } = useMenu();
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={Boolean(tif)}
        title={
          <h1
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 0,
              padding: "0px 15px",
            }}
          >
            Deseja gerar TID?
          </h1>
        }
        closable={true}
        onCancel={() => {
          setTif(null);
        }}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            gap: 25,
            justifyContent: "center",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              navigate("/TID/novo", {
                state: { formValues: tif, tipo: "INTERDICAO" },
              });
              atualizarMenuSelected(["17"]);
              setTif(null);
            }}
          >
            Interdição
          </Button>
          <Button
            type="primary"
            onClick={() => {
              navigate("/TID/novo", {
                state: { formValues: tif, tipo: "DESINTERDICAO" },
              });
              atualizarMenuSelected(["17"]);
              setTif(null);
            }}
          >
            Desinterdição
          </Button>
          <Button
            onClick={() => {
              setTif(null);
            }}
            type="default"
          >
            Não
          </Button>
        </div>
      </Modal>
    </>
  );
}

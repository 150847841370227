import { createAction } from "@reduxjs/toolkit";
import { IProgramaAtividades } from "../../interfaces/IProgramaAtividades";

const ADD_PROGRAMAS = "PROGRAMA_ATIVIDADES/addProgramas";

const addProgramas = createAction(
  ADD_PROGRAMAS,
  (programas: IProgramaAtividades[], persistedAt: number) => ({
    payload: { programas, persistedAt },
  })
);

const REMOVE_PROGRAMAS = "PROGRAMA_ATIVIDADES/removeProgramas";

const removeProgramas = createAction(REMOVE_PROGRAMAS, () => ({ payload: {} }));

export const programaActions = { addProgramas, removeProgramas };

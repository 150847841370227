import { createAction } from "@reduxjs/toolkit";

const addFiltroTermoApreensao = createAction(
  "FILTRO_TERMO_APREENSAO_LIBERACAO/addFiltroTermoApreensao",
  (termos) => ({
    payload: { termos },
  })
);

const removeFiltroTermoApreensao = createAction(
  "FILTRO_TERMO_APREENSAO_LIBERACAO/removeFiltroTermoApreensao",
  () => ({
    payload: {},
  })
);

export const filtroTermoApreensaoAction = {
  addFiltroTermoApreensao,
  removeFiltroTermoApreensao,
};

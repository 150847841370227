import {
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { masks } from "../../../../functions/mascaras";
import { openNotification } from "../../../../components/Notifications";
import { cnpj } from "cpf-cnpj-validator";
import { IMunicipio } from "../../../../interfaces/IMunicipio";
import { IAssinante } from "../../../../interfaces/IAssinante";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useMunicipio } from "../../../../hooks/useMunicipio";
import { useEstabelecimento } from "../../../../hooks/useEstabelecimento";

interface IFiscalizadoVistoriaProps {
  formVistoria: FormInstance<any>;
  municipiosOptions: IMunicipio[];
  setMunicipiosOptions: React.Dispatch<React.SetStateAction<IMunicipio[]>>;
  isValidDocument: boolean;
  setIsValidDocument: React.Dispatch<React.SetStateAction<boolean>>;
  setFiscalizado: React.Dispatch<React.SetStateAction<IAssinante>>;
}

export function FiscalizadoVistoria({
  formVistoria,
  municipiosOptions,
  setMunicipiosOptions,
  isValidDocument,
  setIsValidDocument,
  setFiscalizado,
}: IFiscalizadoVistoriaProps) {
  const { municipiosBrasil, listaUfs } = useMunicipio();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { cepMask, cpfCnpjMask, coordMask, telefoneMask } = masks;

  const handleSearchCnpj = (value: string) => {
    const cnpjFiscalizado = value ? value.replace(/[^\d]+/g, "") : "";

    if (!value) return;

    if (!cnpj.isValid(cnpjFiscalizado)) {
      openNotification({
        type: "error",
        message: "Erro ao buscar Fiscalizado",
        description: "Digite um CNPJ válido para prosseguir.",
      });
      return;
    }
    setIsValidDocument(true);
    buscaEstabelecimento(cnpjFiscalizado);
  };

  const buscaEstabelecimento = async (cnpj: string) => {
    const estabelecimentos = await buscarEstabelecimentoPeloCnpj(cnpj);

    if (estabelecimentos.length > 0) {
      formVistoria.setFieldsValue({
        nomeFiscalizado: estabelecimentos[0].nome,
        ufFiscalizado: estabelecimentos[0].endereco.municipio.uf.id,
        municipioFiscalizado: estabelecimentos[0].endereco.municipio.id,
        emailFiscalizado: estabelecimentos[0].email,
        telefoneFiscalizado: telefoneMask(
          estabelecimentos[0].endereco.telefone
        ),
        cepFiscalizado: cepMask(estabelecimentos[0].endereco.cep),
        enderecoFiscalizado: estabelecimentos[0].endereco.bairro,
        latGrauFiscalizado: coordMask(
          estabelecimentos[0].coordenadaGeografica.latGrau.toString() || ""
        ),
        latMinFiscalizado: coordMask(
          estabelecimentos[0].coordenadaGeografica.latMin.toString() || ""
        ),
        latSegFiscalizado: coordMask(
          estabelecimentos[0].coordenadaGeografica.latSeg.toString() || ""
        ),
        longGrauFiscalizado: coordMask(
          estabelecimentos[0].coordenadaGeografica.longGrau.toString() || ""
        ),
        longMinFiscalizado: coordMask(
          estabelecimentos[0].coordenadaGeografica.longMin.toString() || ""
        ),
        longSegFiscalizado: coordMask(
          estabelecimentos[0].coordenadaGeografica.longSeg.toString() || ""
        ),
      });

      setFiscalizado({
        assinatura: null,
        cpfAssinante: cpfCnpjMask(estabelecimentos[0].cpfCnpj),
        nomeAssinante: estabelecimentos[0].nome,
        funcaoAssinante: "",
      });
      setMunicipiosOptions(
        municipiosBrasil.filter(
          (municipio) =>
            municipio.uf.id === estabelecimentos[0].endereco.municipio.uf.id
        )
      );
    } else {
      openNotification({
        type: "info",
        message: "Estabelecimento não encontrado",
      });
    }
  };

  const handleChangeCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
    formVistoria.setFieldValue("cnpjFiscalizado", cpfCnpjMask(e.target.value));

    if (!cnpj.isValid(e.target.value.replace(/[^\d]+/g, ""))) {
      formVistoria.resetFields([
        "nomeFiscalizado",
        "ufFiscalizado",
        "municipioFiscalizado",
        "emailFiscalizado",
        "telefoneFiscalizado",
        "cepFiscalizado",
        "enderecoFiscalizado",
        "longGrauEstabelecimento",
        "longMinEstabelecimento",
        "longSegEstabelecimento",
        "latGrauEstabelecimento",
        "latMinEstabelecimento",
        "latSegEstabelecimento",
      ]);

      setFiscalizado({
        assinatura: null,
        nomeAssinante: "",
        cpfAssinante: "",
        funcaoAssinante: "",
      });
      setIsValidDocument(false);
    }
  };

  const handleChangeUF = (value: number | undefined) => {
    formVistoria.setFieldValue("municipioFiscalizado", "");
    if (!value) {
      setMunicipiosOptions([]);
      return;
    }

    const municipios = municipiosBrasil.filter(
      (municipio) => municipio.uf.id === value
    );
    setMunicipiosOptions(municipios);
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (e.key === "Enter") {
      document.getElementById(id)?.focus();
    }
  };

  const handleCompleteGeoLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((location) => {
        // console.log(location);
        let latDeg, latMin, latSeg, longDeg, longMin, longSeg;

        let valLat = Math.abs(location.coords.latitude);
        let valLong = Math.abs(location.coords.longitude);

        latDeg = Math.floor(valLat);
        latMin = Math.floor((valLat - latDeg) * 60);
        latSeg =
          Math.round((valLat - latDeg - latMin / 60) * 3600 * 1000) / 1000;

        longDeg = Math.floor(valLong);
        longMin = Math.floor((valLong - longDeg) * 60);
        longSeg =
          Math.round((valLong - longDeg - longMin / 60) * 3600 * 1000) / 1000;

        formVistoria.setFieldsValue({
          latGrauFiscalizado: coordMask(latDeg.toString() || ""),
          latMinFiscalizado: coordMask(latMin.toString() || ""),
          latSegFiscalizado: coordMask(latSeg.toString() || ""),
          longGrauFiscalizado: coordMask(longDeg.toString() || ""),
          longMinFiscalizado: coordMask(longMin.toString() || ""),
          longSegFiscalizado: coordMask(longSeg.toString() || ""),
        });
      });
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel utilizar localização atual",
      });
    }
  };

  return (
    <>
      <Typography.Title level={3}>Fiscalizado</Typography.Title>

      <Form.Item
        label="CNPJ"
        name="cnpjFiscalizado"
        rules={[{ required: true }]}
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input.Search
          allowClear
          enterButton="Buscar"
          placeholder="Digite o CNPJ"
          onSearch={handleSearchCnpj}
          onChange={handleChangeCnpj}
          maxLength={18}
        />
      </Form.Item>
      <Form.Item
        label="Nome"
        name="nomeFiscalizado"
        rules={[{ required: true }]}
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input disabled={!isValidDocument} />
      </Form.Item>
      <Form.Item
        label="Telefone"
        name="telefoneFiscalizado"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input disabled={!isValidDocument} />
      </Form.Item>

      <Form.Item
        label="Email"
        name="emailFiscalizado"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input disabled={!isValidDocument} />
      </Form.Item>
      <Form.Item
        label="CEP"
        name="cepFiscalizado"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input disabled={!isValidDocument} />
      </Form.Item>
      <Form.Item
        label="UF"
        name="ufFiscalizado"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Select
          disabled={!isValidDocument}
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          // disabled={!isValidDocument}
          onChange={handleChangeUF}
          allowClear
        >
          {listaUfs?.map((uf) => (
            <Select.Option key={uf.id} value={uf.id}>
              {uf.sigla}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Municipio"
        name="municipioFiscalizado"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Select
          style={{ minWidth: "190px" }}
          disabled={!isValidDocument}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
        >
          {municipiosOptions.map((municipio) => (
            <Select.Option key={municipio.id} value={municipio.id}>
              {`${municipio.nome}-${municipio.uf.sigla}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Endereço"
        name="enderecoFiscalizado"
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input disabled={!isValidDocument} />
      </Form.Item>

      <Tooltip title="Usar localização atual">
        <Button
          id="button-localizacao-atual"
          disabled={!isValidDocument}
          onClick={handleCompleteGeoLocation}
          onKeyDown={(e) => handleKeyPress(e, "latGrauPropriedade")}
        >
          <EnvironmentOutlined />
        </Button>
      </Tooltip>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
        >
          <Typography
            style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
          >
            Latitude:
          </Typography>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
          <Form.Item
            label="Grau"
            name="latGrauFiscalizado"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
            labelCol={{ flex: "100px", span: 20 }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "latMinFiscalizado")}
              maxLength={5}
              onChange={(e) => {
                formVistoria.setFieldValue(
                  "latGrauFiscalizado",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Minutos"
            name="latMinFiscalizado"
            labelCol={{ flex: "100px", span: 20 }}
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "latSegFiscalizado")}
              maxLength={5}
              onChange={(e) => {
                formVistoria.setFieldValue(
                  "latMinFiscalizado",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Segundos"
            name="latSegFiscalizado"
            labelCol={{ flex: "100px", span: 20 }}
            style={{ maxWidth: "150px" }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "longGrauFiscalizado")}
              maxLength={5}
              onChange={(e) => {
                formVistoria.setFieldValue(
                  "latSegFiscalizado",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
        >
          <Typography
            style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
          >
            Longitude:
          </Typography>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
          <Form.Item
            label="Grau"
            name="longGrauFiscalizado"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
            labelCol={{ flex: "100px", span: 20 }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "longMinFiscalizado")}
              maxLength={5}
              onChange={(e) => {
                formVistoria.setFieldValue(
                  "longGrauFiscalizado",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Minutos"
            name="longMinFiscalizado"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
            labelCol={{ flex: "100px", span: 20 }}
          >
            <Input
              onKeyDown={(e) => handleKeyPress(e, "longSegFiscalizado")}
              maxLength={5}
              onChange={(e) => {
                formVistoria.setFieldValue(
                  "longMinFiscalizado",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>

          <Form.Item
            label="Segundos"
            name="longSegFiscalizado"
            labelAlign="left"
            style={{ maxWidth: "150px" }}
            labelCol={{ flex: "100px", span: 20 }}
          >
            <Input
              maxLength={5}
              onChange={(e) => {
                formVistoria.setFieldValue(
                  "longSegFiscalizado",
                  coordMask(e.target.value)
                );
              }}
              disabled={!isValidDocument}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { IEmpresaAgrotoxico } from "../../interfaces/IEmpresaAgrotoxico";
import { RootState } from "..";

interface IEmpresaAgrotoxicoSlice {
  items: IEmpresaAgrotoxico[];
  persistedAt: number;
}

const initialState: IEmpresaAgrotoxicoSlice = {
  items: [],
  persistedAt: 0,
};

const empresaAgrotoxicoSlice = createSlice({
  name: "EMPRESA_AGROTOXICO",
  initialState,
  reducers: {
    addEmpresas: (state, action) => {
      const { empresas, createdAt } = action.payload;

      state.items = empresas;
      state.persistedAt = createdAt;
    },
    removeEmpresas: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default empresaAgrotoxicoSlice.reducer;

export const getEmpresasAgrotoxicos = (state: RootState) =>
  state.empresasAgrotoxicos.items;

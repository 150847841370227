import { ITif } from "../../../interfaces/ITif";

export function converterTIFtoFormData(
  formValues: ITif,
  anexos?: (File | undefined)[]
) {
  const formData = new FormData();

  Object.entries(formValues).forEach((formValue) => {
    if (
      [
        "anexosTIF",
        "atividades",
        "estabelecimento",
        "municipioFiscalizacao",
        "municipioFiscalizado",
        "produtor",
        "propriedade",
        "respostasAtividades",
        "servidores",
        "ule",
      ].includes(formValue[0])
    )
      return;

    formData.append(formValue[0], formValue[1]);
  });

  //ATIVIDADES
  formValues.atividades.forEach((atv, ind) => {
    formData.append(`atividades[${ind}].coleta`, atv.coleta.toString());
    formData.append(`atividades[${ind}].denuncia`, atv.denuncia.toString());
    formData.append(`atividades[${ind}].interdicao`, atv.interdicao.toString());
    formData.append(
      `atividades[${ind}].desinterdicao`,
      atv.desinterdicao.toString()
    );
    formData.append(
      `atividades[${ind}].notificacao`,
      atv.notificacao.toString()
    );

    //AREA ATIVIDADE
    Object.entries(atv.areaAtividade).forEach((area) => {
      formData.append(
        `atividades[${ind}].areaAtividade.${area[0]}`,
        area[1]?.toString() || ""
      );
    });

    //ARMADILHA
    if (atv.armadilha) {
      Object.entries(atv.armadilha).forEach((arm) => {
        if (["municipio"].includes(arm[0])) return;

        formData.append(
          `atividades[${ind}].armadilha.${arm[0]}`,
          arm[1]?.toString() || ""
        );
      });

      //atividade ARMADILHA MUNICIPIO
      if (atv.armadilha.municipio) {
        Object.entries(atv.armadilha.municipio).forEach((mun) => {
          if (["uf"].includes(mun[0])) return;

          formData.append(
            `atividades[${ind}].armadilha.municipio.${mun[0]}`,
            mun[1]?.toString() || ""
          );
        });

        Object.entries(atv.armadilha.municipio.uf).forEach((mun) => {
          formData.append(
            `atividades[${ind}].armadilha.municipio.uf.${mun[0]}`,
            mun[1]?.toString() || ""
          );
        });
      }
    }

    // ATIVIDADE
    formData.append(
      `atividades[${ind}].atividade.id`,
      atv.atividade.id.toString() || ""
    );
    formData.append(
      `atividades[${ind}].atividade.nome`,
      atv.atividade.nome || ""
    );

    //QUESTOES
    atv.atividade.questoes.forEach((quest, indQuest) => {
      formData.append(
        `atividades[${ind}].atividade.questoes[${indQuest}].order`,
        quest.order.toString() || ""
      );

      formData.append(
        `atividades[${ind}].atividade.questoes[${indQuest}].id`,
        quest.id.toString() || ""
      );

      //PERGUNTA
      Object.entries(quest.pergunta).forEach((perg) => {
        if (
          [
            "alternativas",
            "antecessora",
            "campos",
            "respostaCondicional",
          ].includes(perg[0])
        )
          return;

        formData.append(
          `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.${perg[0]}`,
          perg[1]?.toString() || ""
        );
      });

      //ALTERNATIVAS
      quest.pergunta.alternativas.forEach((alt, indAlternativa) => {
        formData.append(
          `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.alternativas[${indAlternativa}].id`,
          alt.id.toString() || ""
        );
        formData.append(
          `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.alternativas[${ind}].ativo`,
          alt.ativo.toString() || ""
        );
        formData.append(
          `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.alternativas[${ind}].nome`,
          alt.nome.toString() || ""
        );
      });

      //ANTECESSORA
      if (quest.pergunta.antecessora) {
        Object.entries(quest.pergunta.antecessora).forEach((antecessor) => {
          formData.append(
            `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.antecessora.${antecessor[0]}`,
            antecessor[1]?.toString() || ""
          );
        });
      }

      //CAMPOS
      quest.pergunta.campos.forEach((campo, indCampo) => {
        formData.append(
          `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.campos[${indCampo}]`,
          campo.toString() || ""
        );
      });

      //RESPOSTA CONDICIONAL
      if (quest.pergunta.respostaCondicional) {
        Object.entries(quest.pergunta.respostaCondicional).forEach((resp) => {
          formData.append(
            `atividades[${ind}].atividade.questoes[${indQuest}].pergunta.respostaCondicional.${resp[0]}`,
            resp[1]?.toString() || ""
          );
        });
      }
    });
  });

  //ESTABELECIMENTO
  if (formValues.estabelecimento) {
    Object.entries(formValues.estabelecimento).forEach((estab) => {
      if (["coordenadaGeografica", "endereco"].includes(estab[0])) return;

      formData.append(
        `estabelecimento.${estab[0]}`,
        estab[1]?.toString() || ""
      );
    });

    //COORDENANDA GEOGRAFICA ESTABELECIMENTO
    if (formValues.estabelecimento.coordenadaGeografica) {
      Object.entries(formValues.estabelecimento.coordenadaGeografica).forEach(
        (coord) => {
          formData.append(
            `estabelecimento.coordenadaGeografica.${coord[0]}`,
            coord[1]?.toString() || ""
          );
        }
      );
    }

    //ENDERECO ESTABELECIMENTO
    if (formValues.estabelecimento.endereco) {
      Object.entries(formValues.estabelecimento.endereco).forEach((end) => {
        if (["municipio", "tipoLogradouro"].includes(end[0])) return;

        formData.append(
          `estabelecimento.endereco.${end[0]}`,
          end[1]?.toString() || ""
        );
      });

      //MUNICIPIO ENDERECO
      if (formValues.estabelecimento.endereco.municipio) {
        Object.entries(formValues.estabelecimento.endereco.municipio).forEach(
          (municipio) => {
            if (["uf"].includes(municipio[0])) return;

            formData.append(
              `estabelecimento.endereco.municipio.${municipio[0]}`,
              municipio[1]?.toString() || ""
            );
          }
        );

        //UF MUNICIPIO ENDERECO
        if (formValues.estabelecimento.endereco.municipio.uf) {
          Object.entries(
            formValues.estabelecimento.endereco.municipio.uf
          ).forEach((uf) => {
            formData.append(
              `estabelecimento.endereco.municipio.uf.${uf[0]}`,
              uf[1]?.toString() || ""
            );
          });
        }
      }

      //TIPO LOGRADOURO ENDERECO
      if (formValues.estabelecimento.endereco.tipoLogradouro) {
        Object.entries(
          formValues.estabelecimento.endereco.tipoLogradouro
        ).forEach((logradouro) => {
          formData.append(
            `estabelecimento.endereco.tipoLogradouro.${logradouro[0]}`,
            logradouro[1]?.toString() || ""
          );
        });
      }
    }
  }

  //MUNICIPIOS
  if (formValues.municipioFiscalizacao) {
    Object.entries(formValues.municipioFiscalizacao).forEach(
      (municipioForm) => {
        if (["uf"].includes(municipioForm[0])) return;

        formData.append(
          `municipioFiscalizacao.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );

    Object.entries(formValues.municipioFiscalizacao.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioFiscalizacao.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  if (formValues.municipioFiscalizado) {
    Object.entries(formValues.municipioFiscalizado).forEach((municipioForm) => {
      if (["uf"].includes(municipioForm[0])) return;

      formData.append(
        `municipioFiscalizado.${municipioForm[0]}`,
        municipioForm[1]?.toString() || ""
      );
    });

    Object.entries(formValues.municipioFiscalizado.uf).forEach(
      (municipioForm) => {
        formData.append(
          `municipioFiscalizado.uf.${municipioForm[0]}`,
          municipioForm[1]?.toString() || ""
        );
      }
    );
  }

  //PRODUTOR
  if (formValues.produtor) {
    Object.entries(formValues.produtor).forEach((prod) => {
      if (["endereco"].includes(prod[0])) return;

      formData.append(`produtor.${prod[0]}`, prod[1]?.toString() || "");
    });

    //ENDERECO PRODUTOR
    if (formValues.produtor.endereco) {
      Object.entries(formValues.produtor.endereco).forEach((end) => {
        if (["municipio", "tipoLogradouro"].includes(end[0])) return;

        formData.append(
          `produtor.endereco.${end[0]}`,
          end[1]?.toString() || ""
        );
      });

      //MUNICIPIO ENDERECO
      if (formValues.produtor.endereco.municipio) {
        Object.entries(formValues.produtor.endereco.municipio).forEach(
          (municipio) => {
            if (["uf"].includes(municipio[0])) return;

            formData.append(
              `produtor.endereco.municipio.${municipio[0]}`,
              municipio[1]?.toString() || ""
            );
          }
        );

        //UF MUNICIPIO ENDERECO
        if (formValues.produtor.endereco.municipio.uf) {
          Object.entries(formValues.produtor.endereco.municipio.uf).forEach(
            (uf) => {
              formData.append(
                `produtor.endereco.municipio.uf.${uf[0]}`,
                uf[1]?.toString() || ""
              );
            }
          );
        }
      }

      //TIPO LOGRADOURO ENDERECO
      if (formValues.produtor.endereco.tipoLogradouro) {
        Object.entries(formValues.produtor.endereco.tipoLogradouro).forEach(
          (logradouro) => {
            formData.append(
              `produtor.endereco.tipoLogradouro.${logradouro[0]}`,
              logradouro[1]?.toString() || ""
            );
          }
        );
      }
    }
  }

  //PROPRIEDADE
  if (formValues.propriedade) {
    Object.entries(formValues.propriedade).forEach((prop) => {
      if (["coordenadaGeografica", "municipio", "produtores"].includes(prop[0]))
        return;

      formData.append(`propriedade.${prop[0]}`, prop[1]?.toString() || "");
    });

    //COORDENANDA GEOGRAFICA PROPRIEDADE
    if (formValues.propriedade.coordenadaGeografica) {
      Object.entries(formValues.propriedade.coordenadaGeografica).forEach(
        (coord) => {
          formData.append(
            `propriedade.coordenadaGeografica.${coord[0]}`,
            coord[1]?.toString() || ""
          );
        }
      );
    }

    //MUNICIPIO PROPRIEDADE
    if (formValues.propriedade.municipio) {
      Object.entries(formValues.propriedade.municipio).forEach((municipio) => {
        if (["uf"].includes(municipio[0])) return;

        formData.append(
          `propriedade.municipio.${municipio[0]}`,
          municipio[1]?.toString() || ""
        );
      });

      //UF MUNICIPIO PROPRIEDADE
      if (formValues.propriedade.municipio.uf) {
        Object.entries(formValues.propriedade.municipio.uf).forEach((uf) => {
          formData.append(
            `propriedade.municipio.uf.${uf[0]}`,
            uf[1]?.toString() || ""
          );
        });
      }
    }

    //PRODUTORES PROPRIEDADE
    formValues.propriedade.produtores.forEach((prod, ind) => {
      Object.entries(prod).forEach((prodItem) => {
        if (["endereco"].includes(prodItem[0])) return;

        formData.append(
          `propriedade.produtores[${ind}].${prodItem[0]}`,
          prodItem[1]?.toString() || ""
        );
      });

      //ENDERECO PRODUTOR PROPRIEDADE
      if (prod.endereco) {
        Object.entries(prod.endereco).forEach((end) => {
          if (["municipio", "tipoLogradouro"].includes(end[0])) return;

          formData.append(
            `propriedade.produtores[${ind}].endereco.${end[0]}`,
            end[1]?.toString() || ""
          );
        });
      }

      //MUNICIPIO ENDERECO PROPRIEDADE
      if (prod.endereco.municipio) {
        Object.entries(prod.endereco.municipio).forEach((municipio) => {
          if (["uf"].includes(municipio[0])) return;

          formData.append(
            `propriedade.produtores[${ind}].endereco.municipio.${municipio[0]}`,
            municipio[1]?.toString() || ""
          );
        });

        //UF MUNICIPIO ENDERECO
        if (prod.endereco.municipio.uf) {
          Object.entries(prod.endereco.municipio.uf).forEach((uf) => {
            formData.append(
              `propriedade.produtores[${ind}].endereco.municipio.uf.${uf[0]}`,
              uf[1]?.toString() || ""
            );
          });
        }
      }

      //TIPO_LOGRADOURO ENDERECO PROPRIEDADE
      if (prod.endereco.tipoLogradouro) {
        Object.entries(prod.endereco.tipoLogradouro).forEach((logradouro) => {
          formData.append(
            `propriedade.produtores[${ind}].endereco.tipoLogradouro.${logradouro[0]}`,
            logradouro[1]?.toString() || ""
          );
        });
      }
    });
  }

  //RESPOSTAS ATIVIDADES
  formValues.respostasAtividades.forEach((res, ind) => {
    formData.append(`respostasAtividades[${ind}].descricao`, res.descricao);

    // ALTERNATIVA RESPOSTA ATIVIDADE
    if (res.alternativa) {
      Object.entries(res.alternativa).forEach((alt) => {
        formData.append(
          `respostasAtividades[${ind}].alternativa.${alt[0]}`,
          alt[1]?.toString() || ""
        );
      });
    }

    //ARMADILHA
    if (res.armadilha) {
      Object.entries(res.armadilha).forEach((arm) => {
        if (["municipio"].includes(arm[0])) return;

        formData.append(
          `respostasAtividades[${ind}].armadilha.${arm[0]}`,
          arm[1]?.toString() || ""
        );
      });

      //atividade ARMADILHA MUNICIPIO
      if (res.armadilha.municipio) {
        Object.entries(res.armadilha.municipio).forEach((mun) => {
          if (["uf"].includes(mun[0])) return;

          formData.append(
            `respostasAtividades[${ind}].armadilha.municipio.${mun[0]}`,
            mun[1]?.toString() || ""
          );
        });

        Object.entries(res.armadilha.municipio.uf).forEach((mun) => {
          formData.append(
            `respostasAtividades[${ind}].armadilha.municipio.uf.${mun[0]}`,
            mun[1]?.toString() || ""
          );
        });
      }
    }

    //ATIVIDADE RESPOSTA
    if (res.atividade) {
      Object.entries(res.atividade).forEach((atv) => {
        formData.append(
          `respostasAtividades[${ind}].atividade.${atv[0]}`,
          atv[1]?.toString() || ""
        );
      });
    }

    //PERGUNTA RESPOSTA
    if (res.pergunta) {
      Object.entries(res.pergunta).forEach((perg) => {
        formData.append(
          `respostasAtividades[${ind}].pergunta.${perg[0]}`,
          perg[1]?.toString() || ""
        );
      });
    }

    //CAMPOS RESPOSTA
    res.campos.forEach((campo, ind) => {
      Object.entries(campo).forEach((campoItem) => {
        formData.append(
          `respostasAtividades[${ind}].campos[${ind}].${campoItem[0]}`,
          campoItem[1]?.toString() || ""
        );
      });
    });
  });

  //ULE
  if (formValues.ule) {
    Object.entries(formValues.ule).forEach((ule) => {
      if (["municipio", "urs", "telefones"].includes(ule[0])) return;

      formData.append(`ule.${ule[0]}`, ule[1]?.toString() || "");
    });

    Object.entries(formValues.ule.municipio).forEach((uleMunicipio) => {
      if (["uf"].includes(uleMunicipio[0])) return;

      formData.append(
        `ule.municipio.${uleMunicipio[0]}`,
        uleMunicipio[1]?.toString() || ""
      );
    });

    Object.entries(formValues.ule.municipio.uf).forEach((uleUf) => {
      formData.append(
        `ule.municipio.uf.${uleUf[0]}`,
        uleUf[1]?.toString() || ""
      );
    });
  }

  //SERVIDORES
  formValues.servidores.forEach((serv, ind) => {
    formData.append(`servidores[${ind}].assinatura`, serv.assinatura || "");
    Object.entries(serv.servidor).forEach((servidorForm) => {
      formData.append(
        `servidores[${ind}].servidor.${servidorForm[0]}`,
        servidorForm[1]?.toString() || ""
      );
    });
  });

  //ANEXO
  const file = new File([], "");

  if (!anexos || anexos.length === 0) {
    formData.append("anexosTIF", file);
  }

  anexos?.forEach((item) => {
    formData.append("anexosTIF", item || file);
  });

  return formData;
}

import moment from "moment";
import { ITermoApreensaoLiberacao } from "../../../../../../interfaces/ITermoApreensaoLiberacao";
import styled from "styled-components";
import { masks } from "../../../../../../functions/mascaras";

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  height: 2vh;
`;

const ContentProduto = styled.div<{
  width: string;
  hasBorder?: boolean;
}>`
  padding: 5px;
  width: ${(props) => props.width};
  border-right: ${(props) => (props.hasBorder ? "1px solid black" : "none")};
  font-size: 12px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export function BodyTermoApreensaoLiberacaoImpressao({
  termo,
  propriedade,
}: {
  termo: ITermoApreensaoLiberacao;
  propriedade: string;
}) {
  const { cepMask, cpfCnpjMask, telefoneMask } = masks;

  return (
    <>
      <tbody>
        <tr>
          <td
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
            }}
          >
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                {termo.tipoTermo === "LIBERACAO"
                  ? `N° Termo de Apreensão:${termo.termoApreensao?.numero}`
                  : `N° TIF:${termo.tif?.numero}`}
              </div>
              <div style={{ width: "40%", paddingLeft: 5 }}>
                Data Fiscalização:
                {termo.dataFiscalizacao?.includes("/")
                  ? termo.dataFiscalizacao
                  : moment(termo.dataFiscalizacao || "").format("DD/MM/YYYY")}
              </div>
            </Container>

            <div
              style={{
                background: "#d9d9d9",
                width: "100%",
                borderBottom: "1px solid black",
                height: "2vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              FISCALIZADO
            </div>

            <Container style={{ paddingLeft: 5 }}>
              Nome/Razao social:{termo.nomeFiscalizado}
            </Container>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CPF/CNPJ:{cpfCnpjMask(termo.cpfCnpjFiscalizado || "")}
              </div>
              <div
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                RG:{termo.inscricaoEstadualFiscalizado}
              </div>

              <div style={{ width: "30%", paddingLeft: 5 }}>
                RENASEM:{termo.renasemFiscalizado}
              </div>
            </Container>

            <div style={{ paddingLeft: 5, borderBottom: "1px solid black" }}>
              Endereço:{termo.enderecoFiscalizado}
            </div>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Municipio:{termo.municipioFiscalizado?.nome}
              </div>
              <div
                style={{
                  width: "10%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                UF:{termo.municipioFiscalizado?.uf.sigla}
              </div>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CEP:{cepMask(termo.cepFiscalizado || "")}
              </div>
              <div style={{ width: "25%", paddingLeft: 5 }}>
                Telefone:{telefoneMask(termo.telefoneFiscalizado || "")}
              </div>
            </Container>

            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Email:{termo.emailFiscalizado}
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                  paddingLeft: 5,
                }}
              >
                <span>Localização Geografica:</span>

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>S</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>O</span>
                </div>
              </div>
            </Container>

            {propriedade && (
              <>
                <div
                  style={{
                    background: "#d9d9d9",
                    width: "100%",
                    borderBottom: "1px solid black",
                    height: "2vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  PROPRIEDADE
                </div>

                <Container style={{ paddingLeft: 5 }}>
                  Nome Propriedade:{propriedade}
                </Container>
              </>
            )}

            {/**TABELA PRODUTOS */}
            {termo.itens.length > 0 && (
              <>
                <div
                  style={{
                    background: "#d9d9d9",
                    borderBottom: "1px solid black",
                    display: "flex",
                    fontWeight: "bold",
                  }}
                >
                  {/**TITULOS */}
                  <ContentProduto width="10%" hasBorder>
                    Tipo de Produto
                  </ContentProduto>
                  <ContentProduto width="15%" hasBorder>
                    Identificação
                  </ContentProduto>

                  <ContentProduto width="15%" hasBorder>
                    Fabricante ou Renasem
                  </ContentProduto>

                  <ContentProduto width="12%" hasBorder>
                    Embalagem
                  </ContentProduto>
                  <ContentProduto width="11%" hasBorder>
                    Lote
                  </ContentProduto>
                  <ContentProduto width="10%" hasBorder>
                    Validade
                  </ContentProduto>
                  <ContentProduto width="8%" hasBorder>
                    Volume Emb.
                  </ContentProduto>
                  <ContentProduto width="7%" hasBorder>
                    Quant.
                  </ContentProduto>
                  <ContentProduto width="6%" hasBorder>
                    Total
                  </ContentProduto>
                  <ContentProduto width="6%">UND</ContentProduto>
                </div>

                <div>
                  {termo.itens.map((item, ind, items) => {
                    if (item.quantidade > 0) {
                      return (
                        <div
                          key={ind}
                          style={{
                            display: "flex",
                            borderBottom:
                              ind < items.length - 1
                                ? "1px solid black"
                                : "none",
                          }}
                        >
                          <ContentProduto width="10%" hasBorder>
                            {item.tipoArea}
                          </ContentProduto>
                          <ContentProduto width="15%" hasBorder>
                            {item.nomeProduto}
                            {item.cultivar && `-${item.cultivar.nome}`}
                          </ContentProduto>
                          <ContentProduto width="15%" hasBorder>
                            {item.nomeFabricante || item.renasem}
                          </ContentProduto>

                          <ContentProduto width="12%" hasBorder>
                            {item.embalagem?.nome || ""}
                          </ContentProduto>

                          <ContentProduto width="11%" hasBorder>
                            {item.lote}
                          </ContentProduto>
                          <ContentProduto width="10%" hasBorder>
                            {item.dataValidade
                              ? item.dataValidade.includes("/")
                                ? item.dataValidade
                                : moment(item.dataValidade).format("DD/MM/YYYY")
                              : ""}
                          </ContentProduto>
                          <ContentProduto width="8%" hasBorder>
                            {item.volume}
                          </ContentProduto>
                          <ContentProduto width="7%" hasBorder>
                            {item.quantidade || ""}
                          </ContentProduto>
                          <ContentProduto width="6%" hasBorder>
                            {item.total !== 0 ? item.total : item.quantidade}
                          </ContentProduto>
                          <ContentProduto width="6%">
                            {item.unidadeMedida.sigla}
                          </ContentProduto>
                        </div>
                      );
                    }

                    return null;
                  })}
                </div>

                <div
                  style={{
                    background: "#d9d9d9",
                    width: "100%",
                    borderBottom: "1px solid black",
                    height: "2vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  {/**TITULOS */}
                  <ContentProduto width="100%">Descrição</ContentProduto>
                </div>

                <div>
                  {termo.itens.map((item, ind, items) => {
                    if (item.quantidade > 0) {
                      return (
                        <div
                          key={ind}
                          style={{
                            borderBottom:
                              ind < items.length - 1
                                ? "1px solid black"
                                : "none",
                            fontSize: 12,
                            paddingLeft: 5,
                            minHeight: "2vh",
                          }}
                        >
                          {item.descricao}
                        </div>
                      );
                    }

                    return null;
                  })}
                </div>
              </>
            )}

            <div
              style={{
                background: "#d9d9d9",
                width: "100%",
                borderBottom: "1px solid black",
                height: "2vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              DEPOSITÁRIO
            </div>

            <Container style={{ paddingLeft: 5 }}>
              Nome/Razao social:{" "}
              {termo.nomeDepositario || termo.nomeFiscalizado}
            </Container>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CPF/CNPJ:{" "}
                {cpfCnpjMask(
                  termo.cpfCnpjDepositario || termo.cpfCnpjFiscalizado
                )}
              </div>
              <div
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                RG:
                {termo.inscricaoEstadualDepositario ||
                  termo.inscricaoEstadualFiscalizado}
              </div>

              <div style={{ width: "30%", paddingLeft: 5 }}>
                RENASEM:{termo.renasemDepositario || termo.renasemFiscalizado}
              </div>
            </Container>

            <div
              style={{
                paddingLeft: 5,
                borderBottom: "1px solid black",
              }}
            >
              Endereço:
              {termo.enderecoDepositario ||
                termo.tif?.enderecoPropriedade ||
                ""}
            </div>

            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "40%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Municipio:
                {termo.municipioDepositario?.nome ||
                  termo.municipioFiscalizado?.nome}
              </div>
              <div
                style={{
                  width: "10%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                UF:
                {termo.municipioDepositario?.uf.sigla ||
                  termo.municipioFiscalizado?.uf.sigla}
              </div>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                CEP:{cepMask(termo.cepDepositario || termo.cepFiscalizado)}
              </div>
              <div style={{ width: "25%", paddingLeft: 5 }}>
                Telefone:
                {telefoneMask(
                  termo.telefoneDepositario || termo.telefoneFiscalizado
                )}
              </div>
            </Container>
            <Container style={{ display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Email:{termo.emailDepositario || termo.emailFiscalizado}
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                  paddingLeft: 5,
                }}
              >
                <span>Localização Geografica:</span>

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>S</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>O</span>
                </div>
              </div>
            </Container>

            {/* <div style={{ paddingLeft: 5, borderBottom: "1px solid black" }}>
              Depósito (Local/Endereço):{termo.enderecoDepositario}
            </div> */}

            <div style={{ minHeight: "10vh" }}>
              <div style={{ minHeight: 50 }}>
                <div
                  style={{
                    background: "#d9d9d9",
                    width: "100%",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  AMPARO LEGAL
                </div>
                <div
                  style={{
                    padding: 5,
                    textAlign: "justify",
                  }}
                >
                  {termo.amparoLegal.includes("\n") ? (
                    termo.amparoLegal.split("\n").map((text, ind) => (
                      <p key={ind} style={{ textIndent: 40, marginBottom: 5 }}>
                        {text}
                      </p>
                    ))
                  ) : (
                    <span>{termo.amparoLegal}</span>
                  )}
                </div>
              </div>

              {termo.providencias && (
                <div style={{ minHeight: 50 }}>
                  <div
                    style={{
                      background: "#d9d9d9",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "bold",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    PROVIDÊNCIA(S)
                  </div>
                  <div
                    style={{
                      padding: 5,
                      textAlign: "justify",
                    }}
                  >
                    {termo.providencias.includes("\n") ? (
                      termo.providencias.split("\n").map((text, ind) => (
                        <p
                          key={ind}
                          style={{ textIndent: 40, marginBottom: 5 }}
                        >
                          {text}
                        </p>
                      ))
                    ) : (
                      <span>{termo.providencias}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}

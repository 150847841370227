import { useDispatch, useSelector } from "react-redux";
import { getAtividadesInterditadas } from "../store/feature/atividadeInterditadaSlice";
import { IAtividadeInterdicao } from "../interfaces/IAtividadeInterdicao";
import { atividadeInterditadaAction } from "../store/actions/atividadeInterditadaAction";

export const useAtividadeInterditada = () => {
  const appDispatch = useDispatch();
  const listaAtividadesInterditadas = useSelector(getAtividadesInterditadas);
  const { addAtividades, removeAtividades } = atividadeInterditadaAction;

  const adicionarAtividadesInterditadasSync = (
    atividade: IAtividadeInterdicao[]
  ) => {
    appDispatch(addAtividades(atividade));
  };

  const removerAtividadesInterditadasSync = () => {
    appDispatch(removeAtividades());
  };

  return {
    listaAtividadesInterditadas,
    adicionarAtividadesInterditadasSync,
    removerAtividadesInterditadasSync,
  };
};

import { iconsLogo } from "../../../../../../icons";

export function HeaderImpressaoTIF({ numTIF }: { numTIF: string }) {
  // console.log(IndeaLogo);

  return (
    <>
      <thead>
        <tr>
          <td style={{ border: "1px solid black" }}>
            <div
              style={{
                display: "block",
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 10px 0px 10px",
                }}
              >
                <div>
                  <img
                    alt={iconsLogo[1].name}
                    style={{
                      height: 70,
                    }}
                    src={iconsLogo[1].image}
                  />
                </div>
                <div>
                  <img
                    alt={iconsLogo[2].name}
                    style={{
                      height: 70,
                    }}
                    src={iconsLogo[2].image}
                  />
                </div>

                <div>
                  <img
                    alt={iconsLogo[3].name}
                    style={{ height: 70 }}
                    src={iconsLogo[3].image}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                paddingLeft: 10,
                paddingBottom: 5,
              }}
            >
              Número:{numTIF}
            </div>
          </td>
        </tr>
      </thead>
    </>
  );
}

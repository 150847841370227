import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../";
import { IProdutor } from "../../interfaces/IProdutor";
import { IPropriedade } from "../../interfaces/IPropriedade";

interface IpropriedadeState {
  items: {
    error?: string;
    municipioId: number;
    propriedades: IPropriedade[];
  }[];
  persistedAt: number;
}

const initialState: IpropriedadeState = {
  items: [],
  persistedAt: 0,
};

const propriedadeSlice = createSlice({
  name: "PROPRIEDADE",
  initialState,
  reducers: {
    addPropriedades: (state, action) => {
      const { items, dateTimeCreate } = action.payload;

      try {
        if (items.propriedades.length === 0) {
          state.items.push({ ...items, error: "erro propriedades" });
          return;
        }

        if (!items) {
          state.items = [];
          return;
        }

        state.items.push(items);
        state.persistedAt = dateTimeCreate;
      } catch (error) {
        console.log(error);
      }
    },
    removePropriedades: (state, action) => {
      const idMunicipio = action.payload;
      const propriedadeFilter = state.items.filter(
        (prop) => prop.municipioId !== idMunicipio
      );
      state.items = propriedadeFilter;
    },
    updatePropriedades: (state, action) => {
      const { items, dateTimeUpdate } = action.payload;

      try {
        if (items.propriedades.length === 0) return;

        if (!items) return;

        const updateProps = state.items.map((prop: any) => {
          if (prop.municipioId === items.municipioId) {
            return items;
          } else {
            return prop;
          }
        });

        state.items = updateProps;
        state.persistedAt = dateTimeUpdate;
      } catch (error) {
        console.log(error);
      }
    },
  },
});

export const getPropriedades = (state: RootState) => state.propriedades.items;

export const getProdutores = (state: RootState) => {
  let produtores: IProdutor[] = [];
  let auxIds: number[] = [];

  state.propriedades.items.forEach((item) => {
    item.propriedades.forEach((prop) => {
      prop.produtores.forEach((produtor) => {
        if (!auxIds.some((id) => id === produtor.id)) {
          auxIds.push(produtor.id);
          produtores.push(produtor);
        }
      });
    });
  });

  //RETIRANDO PRODUTORES DUPLICADOS

  return produtores;
};

export default propriedadeSlice.reducer;

import { createAction } from "@reduxjs/toolkit";

const ADD_ESTABELECIMENTOS = "ESTABELECIMENTO/addEstabelecimentos";

const addEstabelecimentos = createAction(
  ADD_ESTABELECIMENTOS,
  (items, dateTimeCreate: number) => ({
    payload: { items, dateTimeCreate },
  })
);

const REMOVE_ESTABELECIMENTOS = "ESTABELECIMENTO/removeEstabelecimentos";

const removeEstabelecimentos = createAction(
  REMOVE_ESTABELECIMENTOS,
  (municipioId: number) => ({
    payload: municipioId,
  })
);

const UPDATE_ESTABELECIMENTOS = "ESTABELECIMENTO/updateEstabelecimentos";

const updateEstabelecimentos = createAction(
  UPDATE_ESTABELECIMENTOS,
  (items, dateTimeUpdate: number) => ({
    payload: { items, dateTimeUpdate },
  })
);

export const estabelecimentosAction = {
  addEstabelecimentos,
  removeEstabelecimentos,
  updateEstabelecimentos,
};

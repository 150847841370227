import { createAction } from "@reduxjs/toolkit";
import { IMunicipio } from "../../interfaces/IMunicipio";


const addMunicipios = createAction(
    'downloadMunicipios/addMunicipios',
    (municipios:IMunicipio[],dateTimeUpdate:number)=>({
        payload:{municipios,dateTimeUpdate}
    })
)

const removeMunicipio = createAction(
    'downloadMunicipios/removeMunicipio',
    (idMunicipio)=>({
        payload:idMunicipio
    })
)

export const downloadMunicipiosAction = {addMunicipios,removeMunicipio};
import { createAction } from "@reduxjs/toolkit";
import { IArmadilha } from "../../interfaces/IArmadilha";

const addArmadilha = createAction(
  "ARMADILHA/addArmadilha",
  (armadilhas: IArmadilha[], municipioId: number, dateTimeCreate: number) => ({
    payload: { armadilhas, municipioId, dateTimeCreate },
  })
);

const removeArmadilha = createAction(
  "ARMADILHA/removeArmadilha",
  (municipioId: number) => ({
    payload: { municipioId },
  })
);

export const armadilhaAction = { addArmadilha, removeArmadilha };

import { createAction } from "@reduxjs/toolkit";
import { IItemTermoApreensao } from "../../interfaces/IItemTermoApreensao";

const addItem = createAction(
  "ITENS_TERMO_APREENSAO_LIBERACAO/addItemTermoApreensao",
  (item: IItemTermoApreensao) => ({
    payload: { item },
  })
);

const updateItem = createAction(
  "ITENS_TERMO_APREENSAO_LIBERACAO/updateItemTermoApreensao",
  (item: IItemTermoApreensao) => ({
    payload: { item },
  })
);

const removeItem = createAction(
  "ITENS_TERMO_APREENSAO_LIBERACAO/removeItemTermoApreensao",
  (id: number) => ({
    payload: { id },
  })
);

const removeAllItens = createAction(
  "ITENS_TERMO_APREENSAO_LIBERACAO/removeAllItensTermoApreensao",
  () => ({
    payload: {},
  })
);

export const itensTermoApreensaoLiberacaoAction = {
  addItem,
  removeItem,
  removeAllItens,
  updateItem,
};

import { Form, Upload, UploadFile } from "antd";
import { Fragment } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { openNotification } from "../Notifications";

interface IUploadFileTermoApreensaoLiberacaoProps {
  anexos: any[];
  setAnexos: React.Dispatch<React.SetStateAction<any[]>>;
}

export function UploadFilesForm({
  anexos,
  setAnexos,
}: IUploadFileTermoApreensaoLiberacaoProps) {
  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const tamanhoArquivos = info.fileList.reduce(
      (acc, info: any) => acc + info.size,
      0
    );

    // 10 mb em binario
    const tenMb = 10 * 1048576;

    if (tamanhoArquivos > tenMb) {
      openNotification({
        type: "info",
        message: "Capacidade para anexos ultrapassada",
        description: "Capacidade para anexos é de 10MB",
      });
      return;
    }

    setAnexos(info.fileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <Fragment>
      <Form.Item label="Anexo" valuePropName="anexo">
        <Upload
          fileList={anexos}
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          onPreview={onPreview}
          listType="picture-card"
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>
    </Fragment>
  );
}
